import { useMemo } from 'react';
import routePaths from '../../../../config/route-paths';
import { PaginatedStatementTransactionDef } from '../../../../api/statements/routes';
import { formatStatementCurrency } from '../../../statements/helpers/format-statement-currency';
import { formatAsLinkForCSV } from '../../../../hooks/use-get-download-menu-option/helpers/format-as-link-for-csv';
import { DownloadColumn } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv/download-csv.types';
import { getAdminUrl } from '../../../../helpers/get-admin-url';
import { formatStatementQuantity } from '../../../statements/helpers/format-statement-quantity';
import { formatStatementSku } from '../../../statements/helpers/format-statement-sku';

export const useStatementsTransactionsDownloadColumns = () => {
  return useMemo(() => {
    const downloadColumns: DownloadColumn<PaginatedStatementTransactionDef>[] =
      [
        {
          getValue: ({ date }) => {
            return date;
          },
          header: 'Date',
        },
        {
          getValue: ({ designerName }) => {
            return designerName;
          },
          header: 'Designer',
        },
        {
          getValue: ({ category }) => {
            return category;
          },
          header: 'Category',
        },
        {
          getValue: ({ type }) => {
            return type;
          },
          header: 'Type',
        },
        {
          getValue: ({ order }) => {
            return order?.id
              ? formatAsLinkForCSV({
                  text: `${order.type} #${order.id}`,
                  url: `${getAdminUrl() + routePaths.orders}/${order.id}`,
                })
              : '';
          },
          header: 'Order',
        },
        {
          getValue: ({ statementId }) => {
            return statementId
              ? formatAsLinkForCSV({
                  text: `#${statementId}`,
                  url: `${
                    getAdminUrl() + routePaths.statements
                  }/${statementId}`,
                })
              : '';
          },
          header: 'Statement',
        },
        {
          getValue: ({ sku }) => {
            return formatStatementSku(sku);
          },
          header: 'SKU',
        },
        {
          getValue: ({ quantity }) => {
            return formatStatementQuantity(quantity);
          },
          header: 'Quantity',
        },
        {
          getValue: ({ pricePerItem }) => {
            return formatStatementCurrency(pricePerItem);
          },
          header: 'Price per item',
        },
        {
          getValue: ({ total }) => {
            return formatStatementCurrency(total);
          },
          header: 'Total',
        },
      ];

    return downloadColumns;
  }, []);
};
