import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { GetIssuesArgs, GetIssuesResponse } from './get-issues.types';

export const fetchMachineIssues = (args: GetIssuesArgs) => {
  const { machineId, page = 1 } = args;

  return axios
    .get<GetIssuesResponse>(
      `${getCoreApiUrl('machines')}/${machineId}/issues?page=${page}&count=10`,
    )
    .then((response) => {
      return response.data;
    });
};

const groupKey = (machineId: number) => {
  return `machine-${machineId}-issues`;
};

const key = (args: GetIssuesArgs) => {
  const queries = [
    'machine',
    `machineId=${args?.machineId}`,
    'issues',
    `page=${args?.page}`,
  ];

  return [
    {
      groupKey: groupKey(args?.machineId),
      key: queries.join('|'),
    },
  ];
};

export const getMachineIssues = {
  groupKey,
  key,
  query: fetchMachineIssues,
};
