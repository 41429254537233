import {
  Checkbox,
  Conditional,
  Dropdown,
  Flex,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { debounce } from 'lodash';
import { formatAsCurrency } from '../../../helpers/formatter';
import { ReasonStockPropsDef } from './reason-stock.types';
import { StyledShortProductSummary } from './reason-stock.styles';
import { useHandleInteractions } from './hooks/use-handle-interactions';
import { useIsReasonStockDisabled } from './hooks/use-is-reason-stock-disabled';
import { PartStatusBadge } from '../../common/part-status-badge';

export const ReasonStock = (props: ReasonStockPropsDef) => {
  const { getIsDisabled, reasons, setSelectedStocksDetails, shipments, stock } =
    props;

  const {
    checked,
    handleCheck,
    handleSelectedReason,
    selectedReason,
    setSpecificReason,
    specificReason,
  } = useHandleInteractions({ setSelectedStocksDetails, stock });

  const disabled = useIsReasonStockDisabled({
    getIsDisabled,
    shipments,
    stock,
  });

  return (
    <Flex alignItems="flex-start" gap="x" justifyContent="space-between">
      <Flex gap="x">
        <Checkbox
          checked={checked}
          disabled={disabled}
          onSelected={handleCheck}
        />
        <StyledShortProductSummary
          alignContent="start"
          containerProps={{
            onClick: disabled
              ? undefined
              : () => {
                  handleCheck(!checked);
                },
          }}
          fetchSku={
            // ! NOT IDEAL TO USE THIS PROP. SHOULD REFACTOR AWAY FROM IT WHEN ORDERS GETS OVERHAULED
            stock.product.sku
          }
          imageSize={{ lg: '8rem', sm: '4rem' }}
        >
          <Stack
            gap="x"
            paddingTop=".5x"
            style={{ cursor: 'default' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PartStatusBadge status={stock.status} />

            <Conditional condition={checked}>
              <Dropdown
                items={reasons}
                placeholder="Select..."
                value={selectedReason?.value}
                onSelect={handleSelectedReason}
              />
            </Conditional>

            <Conditional
              condition={checked && !!selectedReason?.requireSpecifics}
            >
              <TextField
                placeholder="Specify reason"
                value={specificReason}
                onTextChange={debounce(setSpecificReason)}
              />
            </Conditional>
          </Stack>
        </StyledShortProductSummary>
      </Flex>

      <Typography
        align="right"
        text={formatAsCurrency(stock.product.amount.subtotal, {
          isCents: true,
        })}
      />
    </Flex>
  );
};
