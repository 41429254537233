import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AssignMachineFooterProps } from './assign-machine-footer.types';
import { startPrintPrintJobModalAtoms } from '../../../start-print-print-job-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { machinesApi } from '../../../../../../../../../../api';
import { bambuMachineTypes } from '../../../../../../../../../../constants/machines';
import { Machine } from '../../../../../../../../../../api/jobs/jobs.types';

const getBuildPlateNeedsChanged = (machine: Machine) => {
  const isBambuMachine = bambuMachineTypes.includes(machine?.type);

  return isBambuMachine ? false : machine?.startJobCount >= 20;
};

export const AssignMachineFooter = (props: AssignMachineFooterProps) => {
  const { handleCloseModal, job } = props;

  const assignedMachine = useRecoilValue(
    startPrintPrintJobModalAtoms.assignedMachine,
  );

  const setBuildPlateNeedsChanged = useSetRecoilState(
    startPrintPrintJobModalAtoms.buildPlateNeedsChanged,
  );
  const setUpdateOnClose = useSetRecoilState(
    startPrintPrintJobModalAtoms.updateOnClose,
  );
  const setStep = useSetRecoilState(startPrintPrintJobModalAtoms.step);

  const { notifyAxiosError, processing, setProcessing } = useNotification();

  const handleAssignMachine = async () => {
    try {
      setProcessing(true);

      let buildPlateNeedsChanged = getBuildPlateNeedsChanged(job.machine);

      if (job.machine?.id !== assignedMachine) {
        const { data } = await machinesApi.jobReassign({
          jobId: job.id,
          machineId: assignedMachine,
        });

        setUpdateOnClose(true);

        buildPlateNeedsChanged = getBuildPlateNeedsChanged(data.job.machine);
      }

      if (buildPlateNeedsChanged) {
        setBuildPlateNeedsChanged(buildPlateNeedsChanged);
      }

      setStep('MACHINE_TASKS');
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to assign machine.' });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        disabled={!assignedMachine}
        processing={processing}
        size={fullScreenModalFooterButtonSize}
        text="Confirm"
        onClick={handleAssignMachine}
      />
    </>
  );
};
