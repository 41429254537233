import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { Checkbox, SearchField, Stack } from 'gantri-components';
import { FilterProps } from '../../../../components/dropdowns/index';
import DatePicker from '../../../../components/common/date-picker';
import { payoutsPageAtoms } from '../../statements.atoms';
import { OnDateChanged } from '../../../../components/common/date-picker/date-picker.types';
import { FilterResetLabel } from '../../../../components/common/filter-reset-label';
import { AtomsCheckboxList } from '../../../../components/common/atoms-checkbox-list';
import {
  FilterGrid,
  FilterRow,
} from '../../../../components/common/filter-grid';
import {
  statementCategory,
  statementStatuses,
} from '../../../../api/statements/routes';
import { transactionTypesMap } from '../../../../constants/options';
import {
  FetchAllDesignersResponseDef,
  useFetchAllDesigners,
} from '../../../../api/designers/routes';
import { DesignerFilterRenderItem } from './components/designer-filter-render-item';

export const StatementsFilter: FC<FilterProps> = () => {
  const [dateRange, setDateRange] = useRecoilState(
    payoutsPageAtoms.filters.dateRange,
  );
  const [designerId, setDesignerId] = useRecoilState(
    payoutsPageAtoms.filters.designerId,
  );
  const [designersActiveOnly, setDesignersActiveOnly] = useRecoilState(
    payoutsPageAtoms.filters.designersActiveOnly,
  );

  const onDateChange: OnDateChanged = ({ formattedFrom, formattedTo }) => {
    setDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const statementsStatusFilterItems = Object.values(statementStatuses);
  const statementsCategoryFilterItems = Object.values(statementCategory);
  const statementsTypeFilterItems = [
    transactionTypesMap.order,
    transactionTypesMap.thirdParty,
    transactionTypesMap.trade,
    transactionTypesMap.wholesale,
  ];

  const { data, isLoading } =
    useFetchAllDesigners<FetchAllDesignersResponseDef>({
      transform: (response) => {
        const filteredDesigners = response.designers.filter(({ name }) => {
          return !!name;
        });

        if (designersActiveOnly) {
          const activeDesigners = filteredDesigners.filter(({ active }) => {
            return designersActiveOnly ? active : true;
          });

          return {
            ...response,
            designers: activeDesigners,
          } satisfies FetchAllDesignersResponseDef;
        }

        return {
          ...response,
          designers: filteredDesigners,
        } satisfies FetchAllDesignersResponseDef;
      },
      transformDependencies: [designersActiveOnly],
    });

  const designers = data?.designers || [];

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={payoutsPageAtoms.filters.dateRange}
          default={payoutsPageAtoms.defaults.filters.dateRange}
          text="Date range"
        />
        <DatePicker
          allowFutureDays
          initialValue={dateRange}
          range
          resetDates={payoutsPageAtoms.defaults.filters.dateRange}
          onDateChanged={onDateChange}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={payoutsPageAtoms.filters.statuses}
          default={payoutsPageAtoms.defaults.filters.statuses}
          text="Status"
        />
        <AtomsCheckboxList
          atom={payoutsPageAtoms.filters.statuses}
          gridProps={{ columns: 2 }}
          items={statementsStatusFilterItems}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atomsList={[
            payoutsPageAtoms.filters.designerId,
            payoutsPageAtoms.filters.designersActiveOnly,
          ]}
          defaultsList={[payoutsPageAtoms.defaults.filters.designerId, true]}
          text="Designer"
        />

        <Stack>
          <SearchField
            idProperty="id"
            items={designers}
            labelProperty="name"
            maxHeight="40rem"
            placeholder="Search designer"
            processing={isLoading}
            renderItem={DesignerFilterRenderItem}
            renderPlaceholder={DesignerFilterRenderItem}
            searchable
            value={designerId}
            onSelect={(item) => {
              setDesignerId(item?.id);
            }}
          />

          <Checkbox
            checked={designersActiveOnly}
            labelText="Active designers only"
            onSelected={setDesignersActiveOnly}
          />
        </Stack>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={payoutsPageAtoms.filters.categories}
          default={payoutsPageAtoms.defaults.filters.categories}
          text="Category"
        />
        <AtomsCheckboxList
          atom={payoutsPageAtoms.filters.categories}
          gridProps={{ columns: 2 }}
          items={statementsCategoryFilterItems}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={payoutsPageAtoms.filters.types}
          default={payoutsPageAtoms.defaults.filters.types}
          text="Type"
        />
        <AtomsCheckboxList
          atom={payoutsPageAtoms.filters.types}
          gridProps={{ columns: 2 }}
          items={statementsTypeFilterItems}
        />
      </FilterRow>
    </FilterGrid>
  );
};
