import React, { FC, useMemo, useState } from 'react';
import {
  Conditional,
  Dropdown,
  FormikInput,
  Grid,
  SearchField,
  Typography,
} from 'gantri-components';
import { useField, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import * as yup from 'yup';
import { orderTypeOptions } from './details-section.constant';
import { useFetchUsersEmails } from '../../../../api/products/routes/fetch-users-emails';
import { ResaleCertificate } from './resale-certificate';
import { transactionTypesMap } from '../../../../constants/options';

export const NewOrderDetailsSection: FC = React.memo(() => {
  const { setFormikState } = useFormikContext<any>();

  const { setFieldValue } = useFormikContext();

  const [{ value: discount }] = useField<number>('discount');
  const [{ value: orderType }] = useField<string>('orderType');
  const [{ value: email }] = useField<string>('email');
  const [query, setQuery] = useState<string>('');

  const showInvoiceInput = useMemo(() => {
    if (!orderType) return false;

    return ['Trade', 'Third Party', 'Wholesale'].includes(orderType);
  }, [orderType]);

  const showResaleCertificate = useMemo(() => {
    if (!orderType) return false;

    const isValidOrderType = [
      transactionTypesMap.wholesale,
      transactionTypesMap.trade,
    ].some((type) => {
      return type === orderType;
    });

    return isValidOrderType && !!email;
  }, [orderType, email]);

  const { data, isLoading } = useFetchUsersEmails({
    enabled: !!query,
    fetchArgs: {
      search: query,
    },
  });

  const users = data?.users;

  return (
    <Grid columns={2} gap="2x">
      <FormikInput
        Field={
          <Dropdown
            items={orderTypeOptions}
            placeholder="Select Type"
            onSelect={async (item) => {
              const orderType = item?.value;

              await setFieldValue('orderType', orderType);

              if (orderType === transactionTypesMap.trade && !discount) {
                await setFieldValue('discountType', 'percentage');
                await setFieldValue('discount', 15);
              }
            }}
          />
        }
        labelText="Type"
        name="orderType"
        required
      />

      <Conditional condition={showInvoiceInput}>
        <FormikInput
          labelText="Invoice #"
          name="invoice"
          placeholder="Invoice"
        />

        <FormikInput
          Field={
            <SearchField
              creatableLabel="user"
              idProperty="email"
              isCreatable
              isValidCreatableItem={(term) => {
                return yup.string().email().isValidSync(term);
              }}
              items={isLoading ? [] : users ?? []}
              labelProperty="email"
              maxHeight="33rem"
              minSearchLength={3}
              placeholder="Search"
              processing={isLoading}
              renderItem={(user) => {
                return (
                  <Typography
                    horizontalPadding="1.6rem"
                    text={user.email}
                    variant="p2"
                    verticalPadding="0.8rem"
                  />
                );
              }}
              searchable
              searchMode="external"
              value={email}
              onSelect={(op) => {
                const fileName = op?.resaleCertificate
                  ?.split('%2Fresale-certificate%2F')[1]
                  ?.split('?')?.[0];

                const fileUrl = op?.resaleCertificate;

                setFormikState((prevState) => {
                  return {
                    ...prevState,
                    values: {
                      ...prevState.values,
                      resaleCertificate: op?.hasResaleCertificate
                        ? {
                            fileName,
                            fileUrl,
                          }
                        : {
                            fileBlob: undefined,
                            fileName: undefined,
                            fileSize: undefined,
                            fileUrl: undefined,
                            metadata: undefined,
                          },
                      userSelected: op,
                    },
                  };
                });
              }}
              onTextChange={debounce(setQuery, 300)}
            />
          }
          fieldVariant="standard"
          labelText="Email"
          name="email"
          required
        />
      </Conditional>

      <Conditional condition={showResaleCertificate}>
        <ResaleCertificate />
      </Conditional>
    </Grid>
  );
});
