import styled from 'styled-components';
import { measureHeight } from 'react-div-100vh';
import { media, Stack } from 'gantri-components';

/** In pixels. Applicable to mobile and tablet. */
export const mobileAdminHeaderHeight = 48;

/** In pixels. Applicable to desktop only. */
export const desktopDetailsPanelWidth = 400;

const fullHeight = measureHeight();

export const detailsPanelZIndex = 999;

export const StyledDetailsPanelStack = styled(Stack)`
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  // added z-index to prevent jobs details panel from rendering on top of full screen job modals
  z-index: ${detailsPanelZIndex} !important;
  background-color: ${(props) => {
    return props.theme.colors.surfaces.monochrome.t1;
  }};
  border-left: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
  box-shadow: 0px 0.2rem 1.2rem rgba(0, 0, 0, 0.08);
  width: ${desktopDetailsPanelWidth}px;
  height: ${fullHeight}px;
  ${media.lessThan('md')`
    padding-top: ${mobileAdminHeaderHeight}px;
    width: 100vw;
    max-width: unset;
  `}
  a {
    text-decoration: underline;
  }
`;

export const StyledDetailsPanelTitle = styled.div`
  display: grid;
  border-bottom: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
  padding: 1.2rem 1.5rem;
  grid-template-columns: 1fr;
  justify-content: space-between;
  user-select: none;
  column-gap: 3rem;
  row-gap: 1.2rem;
`;

export const StyledDetailsPanelContent = styled.div`
  height: 100%;
  padding: 2rem 1.5rem 6rem;
  overflow: auto;
`;
