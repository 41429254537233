import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import { PaginatedInventoryPurchase } from '../../../../api/inventory-purchases/inventory-purchases.types';
import { TableSortField } from '../../../../components/common/table/table.props';
import routePaths from '../../../../config/route-paths';
import { formatAsCurrency, formatDate } from '../../../../helpers/formatter';
import { InventoryPurchaseStatusBadge } from '../../../inventory-purchase/components/status-badge';
import { InventoryPurchaseCell } from './components/inventory-purchase-cell';
import { VendorPurchasesSortingField } from './components/vendor-purchases-filters/vendor-purchases-filters.atoms';

export const vendorInventoryPurchasesSortFields: TableSortField<VendorPurchasesSortingField>[] =
  [
    {
      bidirectional: true,
      label: 'Created',
      sortingField: 'createdAt',
    },
    {
      bidirectional: true,
      label: 'Completed',
      sortingField: 'completedAt',
    },
  ];

export const columns: ColumnDef<PaginatedInventoryPurchase>[] = [
  {
    accessorKey: 'id',
    cell: InventoryPurchaseCell,
    header: 'Inventory Purchase #',
  },
  {
    accessorKey: 'createdAt',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['createdAt']
    >) => {
      return <Typography text={formatDate(getValue())} />;
    },
    header: 'Created',
    size: 100,
  },
  {
    accessorKey: 'completedAt',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['completedAt']
    >) => {
      return <Typography text={formatDate(getValue())} />;
    },
    header: 'Completed',
    size: 100,
  },
  {
    accessorKey: 'invoice',
    header: 'Invoice # (PO)',
    size: 100,
  },
  {
    accessorKey: 'userInfo',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['userInfo']
    >) => {
      const { fullName, id } = getValue();

      return (
        <Link to={`${routePaths.users}/${id}`}>
          <Typography color="link" decoration="underline" text={fullName} />
        </Link>
      );
    },
    header: 'User',
  },
  {
    accessorKey: 'inventory',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['inventory']
    >) => {
      const { id, name } = getValue();

      return (
        <Link to={`${routePaths.inventories}/${id}`}>
          <Typography color="link" decoration="underline" text={name} />
        </Link>
      );
    },
    header: 'Inventory',
    size: 250,
  },
  {
    accessorKey: 'inventory',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['inventory']
    >) => {
      const { stock, unit } = getValue();

      return <Typography text={`${stock} ${unit}`} />;
    },
    header: 'Units',
    id: 'units',
    size: 100,
  },
  {
    accessorKey: 'totalCost',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['totalCost']
    >) => {
      return (
        <Typography text={formatAsCurrency(getValue(), { isCents: true })} />
      );
    },
    header: 'Total',
    size: 100,
  },
  {
    accessorKey: 'status',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['status']
    >) => {
      return <InventoryPurchaseStatusBadge status={getValue()} />;
    },
    header: 'Status',
    size: 100,
  },
];
