import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchIdleMachinesArgs,
  FetchIdleMachinesResponse,
} from './fetch-idle-machines.types';
import { isOpaqueMaterial } from '../../../../constants/parts';

export const fetchIdleMachines = (args: FetchIdleMachinesArgs) => {
  const { material, type } = args;
  const materialOpacity = isOpaqueMaterial(material) ? 'opaque' : 'translucent';

  return axios.get<FetchIdleMachinesResponse>(
    `${getCoreApiUrl('machines')}/idle`,
    {
      params: { material, materialOpacity, type },
    },
  );
};
