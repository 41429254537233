import { Cell, Grid, Line, Typography } from 'gantri-components';
import routePaths from '../../../../../../config/route-paths';
import { Label } from '../../../../../../components/label';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { MachineDetailsSectionPropsDef } from './machine-details-section.types';
import { MachineStatusBadgeWithCheckMsg } from '../../../machine-status-badge-with-check-msg';

export const MachineDetailsSection = (props: MachineDetailsSectionPropsDef) => {
  const { machine } = props;

  return (
    <>
      <Grid columns="9rem 1fr" gap="2x">
        <Label text="Machine" />
        <Cell justifyItems="start">
          <StyledAnchor
            text={machine.name}
            to={`${routePaths.machines}/${machine.id}`}
          />
        </Cell>

        <Label text="Type" />
        <Typography text={machine.type} />

        <Label text="Status" />
        <MachineStatusBadgeWithCheckMsg
          hideIcon
          lastStatusChangeDate={machine.lastStatusChangeDate}
          status={machine.status}
        />
      </Grid>

      <Line />
    </>
  );
};
