import {
  FetchPaginatedStatementTransactionsArgsDef,
  FetchPaginatedStatementTransactionsResponseDef,
} from './fetch-paginated-statement-transactions.types';
import { fetchPaginatedStatementTransactions } from './fetch-paginated-statement-transactions';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';

export const useGetPaginatedStatementTransactions = <
  TransformedData = FetchPaginatedStatementTransactionsResponseDef,
>(
  props?: GenericFetchProps<
    FetchPaginatedStatementTransactionsArgsDef,
    FetchPaginatedStatementTransactionsResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch statement transaction records.',
    queryObj: getPaginatedTransactionsQueryObj,
    ...props,
  });
};

export const getPaginatedTransactionsQueryObj = getGenericQueryObj({
  groupName: 'statement-transactions-paginated-records',
  query: fetchPaginatedStatementTransactions,
});

export const useInvalidateGetPaginatedStatementTransactionsCache = () => {
  const invalidateGetPaginatedStatementTransactionsCache =
    useGetInvalidateQueryCache(getPaginatedTransactionsQueryObj);

  return { invalidateGetPaginatedStatementTransactionsCache };
};
