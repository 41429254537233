import { useFormikContext } from 'formik';
import {
  activeProductColorCodes,
  DynamicListItem,
  DynamicListItemDefaultData,
  productColorsMap,
} from 'gantri-components';
import { useMemo } from 'react';
import { useAsync } from 'react-use';
import {
  productAccessoriesSubCategories,
  productLightCategories,
  productLightSubCategories,
} from '../../../../../../../../api/products/products.constants';
import { newProductTypes } from '../../../../new-product-modal.constants';
import {
  NewProductColorItem,
  NewProductModalFormData,
  NewProductType,
} from '../../../../new-product-modal.type';

export const useNewProductModalContentData = () => {
  const { isValid, setFieldValue, validateForm, values } =
    useFormikContext<NewProductModalFormData>();

  const getIsAccessory = (type: NewProductType) => {
    return type === newProductTypes.accessory;
  };

  const isAccessory = getIsAccessory(values.type);

  const categoryItems: DynamicListItem<DynamicListItemDefaultData>[] =
    useMemo(() => {
      return Object.values(productLightCategories).map((value) => {
        return {
          label: value,
          value,
        };
      });
    }, []);

  const subCategoryItems: DynamicListItem<DynamicListItemDefaultData>[] =
    useMemo(() => {
      const subCategories = isAccessory
        ? productAccessoriesSubCategories
        : productLightSubCategories;

      return Object.values(subCategories).map((value) => {
        return {
          label: value,
          value,
        };
      });
    }, [isAccessory]);

  const colorItems: DynamicListItem<NewProductColorItem>[] = useMemo(() => {
    return activeProductColorCodes.map((code) => {
      return {
        code: productColorsMap[code]?.code,
        name: productColorsMap[code]?.shortColorName,
        showInternalOnly: true,
      };
    });
  }, []);

  const onTypeSelected = (type: NewProductType): void => {
    const category = getIsAccessory(type) ? type : '';

    // TODO: should be done in only one transaction
    setFieldValue('type', type);
    setFieldValue('category', category);
    setFieldValue('subCategory', '');
    setFieldValue('colors', []);
  };

  const handleColorChange = (
    value: NewProductColorItem | NewProductColorItem[],
  ) => {
    const selectedColors = Array.isArray(value) ? value : [value];

    setFieldValue('colors', selectedColors);
  };

  useAsync(async () => {
    if (isValid) {
      await validateForm(values);
    }
  }, [isAccessory]);

  return {
    categoryItems,
    colorItems,
    handleColorChange,
    isAccessory,
    onTypeSelected,
    subCategoryItems,
  };
};
