import { useEffect, useState } from 'react';
import { visibleMachineStatusOptions } from '../../../../machines-overview.atoms';
import { machineStatuses } from '../../../../../../constants/machines';
import { UseShowInProgressCheckPropsDef } from './use-show-in-progress-check.types';

/**
 * If `status` is `In progress`, `visibleStatuses` contains `In progress - Check`, and the `lastStatusChangeDate` is within `showCheckWithinMinutes`, returns `true`.
 *
 * If hook returns `true`, it will update to `false` once `lastStatusChangeDate` is no longer within `showCheckWithinMinutes`.
 */
export const useShowInProgressCheck = (
  props: UseShowInProgressCheckPropsDef,
) => {
  const {
    lastStatusChangeDate = 0,
    showCheckWithinMinutes,
    status,
    visibleStatuses,
  } = props;

  const [showInProgressCheck, setShowInProgressCheck] =
    useState<boolean>(false);

  useEffect(() => {
    let showCheckForMs = 0;

    if (status === machineStatuses.inProgress) {
      const shouldShowInProgressCheck =
        !visibleStatuses ||
        visibleStatuses?.some((visibleStatus) => {
          return visibleStatus === visibleMachineStatusOptions.inProgressCheck;
        });

      if (shouldShowInProgressCheck) {
        const displayForMs = showCheckWithinMinutes * 60 * 1000;
        const timeSinceLastStatusChange = Date.now() - lastStatusChangeDate;
        const isWithinTimeframe = timeSinceLastStatusChange < displayForMs;

        if (isWithinTimeframe) {
          showCheckForMs = displayForMs - timeSinceLastStatusChange;
        }
      }
    }

    if (!!showCheckForMs) {
      setShowInProgressCheck(true);

      const timeoutId = setTimeout(() => {
        setShowInProgressCheck(false);
      }, showCheckForMs);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [visibleStatuses, lastStatusChangeDate]);

  return showInProgressCheck;
};
