import { Flex, Icon, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { addJobModalAtoms } from '../../add-job-modal.atoms';
import { addJobModalSteps } from '../../add-job-modal.types.constants';

export const AddJobModalHeader = () => {
  const step = useRecoilValue(addJobModalAtoms.step);

  switch (step) {
    case addJobModalSteps.addJob:
      return (
        <Typography align="center" text="Add non-product job" variant="h4" />
      );

    case addJobModalSteps.jobAdded:
      return (
        <Flex alignItems="center" gap="x" justifyContent="center">
          <Icon
            color="success"
            name="ui-control:check_mark_circle_filled_24"
            size="2.4rem"
          />
          <Typography text="Job added" variant="h4" />
        </Flex>
      );

    case addJobModalSteps.jobNotAdded:
      return (
        <Flex alignItems="center" gap="x" justifyContent="center">
          <Icon color="alert" name="alert:warning_triangle_24" size="2.4rem" />
          <Typography text="Job not added" variant="h4" />
        </Flex>
      );

    default:
      return null;
  }
};
