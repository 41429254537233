import { StatusBadge } from '../../../../components/status-badge';
import { statusBadgeThemes } from '../../../../components/status-badge/status-badge.constants';
import { inventoryPurchaseStatuses } from '../../../../constants/inventory-purchases';
import { shipmentStatuses } from '../purchase-status-section/components/shipment/shipment.constants';
import { StatusBadgeProps } from './status-badge.types';

export const InventoryPurchaseStatusBadge = ({ status }: StatusBadgeProps) => {
  const statusText = status || shipmentStatuses.waiting;

  switch (status) {
    case inventoryPurchaseStatuses.partial:
    case inventoryPurchaseStatuses.arrived:
      return (
        <StatusBadge text={statusText} theme={statusBadgeThemes.blueLight} />
      );

    case inventoryPurchaseStatuses.completed:
    case inventoryPurchaseStatuses.delivered:
      return (
        <StatusBadge text={statusText} theme={statusBadgeThemes.greenLight} />
      );

    default:
      return (
        <StatusBadge
          text={statusText}
          theme={statusBadgeThemes.monochromeLight}
        />
      );
  }
};
