import {
  partsFiltersDefaults,
  partsPageAtoms,
} from '../../components/dropdowns/parts-filter/parts-filter.atoms';
import { GetPartsArgs } from '../../api/parts/routes/get-parts/get-parts.types';
import { ModalFiltersDetail } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';

export const modalsFiltersDetails: ModalFiltersDetail<GetPartsArgs>[] = [
  {
    atom: partsPageAtoms.filters.attentionOnly,
    defaultValue: partsFiltersDefaults.filters.attentionOnly,
    payloadKey: 'attentionOnly',
  },
  {
    atom: partsPageAtoms.filters.completedDateRange,
    defaultValue: partsFiltersDefaults.filters.completedDateRange,
    payloadKey: 'completedDateRange',
  },
  {
    atom: partsPageAtoms.filters.late,
    defaultValue: partsFiltersDefaults.filters.late,
    payloadKey: 'late',
  },
  {
    atom: partsPageAtoms.filters.locationId,
    defaultValue: partsFiltersDefaults.filters.locationId,
    payloadKey: 'locationId',
  },
  {
    atom: partsPageAtoms.filters.materials,
    defaultValue: partsFiltersDefaults.filters.materials,
    payloadKey: 'materials',
  },
  {
    atom: partsPageAtoms.filters.productId,
    defaultValue: partsFiltersDefaults.filters.productId,
    payloadKey: 'productId',
  },
  {
    atom: partsPageAtoms.filters.status,
    defaultValue: partsFiltersDefaults.filters.status,
    payloadKey: 'status',
  },
  {
    atom: partsPageAtoms.filters.stock,
    defaultValue: partsFiltersDefaults.filters.stock,
    payloadKey: 'stock',
  },
  {
    atom: partsPageAtoms.filters.orderId,
    defaultValue: partsFiltersDefaults.filters.orderId,
    payloadKey: 'orderId',
  },
  {
    atom: partsPageAtoms.filters.attentionAlerts,
    defaultValue: partsFiltersDefaults.filters.attentionAlerts,
    payloadKey: 'attentions',
  },
  {
    atom: partsPageAtoms.filters.lastActionDateRange,
    defaultValue: partsFiltersDefaults.filters.lastActionDateRange,
    payloadKey: 'lastActionDateRange',
  },
];
