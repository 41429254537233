import {
  Conditional,
  Flex,
  getFileUrl,
  Grid,
  Icon,
  ProductColorCode,
  productColorsMap,
  Stack,
  Typography,
} from 'gantri-components';
import { Fragment, PropsWithChildren, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { StyledThumbnailWrapper } from './short-product-summary.styles';
import { NotApplicableText } from '../not-applicable-text';
import {
  ConditionalAnchorProps,
  ShortProductSummaryProps,
} from './short-product-summary.types';
import { placeholderImageSrc } from '../../../constants/images';
import Thumbnail from '../thumbnail/thumbnail';
import { Divider } from '../../divider';
import { useFetchShortProductSummary } from '../../../api/products/routes';

export const ShortProductSummary = (
  props: PropsWithChildren<ShortProductSummaryProps>,
) => {
  const {
    alignContent = 'center',
    children,
    className,
    columns = 'max-content 1fr',
    containerProps = {},
    fetchSku,
    hideImage,
    href,
    imageSize = '5rem',
    target,
    ...productData
  } = props;

  const { data, isLoading } = useFetchShortProductSummary({
    enabled: !!fetchSku,
    fetchArgs: {
      sku: fetchSku,
    },
  });

  const {
    color,
    id,
    name,
    sku,
    thumbnail,
    variants = [],
  } = data?.product || productData;

  const fileUrl =
    getFileUrl<'products'>({
      directory: 'products',
      fileName: thumbnail,
      fileType: 'product-photos',
      identifiers: {
        productId: id,
        sku,
      },
    }) || placeholderImageSrc;

  const productOptions = useMemo(() => {
    return [
      productColorsMap[color as ProductColorCode]?.shortColorName,
      ...variants.map(({ label, name }) => {
        return `${label}: ${name}`;
      }),
    ].filter(Boolean);
  }, [variants]);

  return (
    <Conditional
      condition={isLoading && !id}
      Fallback={
        <Conditional condition={!!name} Fallback={<NotApplicableText />}>
          <Grid
            alignItems="start"
            columns={columns}
            gap="x"
            {...containerProps}
            className={className}
          >
            <Conditional condition={!hideImage}>
              <ConditionalAnchor href={href} target={target}>
                <StyledThumbnailWrapper>
                  <Thumbnail alt={name} size={imageSize} src={fileUrl} />
                </StyledThumbnailWrapper>
              </ConditionalAnchor>
            </Conditional>
            <Stack alignContent={alignContent} gap="0">
              <ConditionalAnchor href={href} target={target}>
                <Typography text={name} />
                <Conditional condition={!!productOptions}>
                  <Flex
                    alignItems="center"
                    columnGap="x"
                    rowGap="0"
                    wrap="wrap"
                  >
                    {productOptions.map((option, index) => {
                      return (
                        <Fragment key={option}>
                          <Conditional condition={index > 0}>
                            <Divider
                              borderColor="t1"
                              borderLocation="right"
                              height="1.6rem"
                            />
                          </Conditional>
                          <Typography color="t2" text={option} />
                        </Fragment>
                      );
                    })}
                  </Flex>
                </Conditional>
              </ConditionalAnchor>
              {children}
            </Stack>
          </Grid>
        </Conditional>
      }
    >
      <Icon color="link" name="animated:loader" size="2.4rem" />
    </Conditional>
  );
};

const ConditionalAnchor = (
  props: PropsWithChildren<ConditionalAnchorProps>,
) => {
  const { children, href, target } = props;

  return (
    <Conditional condition={!!href} Fallback={children}>
      <Link style={{ textDecoration: 'none' }} target={target} to={href}>
        {children}
      </Link>
    </Conditional>
  );
};
