import {
  Box,
  Conditional,
  Grid,
  Icon,
  Stack,
  Typography,
  FileUploader,
  HandleDeleteFile,
  TransformFileName,
  HandleUploadsComplete,
} from 'gantri-components';
import { useFirebaseFileUploader } from '../../../../../../../../../hooks/use-firebase-file-uploader/use-firebase-file-uploader';
import { GCodeDetailsProps } from './gcode-details.types';
import { StyledAnchor } from '../../../../../../../styled-anchor';
import { useDownloadWithSmallToast } from '../../../../../../../../../hooks/use-download-with-small-toast';
import { downloadToastText } from '../../../../../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';
import { formatGcodeFileName } from '../../../../../../../../../helpers/gcodes';

export const GCodeDetails = (props: GCodeDetailsProps) => {
  const {
    disabled,
    disabledDescription,
    forceOriginalFileName,
    gcode,
    hasError,
    inputName,
    isEditing,
    onBlur,
    onRemove,
    onUpload,
    partName,
    productName,
    productVersion,
    showDownloadToast,
    uploadPath,
    weight,
  } = props;

  let originalFileName = '';

  const { DownloadStatusToast, downloadSingleFile } = useDownloadWithSmallToast(
    { ...downloadToastText.gcode },
  );

  const onFileDelete: HandleDeleteFile = async () => {
    return onRemove();
  };

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName, fileUrl },
  ]) => {
    await onUpload({
      fileName,
      originalFileName,
      path: uploadPath,
      url: fileUrl,
    });
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: gcode?.url,
    handleUploadsComplete,
    isGcode: true,
    onFileDelete,
    path: uploadPath,
  });

  const transformFileName: TransformFileName = (props) => {
    const { fileName, removeWhitespace } = props;

    originalFileName = removeWhitespace(fileName);

    const formattedFileName: string = removeWhitespace(
      formatGcodeFileName({
        partName: forceOriginalFileName ? fileName : partName,
        productName,
        productVersion,
        weight,
      }),
    );

    return formattedFileName;
  };

  return (
    <Box
      style={{ display: 'inline-block' }}
      onClick={(event) => {
        return event.stopPropagation();
      }}
    >
      <Conditional
        condition={!!gcode?.fileName && !!gcode?.url}
        Fallback={
          <Conditional condition={isEditing}>
            <FileUploader
              {...fileUploaderProps}
              buttonProps={{
                disabled,
                icon: (
                  <Icon
                    color={disabled ? 't3' : hasError ? 'white' : 'link'}
                    name="arrows:arrow_upload"
                  />
                ),
                minWidth: '8rem',
                text: 'Upload G-code',
                variant: hasError ? 'primaryAlert' : 'secondary',
              }}
              disabledDescription={
                disabledDescription ||
                'Weight must be provided before you can upload a g-code.'
              }
              expectedExtensions={['gcode']}
              fileName={gcode?.url && gcode?.fileName}
              inputName={inputName}
              isDisabled={disabled}
              maxFileSizeMB={1000}
              transformFileName={transformFileName}
              variant="plain"
              onBlur={onBlur}
            />
          </Conditional>
        }
      >
        <Stack gap=".5x">
          <Conditional condition={showDownloadToast}>
            <DownloadStatusToast />
          </Conditional>
          <Typography text={gcode?.fileName} />
          <Grid
            alignItems="center"
            columns={isEditing ? 'max-content max-content' : '1fr'}
            gap=".5x"
          >
            <span>
              <Typography
                color={isEditing ? 't2' : 't1'}
                display="inline"
                text="File: "
                variant="p3"
              />
              <StyledAnchor
                display="inline"
                icon={
                  <Conditional condition={!isEditing}>
                    <Icon color="link" name="arrows:arrow_external" />
                  </Conditional>
                }
                iconPosition="right"
                text={gcode?.originalFileName || gcode?.fileName}
                variant="p3"
                onClick={() => {
                  return downloadSingleFile({
                    fileName: gcode?.fileName,
                    url: gcode?.url,
                  });
                }}
              />
            </span>
            <Conditional condition={isEditing}>
              <Icon
                color="alert"
                cursor="pointer"
                name="actions:trash_can"
                onClick={onRemove}
              />
            </Conditional>
          </Grid>
        </Stack>
      </Conditional>
    </Box>
  );
};
