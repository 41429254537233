export const statementStatuses = {
  cancelled: 'Cancelled',
  paid: 'Paid',
  processed: 'Processed',
  unpaid: 'Unpaid',
} as const;

export const statementCategory = {
  invoice: 'Invoice',
  royalty: 'Royalty',
} as const;
