import * as yup from 'yup';
import { optional, required } from '../../../../helpers/validators';
import { Material } from '../../../../constants/parts';

export interface ChangeSpoolFormDef {
  material: Material;
  state: 'new' | 'used';
  weight?: string;
}

export const ChangeSpoolSchema: yup.SchemaOf<ChangeSpoolFormDef> = yup
  .object()
  .shape({
    material: required() as yup.SchemaOf<Material>,
    state: required() as yup.SchemaOf<'new' | 'used'>,
    weight: optional() as yup.SchemaOf<string>,
  });
