import { Conditional, Dropdown, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import { MachineType } from '../../../../../../api/machines/machines.types';
import routePaths from '../../../../../../config/route-paths';
import { printerTypes } from '../../../../../../constants/machines';
import {
  deprecatedJobTypes,
  jobSteps,
} from '../../../../../../constants/options';
import { MachineTypeSectionProps } from './machine-type-section.types';

export const MachineTypeSection = (props: MachineTypeSectionProps) => {
  const { job, machineType, setMachineType, viewInEditMode } = props;
  const isTumbleRawJob =
    job?.step === jobSteps.finish && job?.type === deprecatedJobTypes.tumbleRaw;
  const tumblerType: MachineType = 'Tumbler';
  const tumblerMachineTypes: MachineType[] = [tumblerType];
  const printerMachineTypes: MachineType[] = printerTypes.filter((type) => {
    return type !== tumblerType;
  });
  const noneOption = 'None';
  const machinesTypes = isTumbleRawJob
    ? [noneOption, ...tumblerMachineTypes]
    : printerMachineTypes;
  const dropdownItems = machinesTypes.map((label) => {
    return {
      label,
      value: label === noneOption ? null : label,
    };
  });

  // Enforcing only editing on tumble jobs as product has requested that any changes to the weight
  // or machine type on a print > print or print > prepare job are copied to the other.
  // We are restricting this functionality to the product job templates tables as there shouldn't be any
  // reason to edit these values otherwise. Keeping logic for printer machine types in case we are wrong.
  return viewInEditMode && isTumbleRawJob ? (
    <Dropdown
      items={dropdownItems}
      placeholder={noneOption}
      value={machineType}
      onSelect={({ value }: { value: MachineType }) => {
        setMachineType(value);
      }}
    />
  ) : (
    <Conditional
      condition={!!job.machine?.id}
      Fallback={<Typography text={machineType} />}
    >
      <Link to={`${routePaths.machines}/${job.machine?.id}`}>
        <Typography color="link" text={machineType} />
      </Link>
    </Conditional>
  );
};
