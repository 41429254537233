import * as yup from 'yup';
import {
  jobStepsIncludingNa,
  jobTypeOptions,
} from '../../../../../constants/options';
import { AddJobFormValues } from './add-job-modal.types';

const requiredText = 'Required.';

export const schema: yup.SchemaOf<AddJobFormValues> = yup.object().shape({
  assignedTo: yup.array().of(yup.number()).required(requiredText),
  duration: yup.number().required(requiredText),
  instruction: yup.string().nullable(),
  step: yup
    .mixed()
    .oneOf(Object.values(jobStepsIncludingNa))
    .required(requiredText),
  type: yup.mixed().oneOf(Object.values(jobTypeOptions)).required(requiredText),
});
