import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  CancelOrRefundItemsArgs,
  CancelOrRefundItemsResponse,
} from './cancel-or-refund-items.types';

export const cancelOrRefundItems = (args: CancelOrRefundItemsArgs) => {
  const { orderId, ...data } = args;

  return axios
    .post<CancelOrRefundItemsResponse>(
      `${getCoreApiUrl('transactions')}/${orderId}/cancel-or-refund`,
      data,
    )
    .then(({ data }) => {
      return data;
    });
};
