import { useState } from 'react';
import { Button, Flex, Line, Stack } from 'gantri-components';
import { useParams } from 'react-router-dom';
import {
  ReasonStock,
  SelectedStockDetailsDef,
} from '../../components/order-reason/reason-stock';
import routePaths from '../../config/route-paths';
import { PageHeading } from '../../components/layout/page-heading';
import { useRouter } from '../../hooks';
import { useFetchOrder } from '../../api/transactions/routes';
import { MetaData } from '../../components/meta-data/metaData';
import { replacementReasonItems } from './order-replacement-reason.constants';
import { useConfirmReplacement } from './hooks/use-confirm-replacement';

export const OrderReplacementReason = () => {
  const { navigate } = useRouter();
  const { id: orderIdParam } = useParams<{ id: string }>();

  const id = Number(orderIdParam);

  const [selectedStocksDetails, setSelectedStocksDetails] = useState<
    SelectedStockDetailsDef[]
  >([]);

  const { data } = useFetchOrder({
    enabled: !!id,
    fetchArgs: {
      id,
    },
    showLoading: true,
  });

  const order = data?.order;

  const onCancel = () => {
    navigate(`${routePaths.orders}/${id}`);
  };

  const { disabled, onConfirmReplacement, processing } = useConfirmReplacement({
    order,
    selectedStocks: selectedStocksDetails,
  });

  const contentWidth = { lg: '70rem', sm: '100%' };

  const pageTitle = 'Create Replacement';

  return (
    <>
      <MetaData title={pageTitle} />
      <PageHeading
        subTitle="Select items below to create a replacement"
        title={pageTitle}
      />

      {order && (
        <Stack gap="5x" height="unset" verticalPadding="5x">
          <Stack gap="4x" height="unset" maxWidth={contentWidth}>
            {order.stocks.map((stock) => {
              return (
                <ReasonStock
                  key={stock.id}
                  getIsDisabled={({ hasShipped }) => {
                    return !hasShipped;
                  }}
                  reasons={replacementReasonItems}
                  setSelectedStocksDetails={setSelectedStocksDetails}
                  shipments={order.shipments}
                  stock={stock}
                />
              );
            })}
          </Stack>

          <Line />

          <Flex alignItems="center" gap="x">
            <Button text="Cancel" variant="secondary" onClick={onCancel} />
            <Button
              disabled={disabled}
              processing={processing}
              text="Confirm"
              variant="primary"
              onClick={onConfirmReplacement}
            />
          </Flex>
        </Stack>
      )}
    </>
  );
};
