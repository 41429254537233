import { Dropdown, FormikInput } from 'gantri-components';
import { useFormikContext } from 'formik';
import { useAsync } from 'react-use';
import { AddOrEditPartsFormData } from '../../../../add-or-edit-part-modal.types';
import {
  isOpaqueMaterial,
  isTranslucentMaterial,
} from '../../../../../../../../../../../../constants/parts';
import { partPaintedStatuses } from '../../../../../../../../../../../../api/products/products.constants';
import { PaintedStatus } from './components/painted-status';
import { convertStringArrayToValueLabelArray } from '../../../../../../../../../../../../helpers/formatter';

export const PartPaintedStatus = () => {
  const { setFieldValue, setFormikState, values } =
    useFormikContext<AddOrEditPartsFormData>();

  const { material, paintedStatus } = values;
  const isOpaque = isOpaqueMaterial(material);
  const isTranslucent = isTranslucentMaterial(material);
  const isPainted = paintedStatus === partPaintedStatuses.painted;

  const items = convertStringArrayToValueLabelArray(
    isOpaque
      ? [partPaintedStatuses.painted, partPaintedStatuses.unpainted]
      : [partPaintedStatuses.unpainted],
  );

  const placeholder = paintedStatus || 'Select';
  const disabled = !material || isTranslucent;

  useAsync(async () => {
    if (!isPainted) {
      await setFieldValue('colorOverride', []);
    }

    if (isTranslucent && (!paintedStatus || isPainted)) {
      setFormikState((state) => {
        return {
          ...state,
          values: {
            ...state.values,
            colorOverride: [],
            paintedStatus: partPaintedStatuses.unpainted,
          },
        };
      });
    }
  }, [isTranslucent, paintedStatus]);

  return (
    <FormikInput
      Field={
        <Dropdown
          disabled={disabled}
          items={items}
          labelPosition="top"
          maxHeight={400}
          placeholder={placeholder}
          renderItem={(props) => {
            return <PaintedStatus {...props} />;
          }}
          renderPlaceholder={(props) => {
            return <PaintedStatus {...props} disabled={disabled} />;
          }}
        />
      }
      fieldVariant="standard"
      labelText="Painted Status"
      name="paintedStatus"
      required
    />
  );
};
