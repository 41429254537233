import { useMemo } from 'react';
import routePaths from '../../../../config/route-paths';
import { transactionTypesMap } from '../../../../constants/options';
import { useRouter } from '../../../../hooks';
import { transactionsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import { Stock } from '../../../../types/store';
import { UseConfirmReplacementPropsDef } from './use-confirm-replacement.types';

export const useConfirmReplacement = (props: UseConfirmReplacementPropsDef) => {
  const { order, selectedStocks } = props;

  const { navigate } = useRouter();

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const isValid = useMemo(() => {
    return (
      selectedStocks.length &&
      selectedStocks.every(({ isValid }) => {
        return isValid;
      })
    );
  }, [selectedStocks]);

  const onConfirmReplacement = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const stockInfoIds = selectedStocks.map(({ stock }) => {
          return stock.stockInfoId;
        });

        const stocksWithReason = selectedStocks.map(({ reason, stock }) => {
          const stockWithReason: Stock = {
            ...stock,
            replacementReason: reason,
          };

          return stockWithReason;
        });

        const { data } = await transactionsApi.createTransaction({
          address: order.address,
          notes: `Replacement for order #${order.id}`,
          originalTransactionId: order.id,
          stockInfoIds,
          // @ts-expect-error - `quantity` not required for replacement transaction type
          stocks: stocksWithReason,
          type: transactionTypesMap.replacement,
        });

        if (data?.success) {
          const replacementOrderId = data.order.id;

          navigate(`${routePaths.orders}/${replacementOrderId}`);
        }
      } catch (error) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to create order.',
        });
      }
    });
  };

  return { disabled: !isValid, onConfirmReplacement, processing };
};
