import {
  Box,
  Cell,
  Conditional,
  Flex,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { PropsWithChildren } from 'react';
import { desktopDetailsPanelWidth } from '../../../../components/common/details-panel/details-panel.styles';
import { useSyncPropertyWithQueryString } from '../../../../hooks/useSyncPropertyWithQueryString';
import { DetailsPanel } from '../../../../components/common/details-panel';
import { useFetchMachineDetailsPanel } from '../../../../api/machines/routes/fetch-machine-detail-panel';
import { machineDetailsPanelIdQueryKey } from './machines-overview-details-panel.constants';
import { RepairHistory } from './components/repair-history';
import { CurrentPrintHarvestJob } from './components/current-print-harvest-job';
import { MachineDetailsSection } from './components/machine-details-section';
import { OpenIssues } from './components/open-issues';

export const MachinesOverviewDetailsPanel = (
  props: PropsWithChildren<Record<never, never>>,
) => {
  const { children } = props;

  const {
    currentValue: machineIdQueryValue,
    updateQueryString: updateMachineIdQueryValue,
  } = useSyncPropertyWithQueryString<number>(
    machineDetailsPanelIdQueryKey,
    undefined,
  );

  const showDetailsPanel = !!machineIdQueryValue;

  const { data, isLoading } = useFetchMachineDetailsPanel({
    enabled: showDetailsPanel,
    fetchArgs: { id: machineIdQueryValue },
  });

  const { currentPrintJob, issues, machine, repairs } = { ...data };

  const showContent = !!data && !isLoading;

  return (
    <>
      <Box
        height="100%"
        paddingRight={
          showDetailsPanel ? `${desktopDetailsPanelWidth}px` : undefined
        }
      >
        {children}
      </Box>

      <Conditional condition={showDetailsPanel}>
        <DetailsPanel
          header={
            /* Intentionally not using Conditional */
            showContent ? (
              <Cell>
                <Typography text={`Bay ${machine?.bay || ''}`} variant="h4" />
                <Typography color="t2" text={machine?.location} />
              </Cell>
            ) : undefined
          }
          open={showDetailsPanel}
          onClose={() => {
            updateMachineIdQueryValue(undefined);
          }}
        >
          {
            /* Intentionally not using Conditional */
            showContent ? (
              <Stack gap="4x">
                <MachineDetailsSection machine={machine} />

                <CurrentPrintHarvestJob
                  job={currentPrintJob}
                  machineId={machine?.id}
                />

                <OpenIssues issues={issues} machineId={machine?.id} />

                <RepairHistory machineId={machine?.id} repairs={repairs} />
              </Stack>
            ) : (
              <Flex justifyContent="center" padding="3x" width="100%">
                <Icon color="link" name="animated:loader" size="4rem" />
              </Flex>
            )
          }
        </DetailsPanel>
      </Conditional>
    </>
  );
};
