import { FetchGiftCodeResponse } from '../../api/gift-codes/routes/fetch-giftcode/fetch-giftcode.types';
import { discountTypes } from '../../api/transactions/transactions.constants';
import { formatAsCurrency, formatPercentage } from '../../helpers/formatter';

export const getUserType = (giftCode: FetchGiftCodeResponse): string => {
  return giftCode.userTypes.length === 4
    ? 'All types'
    : giftCode.userTypes.join(', ');
};

export const getAmount = (giftCode: FetchGiftCodeResponse): string => {
  return giftCode.type === discountTypes.discountPercent
    ? formatPercentage(giftCode.amount)
    : formatAsCurrency(giftCode.amount, { isCents: true });
};

export const getCreatedBy = (giftCode: FetchGiftCodeResponse): string => {
  return `${giftCode.user.firstName} ${giftCode.user.lastName}`;
};
