import { TooltipProps } from 'gantri-components/dist/components/tooltip/tooltip.types';
import {
  OrderStatus,
  orderStatusesMap,
  ShipmentStatus,
  shipmentStatuses,
  TransactionType,
} from '../../../../constants/options';
import { Shipment } from '../../../../types/store';

export const shipmentRequirements = {
  hasDeliveredShipment: 'hasDeliveredShipment',
  hasInProductionShipment: 'hasInProductionShipment',
  hasShippedShipment: 'hasShippedShipment',
  /** Has a shipment that has not yet started production. */
  hasWaitingShipment: 'hasWaitingShipment',
} as const;

export type ShipmentRequirement =
  typeof shipmentRequirements[keyof typeof shipmentRequirements];

export const preShipmentStatuses = [
  shipmentStatuses.waiting,
  shipmentStatuses.inProgress,
  shipmentStatuses.readyToShip,
] satisfies ShipmentStatus[];

export const unshippedStatuses = [
  shipmentStatuses.waiting,
  shipmentStatuses.inProgress,
  shipmentStatuses.readyToShip,
  shipmentStatuses.cancelled,
  shipmentStatuses.refunded,
] satisfies ShipmentStatus[];

export const getShipmentRequirements = (
  shipments: Shipment[],
): Record<ShipmentRequirement, boolean> => {
  const hasDeliveredShipment = shipments.some(({ status }) => {
    return status === shipmentStatuses.delivered;
  });

  const hasInProductionShipment = shipments.some((shipment) => {
    const hasUnshippedShipment = preShipmentStatuses.some((status) => {
      return status === shipment.status;
    });

    return hasUnshippedShipment;
  });

  const hasWaitingShipment = shipments.some(({ status }) => {
    return status === shipmentStatuses.waiting;
  });

  const hasShippedShipment = shipments.some((shipment) => {
    const isUnshippedShipment = unshippedStatuses.some((status) => {
      return status === shipment.status;
    });

    return !isUnshippedShipment;
  });

  const shipmentRequirements = {
    hasDeliveredShipment,
    hasInProductionShipment,
    hasShippedShipment,
    hasWaitingShipment,
  };

  return shipmentRequirements;
};

export interface GetOrderActionDisabledPropsDef {
  data: {
    orderStatus?: OrderStatus;
    orderType?: TransactionType;
    shipments: Shipment[];
  };
  enabledOrderStatuses?: OrderStatus[];
  enabledOrderTypes?: TransactionType[];
  enabledShipmentRequirements?: ShipmentRequirement[];
  /** Validates AFTER all other validations. */
  validate?: () => GetOrderActionsDisabledReturnDef;
}

interface GetOrderActionsDisabledReturnDef {
  disabled: boolean;
  disabledTooltipProps?: TooltipProps;
}

export const getOrderActionDisabledProps = (
  props: GetOrderActionDisabledPropsDef,
): GetOrderActionsDisabledReturnDef => {
  const {
    data,
    enabledOrderStatuses,
    enabledOrderTypes,
    enabledShipmentRequirements,
    validate,
  } = props;

  const { orderStatus, orderType, shipments } = data;

  const isValidOrderStatus =
    Object.values(orderStatusesMap).includes(orderStatus);

  if (!isValidOrderStatus) {
    return {
      disabled: true,
      disabledTooltipProps: {
        description: `Not available for order status "${orderStatus}".`,
      },
    };
  }

  if (!shipments?.length) {
    return {
      disabled: true,
      disabledTooltipProps: {
        description: 'This order has no shipments.',
      },
    };
  }

  if (enabledOrderTypes && !enabledOrderTypes.includes(orderType)) {
    return {
      disabled: true,
      disabledTooltipProps: {
        description: `Not available for order type "${orderType}".`,
      },
    };
  }

  if (enabledOrderStatuses && !enabledOrderStatuses.includes(orderStatus)) {
    return {
      disabled: true,
      disabledTooltipProps: {
        description: `Not available for order status "${orderStatus}".`,
      },
    };
  }

  if (enabledShipmentRequirements) {
    const shipmentRequirements = getShipmentRequirements(shipments);

    const isMeetingShipmentRequirement = enabledShipmentRequirements.some(
      (status) => {
        return shipmentRequirements[status];
      },
    );

    if (!isMeetingShipmentRequirement) {
      return {
        disabled: true,
        disabledTooltipProps: {
          description: getStatusByShipmentsDisabledMessage(
            enabledShipmentRequirements,
          ),
        },
      };
    }
  }

  if (validate) {
    // ! Should always be the last validation check
    return validate();
  }

  return { disabled: false };
};

const getStatusByShipmentsDisabledMessage = (
  enabledShipmentRequirements: ShipmentRequirement[],
): string => {
  if (
    enabledShipmentRequirements.includes(
      shipmentRequirements.hasWaitingShipment,
    )
  ) {
    return 'Requires waiting shipments.';
  }

  if (
    enabledShipmentRequirements.includes(
      shipmentRequirements.hasShippedShipment,
    )
  ) {
    return 'Requires completed shipments.';
  }

  if (
    enabledShipmentRequirements.includes(
      shipmentRequirements.hasInProductionShipment,
    )
  ) {
    return 'Requires unshipped items.';
  }

  if (
    enabledShipmentRequirements.includes(
      shipmentRequirements.hasDeliveredShipment,
    )
  ) {
    return 'Requires delivered shipments.';
  }

  return '';
};
