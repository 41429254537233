import {
  jobSteps,
  JobType,
  jobTypeOptions,
} from '../../../../../../../constants/options';

export const getDefaultStepForNonProductJobType = (type: JobType) => {
  if (type === jobTypeOptions.boxBreakdown) {
    return jobSteps.assemble;
  }

  if (type === jobTypeOptions.heavyMachineryRepair) {
    return jobSteps.technician;
  }

  if (type === jobTypeOptions.shredding) {
    return jobSteps.print;
  }

  return undefined;
};
