import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  CancelOrRefundItemsArgs,
  CancelOrRefundItemsResponse,
} from './cancel-or-refund-items.types';
import { cancelOrRefundItems } from './cancel-or-refund-items';

export const useCancelOrRefundItems = <
  TransformedData = CancelOrRefundItemsResponse,
>(
  props?: GenericMutateQueryProps<
    CancelOrRefundItemsArgs,
    CancelOrRefundItemsResponse,
    TransformedData
  >,
) => {
  const { onMutate: onCancelOrRefundItems, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to cancel or refund items.',
    mutationFn: cancelOrRefundItems,
    ...props,
  });

  return { ...rest, onCancelOrRefundItems };
};
