import { UseDownloadWithSmallToastProps } from './use-download-with-small-toast.types';

const getDownloadStatusTextFor = (fileType: string) => {
  return {
    completedText: `${fileType} download completed.`,
    errorText: `${fileType} download was not successful.`,
    startedText: `${fileType} download started.`,
  } as const;
};

export const downloadToastText = {
  csv: getDownloadStatusTextFor('CSV'),
  cutSheet: getDownloadStatusTextFor('Cut sheet'),
  file: getDownloadStatusTextFor('File'),
  gcode: getDownloadStatusTextFor('G-code'),
  handout: getDownloadStatusTextFor('Handout'),
  instruction: getDownloadStatusTextFor('Instructions'),
  rfidTag: getDownloadStatusTextFor('Tag'),
  statement: getDownloadStatusTextFor('Statement'),
} satisfies Record<string, UseDownloadWithSmallToastProps>;
