import { Conditional, Flex, Typography } from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Job } from '../../../../api/jobs/jobs.types';
import { JobActionButton } from './components/job-action-cell';
import { JobHandoutsCell } from './components/job-handouts-cell';
import { JobInventoriesCell } from './components/job-inventories-cell';
import { JobDescriptionCell } from './components/job-description-cell';
import { JobStatusCell } from './components/job-status-cell';
import { JobProductCell } from './components/job-product-cell';
import { JobStartDateCell } from './components/job-start-date-cell';
import { JobEndDateCell } from './components/job-end-date-cell';
import { JobPartCell } from './components/job-part-cell';
import { JobStockIdCell } from './components/job-stock-id-cell';
import { JobInstructionCell } from './components/job-instruction-cell';
import { JobMachineCell } from './components/job-machine-cell';
import { JobMachineIssuesCell } from './components/job-machine-issues-cell';
import { UseGetJobColumnsProps } from './use-get-job-columns.types';
import { CellJobAttempt } from '../../../part/components/part-jobs/components/cell-job-attempt';
import { JobMenu } from '../../../../components/common/jobMenu/jobMenu';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import routePaths from '../../../../config/route-paths';
import { JobOrderCell } from './components/job-order-cell';
import { downloadToastText } from '../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';
import { useDownloadWithSmallToast } from '../../../../hooks/use-download-with-small-toast';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';
import GcodeDataCell from '../../../../components/common/custom-data-cells/gcode-data-cell';
import { MachineDetailsMessage } from '../../../../components/common/machine-details-message/machine-details-message';

export const useGetJobColumns = (props: UseGetJobColumnsProps) => {
  const { onRefresh } = props;

  const {
    DownloadStatusToast: DownloadGcodeStatusToast,
    downloadSingleFile: downloadGcode,
  } = useDownloadWithSmallToast({ ...downloadToastText.gcode });

  const {
    DownloadStatusToast: DownloadInstructionStatusToast,
    downloadSingleFile: downloadInstruction,
  } = useDownloadWithSmallToast({ ...downloadToastText.instruction });

  const {
    DownloadStatusToast: DownloadHandoutStatusToast,
    downloadSingleFile: downloadHandout,
  } = useDownloadWithSmallToast({ ...downloadToastText.handout });

  const columns: ColumnDef<Job>[] = useMemo(() => {
    return [
      {
        accessorKey: 'description',
        cell: JobDescriptionCell,
        header: 'Job Name',
        meta: {
          hideable: false,
          reorderable: false,
        },
      },
      {
        accessorKey: 'attempt',
        cell: ({ getValue }: CellContext<Job, Job['attempt']>) => {
          return <CellJobAttempt attempt={getValue()} />;
        },
        header: 'Atmpt',
        meta: {
          hideable: false,
        },
        size: 60,
      },
      {
        accessorKey: 'step',
        header: 'Step',
        size: 100,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 150,
      },
      {
        accessorKey: 'status',
        cell: JobStatusCell,
        header: 'Status',
        meta: {
          hideable: false,
        },
        size: 140,
      },
      {
        accessorKey: 'product',
        cell: JobProductCell,
        header: 'Product',
        meta: {
          hideable: false,
        },
        minSize: 280,
      },
      {
        accessorKey: 'assignedTo',
        cell: ({ getValue }: CellContext<Job, Job['assignedTo']>) => {
          const assignedTo = getValue();

          return (
            <Conditional condition={!!assignedTo?.userName}>
              <StyledAnchor
                text={assignedTo?.userName}
                to={`${routePaths.users}/${assignedTo?.userId}`}
              />
            </Conditional>
          );
        },
        header: 'Assigned to',
      },
      {
        accessorKey: 'versionInfo',
        cell: ({ getValue }: CellContext<Job, Job['versionInfo']>) => {
          return <Typography text={getValue()?.version} />;
        },
        header: 'Version',
        size: 70,
      },
      {
        accessorKey: 'part',
        cell: JobPartCell,
        header: 'Part',
        meta: {
          getIsClickable: ({ getValue }: Cell<Job, Job['part']>) => {
            return !!getValue()?.id;
          },
        },
        size: 280,
      },
      {
        accessorKey: 'gcode',
        cell: ({ getValue }: CellContext<Job, Job['gcode']>) => {
          return <GcodeDataCell gcode={getValue()} />;
        },
        header: 'G-code',
        meta: {
          getOnClick: ({ getValue }: Cell<Job, Job['gcode']>) => {
            const gcode = getValue();
            const isClickable = !!gcode?.url;

            return isClickable
              ? () => {
                  return downloadGcode({
                    fileName: gcode?.fileName,
                    url: gcode?.url,
                  });
                }
              : undefined;
          },
        },
        minSize: 280,
      },
      {
        accessorKey: 'weight',
        cell: ({ getValue }: CellContext<Job, Job['weight']>) => {
          const weight = getValue();

          return <Typography text={weight && `${weight} g`} />;
        },
        header: 'Weight',
        size: 60,
      },
      {
        accessorKey: 'machine',
        cell: JobMachineCell,
        header: 'Machine',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 140,
      },
      {
        accessorKey: 'part',
        cell: ({ row }: CellContext<Job, never>) => {
          const { part } = row.original;

          return (
            <Conditional condition={!!part?.id}>
              <StyledAnchor
                href={`${routePaths.parts}/${part?.id}`}
                text={`#${part?.id}`}
              />
            </Conditional>
          );
        },
        header: 'Part #',
        id: 'partId',
        meta: {
          getIsClickable: ({ getValue }: Cell<Job, Job['part']>) => {
            return !!getValue()?.id;
          },
        },
        size: 100,
      },
      {
        accessorKey: 'startDate',
        cell: JobStartDateCell,
        header: 'Start',
        meta: {
          hideable: false,
        },
        size: 80,
      },
      {
        accessorKey: 'endDate',
        cell: JobEndDateCell,
        header: 'End',
        meta: {
          hideable: false,
        },
        size: 80,
      },
      {
        accessorKey: 'stockId',
        cell: JobStockIdCell,
        header: 'Stock #',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 80,
      },
      {
        accessorKey: 'order',
        cell: JobOrderCell,
        header: 'Order #',
        meta: {
          getIsClickable: ({ getValue }: Cell<Job, Job['order']>) => {
            return !!getValue()?.id;
          },
        },
        size: 130,
      },
      {
        accessorKey: 'batchId',
        cell: ({ getValue }: CellContext<Job, Job['batchId']>) => {
          const batchId = getValue();

          return (
            <Conditional condition={!!batchId}>
              <StyledAnchor
                href={`${routePaths.batches}/${batchId}`}
                text={`#${batchId}`}
              />
            </Conditional>
          );
        },
        header: 'Batch #',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 130,
      },
      {
        accessorKey: 'instruction',
        cell: JobInstructionCell,
        header: 'Instructions',
        minSize: 280,
      },
      {
        accessorKey: 'inventoryRequests',
        cell: JobInventoriesCell,
        header: 'Inventories',
        minSize: 280,
      },
      {
        accessorKey: 'duration',
        header: 'Dur (m)',
        size: 80,
      },
      {
        accessorKey: 'machineDetails',
        cell: (props: CellContext<Job, never>) => {
          const job = props.row.original;
          const machineType = job.machineType || job.machine?.type;
          const material = job.material || job.machine?.material;
          const visible =
            job.step === 'Print' &&
            ['Print', 'Transfer', 'Harvest'].includes(job.type);

          if (!visible) return '';
          if (!material && !machineType) return '';

          return `${machineType || 'N/A'} - ${material || 'N/A'}`;
        },
        header: () => {
          return <MachineDetailsMessage />;
        },
        meta: {
          label: 'Machine Details',
        },
        size: 150,
      },
      {
        accessorKey: 'machineIssues',
        cell: JobMachineIssuesCell,
        header: 'Machine Issues',
        size: 140,
      },
      {
        accessorKey: 'product',
        cell: (props: CellContext<Job, Job['product']>) => {
          return (
            <JobHandoutsCell {...props} downloadHandout={downloadHandout} />
          );
        },
        header: 'Handouts',
        id: 'handouts',
        meta: {
          getOnClick: ({ getValue }: Cell<Job, Job['product']>) => {
            const handouts = getValue()?.handouts || [];
            const isClickable = handouts.length === 1;

            return isClickable
              ? async () => {
                  return downloadHandout({
                    fileName: handouts[0].name,
                    url: handouts[0].link,
                  });
                }
              : undefined;
          },
          label: 'Handouts',
        },
        size: 160,
      },
      {
        cell: ({ row }: CellContext<Job, never>) => {
          const job = row.original;

          return (
            <Flex alignItems="center" gap="2x" justifyContent="flex-end">
              <JobActionButton job={job} onRefresh={onRefresh} />
              <JobMenu job={job} onRefresh={onRefresh} />
            </Flex>
          );
        },
        header: '',
        id: 'actions',
        meta: { hideable: false, label: 'Actions', reorderable: false },
        size: 30,
      },
    ];
  }, []);

  return {
    DownloadGcodeStatusToast,
    DownloadHandoutStatusToast,
    DownloadInstructionStatusToast,
    columns,
  };
};
