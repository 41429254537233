import { IconType } from 'gantri-components/dist/components/icon/icon.type';
import {
  deprecatedJobTypes,
  JobStep,
  jobSteps,
  jobStepsIncludingNa,
  JobType,
  jobTypeOptions,
} from './options';

/** Job types associated with product creation (plus the `Repair` job type). */
export const jobTypesForJobStep = {
  [jobSteps.assemble]: [jobTypeOptions.stage, jobTypeOptions.assemble],
  [jobSteps.finish]: [
    jobTypeOptions.glue,
    jobTypeOptions.sandRaw,
    jobTypeOptions.bondo,
    jobTypeOptions.mask,
    jobTypeOptions.sandPrimed,
    jobTypeOptions.sandPainted,
    jobTypeOptions.prime,
    jobTypeOptions.paint,
    jobTypeOptions.qc,
    jobTypeOptions.stage,
  ],
  [jobSteps.pack]: [jobTypeOptions.pack],
  [jobSteps.print]: [
    jobTypeOptions.transfer,
    jobTypeOptions.print,
    jobTypeOptions.harvest,
    jobTypeOptions.qc,
  ],
  [jobSteps.qa]: [jobTypeOptions.qc],
  [jobSteps.technician]: [jobTypeOptions.repair],
} satisfies Record<Exclude<JobStep, typeof jobStepsIncludingNa.na>, JobType[]>;

type DeprecatedJobType = 'Sand' | 'QA';

export const jobTypeToIconTypeMap: Record<
  JobType | DeprecatedJobType,
  IconType
> = {
  [jobTypeOptions.assemble]: 'work:assemble',
  [jobTypeOptions.bedTaping]: 'work:tape',
  [jobTypeOptions.bondo]: 'actions:link',
  [jobTypeOptions.boxBreakdown]: 'work:box',
  [jobTypeOptions.cleaning]: 'work:broom',
  [jobTypeOptions.glue]: 'work:glue',
  [jobTypeOptions.handoutsPrinting]: 'docs:document_lines',
  [jobTypeOptions.harvest]: 'work:basket_filled',
  [jobTypeOptions.heavyMachineryRepair]: 'work:wrench',
  [jobTypeOptions.inventoryManagement]: 'work:triangle_ruler',
  [jobTypeOptions.kitting]: 'ui-control:three_dots_cluster',
  [jobTypeOptions.mask]: 'work:mask',
  [jobTypeOptions.meeting]: 'people:people',
  [jobTypeOptions.pack]: 'work:box',
  [jobTypeOptions.paint]: 'work:water_drop',
  [jobTypeOptions.prime]: 'work:water_drop_dotted',
  [jobTypeOptions.print]: 'ui-control:lines_three',
  [jobTypeOptions.qc]: 'actions:thumbs_up',
  [jobTypeOptions.rAndDSupport]: 'work:light_bulb',
  [jobTypeOptions.repair]: 'work:print_nozzle',
  [jobTypeOptions.returnProcessing]: 'payment:shopping_bag',
  [jobTypeOptions.sandPainted]: 'work:sand_painted',
  [jobTypeOptions.sandPrimed]: 'work:sand_primed',
  [jobTypeOptions.sandRaw]: 'work:sand_paper',
  [jobTypeOptions.shipping]: 'work:label',
  [jobTypeOptions.shredding]: 'ui-control:lines_three_vertical',
  [jobTypeOptions.spoolChanging]: 'work:spool',
  [jobTypeOptions.spoolChangingBulk]: 'work:spool',
  [jobTypeOptions.stage]: 'work:assemble',
  [jobTypeOptions.toolMaintenance]: 'work:wrench',
  [jobTypeOptions.training]: 'people:people',
  [jobTypeOptions.transfer]: 'work:sd_card_outline',
  [jobTypeOptions.vanDelivery]: 'work:van',
  /** @deprecated */
  [deprecatedJobTypes.paintBoothCleaning]: 'work:broom',
  /** @deprecated */
  [deprecatedJobTypes.paintGunCleaning]: 'work:broom',
  /** @deprecated */
  [deprecatedJobTypes.qa]: 'actions:thumbs_up',
  /** @deprecated */
  [deprecatedJobTypes.sand]: 'work:sand_paper',
  /** @deprecated */
  [deprecatedJobTypes.sandRoomCleaning]: 'work:broom',
  /** @deprecated */
  [deprecatedJobTypes.tumbleRaw]: 'work:tumbler',
};

export const maxFinishAttemptsBeforeRestart = 5;
