import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchMachineDetailsPanelArgsDef,
  FetchMachineDetailsPanelResponseDef,
} from './fetch-machine-detail-panel.types';

export const fetchMachineDetailsPanel = async ({
  id,
}: FetchMachineDetailsPanelArgsDef) => {
  return axios
    .get<FetchMachineDetailsPanelResponseDef>(
      `${getCoreApiUrl('machines')}/${id}/detail-panel`,
    )
    .then(({ data }) => {
      return data;
    });
};
