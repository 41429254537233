import { useFormikContext } from 'formik';
import { Conditional, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { jobTypeToIconTypeMap } from '../../../../../../../../constants/jobs';
import { pluralize } from '../../../../../../../../helpers/pluralize';
import { addJobModalAtoms } from '../../../add-job-modal.atoms';
import { AddJobFormValues } from '../../../add-job-modal.types';
import { getNonProductJobDescription } from '../../../helpers/get-non-product-job-description';

export const JobAddedContent = () => {
  const users = useRecoilValue(addJobModalAtoms.users);

  const { values } = useFormikContext<AddJobFormValues>();

  const { assignedTo, type } = values;

  const jobCreatedBaseText = `${getNonProductJobDescription(
    values,
  )} ${pluralize('job', assignedTo.length)} created` as const;

  const userName =
    assignedTo.length === 1
      ? users.find(({ id }) => {
          return assignedTo[0] === id;
        })?.name
      : undefined;

  const JobTypeIcon = () => {
    return <Icon color="t2" name={jobTypeToIconTypeMap[type]} top="4px" />;
  };

  return (
    <Stack
      alignContent="center"
      justifyContent="center"
      minHeight="17.2rem"
      paddingBottom="2x"
    >
      <Conditional
        condition={assignedTo.length > 1}
        Fallback={
          <Conditional
            condition={!!userName}
            Fallback={
              <Typography
                align="center"
                icon={<JobTypeIcon />}
                text={`${jobCreatedBaseText}.`}
              />
            }
          >
            <Typography
              align="center"
              icon={<JobTypeIcon />}
              text={`${jobCreatedBaseText} and assigned to ${userName}.`}
            />
          </Conditional>
        }
      >
        <Typography
          align="center"
          icon={<JobTypeIcon />}
          text={`${jobCreatedBaseText} and assigned to ${assignedTo.length} operators.`}
        />
      </Conditional>
    </Stack>
  );
};
