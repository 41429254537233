import { FC, memo } from 'react';
import { Cell, Line, Stack, Table, Typography } from 'gantri-components';
import { StatementDef } from '../../../api/statements/routes';
import { useStatementItemsColumns } from './hooks/use-statement-items-columns';

export const StatementItems: FC<{
  statement: StatementDef;
}> = memo(({ statement }) => {
  const { total, transactions } = statement;

  const columns = useStatementItemsColumns({ total });

  return (
    <Stack gap="1.2rem" paddingTop="x" width="100%">
      <Typography text="Transactions" variant="h4" />
      <Cell>
        <Line />
        <Table
          columns={columns}
          data={transactions}
          highlightHoveredRow
          stickyLastColumn
        />
      </Cell>
    </Stack>
  );
});
