import { Job } from '../../../../api/jobs/jobs.types';
import {
  deprecatedJobTypes,
  jobStatuses,
  jobSteps,
  jobTypeOptions,
  nonProductJobTypes,
} from '../../../../constants/options';

export const getJobInfo = (job: Job) => {
  const { part, partId, status, step, stockId, type } = job || {};

  // JOB STATUS
  const isWaiting = status === jobStatuses.waiting;
  const isReady = status === jobStatuses.ready;
  const isInProgress = status === jobStatuses.inProgress;
  const isRejected = status === jobStatuses.rejected;

  // PRINT
  const isPrintTransferJob =
    step === jobSteps.print && type === jobTypeOptions.transfer;
  const isPrintPrintJob =
    step === jobSteps.print && type === jobTypeOptions.print;
  const isPrintHarvestJob =
    step === jobSteps.print && type === jobTypeOptions.harvest;
  const isPrintQcJob = step === jobSteps.print && type === jobTypeOptions.qc;

  // FINISH
  const isFinishSandJob =
    step === jobSteps.finish &&
    [
      deprecatedJobTypes.tumbleRaw,
      jobTypeOptions.sandPainted,
      jobTypeOptions.sandPrimed,
      jobTypeOptions.sandRaw,
    ].some((sandType) => {
      return sandType === type;
    });
  const isFinishGlueJob =
    step === jobSteps.finish && type === jobTypeOptions.glue;
  const isFinishMaskJob =
    step === jobSteps.finish && type === jobTypeOptions.mask;
  const isFinishBondoJob =
    step === jobSteps.finish && type === jobTypeOptions.bondo;
  const isFinishingQcJob =
    step === jobSteps.finish && type === jobTypeOptions.qc;
  const isFinishStageJob =
    step === jobSteps.finish && type === jobTypeOptions.stage;

  // ASSEMBLE
  const isAssembleStageJob =
    step === jobSteps.assemble && type === jobTypeOptions.stage;
  const isAssembleJob =
    step === jobSteps.assemble && type === jobTypeOptions.assemble;

  // QA
  const isFinalQaJob = step === jobSteps.qa && type === jobTypeOptions.qc;

  // PACKING
  const isPackingJob = step === jobSteps.pack && type === jobTypeOptions.pack;

  // REPAIR
  const isRepairJob =
    step === jobSteps.technician && type === jobTypeOptions.repair;

  // PRODUCT, NON-PRODUCT, FLOATING PART, STOCK JOBS
  const isNonProductJob = Object.values(nonProductJobTypes).some(
    (nonProductType) => {
      return nonProductType === type;
    },
  );
  const isProductJob = !isNonProductJob;
  const isFloatingPartJob = isProductJob && !stockId;
  const isStockJob = isProductJob && (!!part || !!partId);

  return {
    isAssembleJob,
    isAssembleStageJob,
    isFinalQaJob,
    isFinishBondoJob,
    isFinishGlueJob,
    isFinishMaskJob,
    isFinishSandJob,
    isFinishStageJob,
    isFinishingQcJob,
    isFloatingPartJob,
    isInProgress,
    /** Be aware that repair jobs use the regular jobs endpoints but are classified as non-product jobs. You may also need to use `isRepairJob` to provide special logic. */
    isNonProductJob,
    isPackingJob,
    isPrintHarvestJob,
    isPrintPrintJob,
    isPrintQcJob,
    isPrintTransferJob,
    /** Be aware that repair jobs use the regular jobs endpoints but are classified as non-product jobs. You may also need to use `isRepairJob` to provide special logic. */
    isProductJob,
    isReady,
    isRejected,
    isRepairJob,
    isStockJob,
    isWaiting,
  };
};
