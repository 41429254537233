import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  GetIdleStocksArgs,
  GetIdleStocksResponse,
} from './get-idle-stocks.types';
import { getIdleStocks } from './get-idle-stocks';

const fetchIdleStocksQueriesObj = getGenericQueryObj({
  groupName: 'fetch-idle-stocks',
  query: getIdleStocks,
});

export const useFetchIdleStocks = <TransformedData = GetIdleStocksResponse>(
  props?: GenericFetchProps<
    GetIdleStocksArgs,
    GetIdleStocksResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch idle stocks.',
    queryObj: fetchIdleStocksQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchIdleStocksCache = () => {
  const invalidateFetchIdleStocksCache = useGetInvalidateQueryCache(
    fetchIdleStocksQueriesObj,
  );

  return { invalidateFetchIdleStocksCache };
};
