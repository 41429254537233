import { Conditional, Flex } from 'gantri-components';
import { useRecoilCallback } from 'recoil';
import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { Label } from '../../label';
import { FilterResetLabelProps } from './filter-reset-label.types';
import { StyledAnchor } from '../styled-anchor';

/** If providing `atomsList` and `defaultsList`, indexes each ***MUST*** correlate. */
export const FilterResetLabel = <AtomType extends unknown>(
  props: FilterResetLabelProps<AtomType>,
) => {
  const {
    atom,
    atomsList,
    default: defaultValue,
    defaultsList,
    hideReset,
    style,
    text,
    transformDefaultValues,
    transformResetValues,
    ...flexProps
  } = props;

  const atomsArray = useMemo(() => {
    return atomsList || [atom];
  }, [atomsList, atom]);

  const atomDefaultsArray = useMemo(() => {
    return defaultsList || [defaultValue];
  }, [defaultsList, defaultValue]);

  const getIsDefaultValue = useRecoilCallback(
    ({ snapshot }) => {
      return () => {
        return atomsArray.every((atom, index) => {
          const defaultValue = atomDefaultsArray[index];
          const currentValue = snapshot.getLoadable(atom).contents;

          if (transformDefaultValues) {
            const transformedValue = transformDefaultValues(
              currentValue,
              index,
            );

            return isEqual(defaultValue, transformedValue);
          }

          return isEqual(defaultValue, currentValue);
        });
      };
    },
    [atomsArray, atomDefaultsArray],
  );

  const handleOnReset = useRecoilCallback(
    ({ reset, set, snapshot }) => {
      return () => {
        atomsArray.forEach((atom, index) => {
          const currentValue = snapshot.getLoadable(atom).contents;

          if (transformResetValues) {
            const transformedValue = transformResetValues(currentValue, index);

            set(atom, transformedValue);
          } else {
            reset(atom);
          }
        });
      };
    },
    [atomsArray, atomDefaultsArray],
  );

  const isDefaultValue = getIsDefaultValue();

  return (
    <Flex
      alignItems="baseline"
      columnGap="x"
      style={{ alignContent: 'start', ...style }}
      wrap="wrap"
      {...flexProps}
    >
      <Label text={text} />

      <Conditional condition={!isDefaultValue && !hideReset}>
        <StyledAnchor text="Reset" onClick={handleOnReset} />
      </Conditional>
    </Flex>
  );
};
