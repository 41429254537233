import {
  Conditional,
  Grid,
  SearchField,
  Stack,
  Typography,
} from 'gantri-components';
import { useAsync } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import React from 'react';
import { machinesApi } from '../../../../../../../../../../api';
import {
  IdleMachineDetails,
  idleMachinesAtoms,
} from '../../../../../../../../../../global-atoms/idle-machines';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { MachineDetails } from '../../../../../../common/job-details-panel-large/components/machine-details';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { startPrintPrintJobModalAtoms } from '../../../start-print-print-job-modal.atoms';
import { AssignMachineContentProps } from './assign-machine-content.types';

export const AssignMachineContent = (props: AssignMachineContentProps) => {
  const { job } = props;
  const { machineType, material } = job;
  const preAssignedMachineId = job.machine?.id;

  const [assignedMachine, setAssignedMachine] = useRecoilState(
    startPrintPrintJobModalAtoms.assignedMachine,
  );

  const setAssignedMachineInstance = useSetRecoilState(
    startPrintPrintJobModalAtoms.assignedMachineInstance,
  );

  const setMaterialChanged = useSetRecoilState(
    startPrintPrintJobModalAtoms.materialChanged,
  );

  const [idleMachinesOfType, setIdleMachinesOfType] = useRecoilState(
    idleMachinesAtoms[machineType],
  );

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  useAsync(async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await machinesApi.fetchIdleMachines({
          material,
          type: machineType,
        });

        const idleMachinesOfType = data.machines;

        setIdleMachinesOfType(idleMachinesOfType);

        // Handles jobs that had machines assigned before starting the Print Print job
        const isAssignedMachineInIdleList =
          !!preAssignedMachineId &&
          idleMachinesOfType.some(({ id }) => {
            return id === preAssignedMachineId;
          });

        if (isAssignedMachineInIdleList) {
          setAssignedMachine(preAssignedMachineId);
          setAssignedMachineInstance(job?.machine);
          setMaterialChanged(job?.machine?.material);
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch idle machines.',
        });
      }
    });
  }, []);

  return (
    <Stack gap="3x">
      <ModalContentHeading
        subTitleText={
          !!preAssignedMachineId && preAssignedMachineId === assignedMachine
            ? 'Review the selected ready machine and then confirm start job.'
            : 'Select a ready machine to continue.'
        }
        titleText="Machine assignment"
      />
      <SearchField
        disabled={processing}
        filterFn={(searchBy, machine) => {
          const searchPattern = new RegExp(searchBy, 'i');

          return [
            machine.name,
            machine.bay,
            `${machine.name} ${machine.bay}`,
          ].some((value) => {
            return searchPattern.test(value);
          });
        }}
        header={
          <Grid columnGap="x" columns="6rem 5rem 1fr" padding="x">
            <Typography color="t3" text="Machine" />
            <Typography color="t3" text="Bay" />
            <Typography color="t3" text="Material" />
          </Grid>
        }
        idProperty="id"
        items={idleMachinesOfType}
        labelProperty="name"
        placeholder="Search name..."
        renderItem={(machine) => {
          return (
            <Conditional condition={!!machine}>
              <Grid columnGap="x" columns="6rem 5rem 1fr" padding="x">
                <Typography text={machine?.name} />
                <Typography color="t2" text={machine?.bay} />
                <Typography color="t2" text={machine?.material} />
              </Grid>
            </Conditional>
          );
        }}
        renderPlaceholder={(machine) => {
          return (
            <Conditional condition={!!machine}>
              <MachineDetails bay={machine?.bay} name={machine?.name} />
            </Conditional>
          );
        }}
        value={assignedMachine}
        onSelect={(item: IdleMachineDetails) => {
          setAssignedMachine(item?.id);
          setAssignedMachineInstance(item);
          setMaterialChanged(item?.material);
        }}
      />
    </Stack>
  );
};
