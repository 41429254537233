import { MachineRecord } from '../../machines.types';
import { MachineStatus, machineStatuses } from '../../../../constants/machines';
import { PartialRecord } from '../../../../types/partial-record';

export const getChangeSpoolDisabled = ({
  status,
}: Pick<MachineRecord, 'status' | 'type'>): boolean => {
  const disabledStatuses: PartialRecord<MachineStatus, boolean> = {
    [machineStatuses.ready]: true,
    [machineStatuses.offline]: true,
    [machineStatuses.manual]: true,
  };

  return !disabledStatuses[status];
};
