import { useRecoilValue } from 'recoil';
import { addJobModalAtoms } from '../../add-job-modal.atoms';
import { addJobModalSteps } from '../../add-job-modal.types.constants';
import { AddJobFormContent } from '../step-add-job/add-job-form-content';
import { JobAddedContent } from '../step-job-added/job-added-content';
import { JobNotAddedContent } from '../step-job-not-added/job-not-added-content';

export const AddJobModalContent = () => {
  const step = useRecoilValue(addJobModalAtoms.step);

  switch (step) {
    case addJobModalSteps.addJob:
      return <AddJobFormContent />;

    case addJobModalSteps.jobAdded:
      return <JobAddedContent />;

    case addJobModalSteps.jobNotAdded:
      return <JobNotAddedContent />;

    default:
      return null;
  }
};
