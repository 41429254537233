import * as yup from 'yup';
import { statementStatuses } from '../../../api/statements/routes';
import { StatementFormSchemaDef } from './statement-form.types';

const requiredText = 'Required.';

export const statementUpdateFormSchema: yup.SchemaOf<StatementFormSchemaDef> =
  yup.object().shape({
    email: yup.string().nullable(),
    method: yup
      .string()
      .nullable()
      .when('status', {
        is: (status) => {
          return status === statementStatuses.paid;
        },
        then: (schema) => {
          return schema.required(requiredText);
        },
      }),
    name: yup.string().nullable(),
    notes: yup.string().nullable(),
    status: yup
      .mixed()
      .oneOf(Object.values(statementStatuses))
      .required(requiredText),
  });
