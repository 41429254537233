import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { sortBy } from 'lodash';
import { UpdateProduct } from '../../product.types';
import { useSyncPropertyWithQueryString } from '../../../../hooks/useSyncPropertyWithQueryString';
import { ProductFetchType } from '../../../../api/products/routes/get-product/get-product.types';
import { productAtoms } from '../../product.atoms';
import {
  productFetchTypes,
  useFetchProduct,
  useInvalidateFetchProductCache,
  useInvalidateGetAllProductsCache,
} from '../../../../api/products/routes';
import { productCategories } from '../../../../api/products/products.constants';
import { useUpdateProduct } from '../../../../api/products/routes/update-product';
import { productJobsTabAtoms } from '../../components/product-jobs/product-jobs.atoms';
import { useHandleSetSelectedSku } from '../use-handle-set-selected-sku';
import { productQueryKeys } from '../../product.constants';

interface UseFetchProductDataProps {
  autoFetch?: boolean;
}

export const useFetchProductData = (props?: UseFetchProductDataProps) => {
  const { autoFetch } = props || {};

  const { id } = useParams<{ id: string }>();
  const productId = Number(id);

  const { currentValue: tabQuery } =
    useSyncPropertyWithQueryString<ProductFetchType>(
      productQueryKeys.tab,
      productFetchTypes.info,
    );

  const versionId = useRecoilValue(productJobsTabAtoms.versionId);
  const setDraftVersionId = useSetRecoilState(
    productJobsTabAtoms.draftVersionId,
  );
  const [product, setProduct] = useRecoilState(productAtoms.product);
  const setProductStatus = useSetRecoilState(productAtoms.productStatus);
  const setIsAccessory = useSetRecoilState(productAtoms.isAccessory);
  const setIsPendantLight = useSetRecoilState(productAtoms.isPendantLight);
  const setIsWallLight = useSetRecoilState(productAtoms.isWallLight);
  const setIsWallSconce = useSetRecoilState(productAtoms.isWallSconce);
  const setSkus = useSetRecoilState(productAtoms.skus);
  const selectedSku = useRecoilValue(productAtoms.selectedSku);

  const { handleSetSelectedSku, skuQuery } = useHandleSetSelectedSku();

  const tabRequiresSkuForFetch = [
    productFetchTypes.assets,
    productFetchTypes.costs,
  ].some((fetchType) => {
    return fetchType === tabQuery;
  });

  const { isLoading: isFetchingProduct } = useFetchProduct({
    enabled: !!autoFetch,
    fetchArgs: {
      id: productId,
      sku: tabRequiresSkuForFetch ? selectedSku || skuQuery : undefined,
      type: tabQuery,
      versionId,
    },
    onSuccess: async ({ product }) => {
      const isAccessory = product.category === productCategories.accessory;
      const isPendantLight =
        product.category === productCategories.pendantLight;
      const isWallLight = product.category === productCategories.wallLight;
      const isWallSconce = product.category === productCategories.wallSconce;

      const { skus } = product;

      setProduct(product);
      // TODO: we should replace this unnecessary updates using recoil selectors
      setProductStatus(product.status);
      setIsAccessory(isAccessory);
      setIsPendantLight(isPendantLight);
      setIsWallLight(isWallLight);
      setIsWallSconce(isWallSconce);
      setSkus(skus);

      if (product.version?.status === 'Draft') {
        setDraftVersionId(product.version.id);
      }
    },
    showLoading: true,
  });

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();
  const { invalidateGetAllProductsCache } = useInvalidateGetAllProductsCache();

  const { isLoading: isUpdatingProduct, onUpdateProduct } = useUpdateProduct({
    onSuccess: async () => {
      await refetchProduct();
    },
    showLoading: true,
  });

  const updateProduct: UpdateProduct = async (updatedProductData = null) => {
    await onUpdateProduct({ product: updatedProductData ?? product });
  };

  const refetchProduct = async () => {
    await invalidateFetchProductCache();

    await invalidateGetAllProductsCache();
  };

  const skus = product?.skus || [];

  useEffect(() => {
    const isSkuQueryInList = skus.some((sku) => {
      return skuQuery === sku;
    });

    const isSelectedSkuInList = skus.some((sku) => {
      return sku === selectedSku;
    });

    if (isSkuQueryInList && skuQuery !== selectedSku) {
      handleSetSelectedSku(skuQuery);
    } else if (skus.length && !isSelectedSkuInList) {
      const defaultSku = sortBy(product?.colors || [], ['name'])?.[0]
        ?.defaultSku;
      const [firstSku] = skus;

      handleSetSelectedSku(defaultSku || firstSku);
    }
  }, [skus, product?.colors]);

  return {
    invalidateFetchProductCache,
    isFetchingProduct,
    isUpdatingProduct,
    processing: isFetchingProduct || isUpdatingProduct,
    refetchProduct,
    updateProduct,
  };
};
