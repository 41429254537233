import { capitalize } from 'lodash';
import { jobStatuses } from '../../../../../../../../../constants/options';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { ConfirmBeginContentProps } from './confirm-begin-content.types';

export const ConfirmBeginContent = (props: ConfirmBeginContentProps) => {
  const { job } = props;

  const isInProgress = job.status === jobStatuses.inProgress;

  const action = isInProgress ? 'complete' : 'start';

  return (
    <ModalContentHeading
      subTitleText={`Are you sure you want to ${action} the ${job.step} ${job.type} job?`}
      titleText={`${capitalize(action)} job`}
    />
  );
};
