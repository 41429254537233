import { Icon, Tooltip, Typography } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import { ReportMachinesGroupBy } from '../../../../api/dashboard/routes/get-report-machines/get-report-machines.types';
import { getSortByFilterAtomFamily } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { SortBy } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { CurrencyCell } from '../../../dashboard/components/common/currency-cell';
import { NumberCell } from '../../../dashboard/components/common/number-cell';
import { PercentCell } from '../../../dashboard/components/common/percent-cell';
import { reportPageSortByDefaults } from '../common/report-page/components/report-table/report-table.constants';
import { convertToDollars } from '../common/report-page/components/report-table/helpers/convert-to-dollars';
import { DownloadableTableColumn } from '../common/report-page/components/report-table/report-table.types';
import { getSortingOptionsFromColumns } from '../common/report-page/helpers/get-sorting-options-from-columns';
import {
  ReportMachineIssuesTableData,
  ReportMachinesTableData,
} from './report-machines.types';

export const defaultMachinesGroupBy: ReportMachinesGroupBy = 'machineType';

export const machinesBaseTableColumns: DownloadableTableColumn<ReportMachinesTableData>[] =
  [
    {
      accessorKey: 'offline',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachinesTableData,
        ReportMachinesTableData['offline']
      >) => {
        return <PercentCell value={getValue()} />;
      },
      header: 'Offline %',
    },
    {
      accessorKey: 'idle',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachinesTableData,
        ReportMachinesTableData['idle']
      >) => {
        return <PercentCell value={getValue()} />;
      },
      header: () => {
        return (
          <Typography
            icon={
              <Tooltip position="top" title="Ready + Manual">
                <Icon
                  name="alert:i_circle"
                  size="1.6rem"
                  style={{ top: '2px' }}
                />
              </Tooltip>
            }
            iconPosition="right"
            style={{ fontFamily: 'inherit' }}
            text="Idle %"
          />
        );
      },
      label: 'Idle %',
    },
    {
      accessorKey: 'utilization',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachinesTableData,
        ReportMachinesTableData['utilization']
      >) => {
        return <PercentCell value={getValue()} />;
      },
      header: 'Utilization %',
    },
    {
      accessorKey: 'issuesOpened',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachinesTableData,
        ReportMachinesTableData['issuesOpened']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: 'Issues Opened #',
    },
    {
      accessorKey: 'issuesResolved',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachinesTableData,
        ReportMachinesTableData['issuesResolved']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: 'Issues Resolved #',
    },
    {
      accessorKey: 'issuesCost',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachinesTableData,
        ReportMachinesTableData['issuesCost']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Issues Cost $',
      transformDownload: convertToDollars,
    },
  ];

export const machinesBaseSortingOptions = getSortingOptionsFromColumns(
  machinesBaseTableColumns,
);

export const reportMachineIssuesTableColumns: DownloadableTableColumn<ReportMachineIssuesTableData>[] =
  [
    {
      accessorKey: 'created',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachineIssuesTableData,
        ReportMachineIssuesTableData['created']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Created',
    },
    {
      accessorKey: 'resolved',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachineIssuesTableData,
        ReportMachineIssuesTableData['resolved']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Resolved',
    },
    {
      accessorKey: 'ofCreated',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachineIssuesTableData,
        ReportMachineIssuesTableData['ofCreated']
      >) => {
        return <PercentCell value={getValue()} />;
      },
      header: '% of Created',
    },
    {
      accessorKey: 'cost',
      cell: ({
        getValue,
      }: CellContext<
        ReportMachineIssuesTableData,
        ReportMachineIssuesTableData['cost']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Cost $',
      transformDownload: convertToDollars,
    },
  ];

export const reportMachineIssuesSortingOptions = getSortingOptionsFromColumns(
  reportMachineIssuesTableColumns,
);

export const pageName: AtomPageName = 'report-machines';

export const reportMachinesPageAtoms = {
  sortBy: getSortByFilterAtomFamily<
    SortBy<keyof ReportMachinesTableData | keyof ReportMachineIssuesTableData>
  >({
    defaultValue: reportPageSortByDefaults,
  })(pageName),
};
