import { Cell } from 'gantri-components';
import { shipmentStatuses } from '../../shipment.constants';
import { InventoryPurchaseStatusBadge } from '../../../../../status-badge';
import { ShipmentStatusCellProps } from './shipment-status-cell.types';
import { Label } from '../../../../../../../../components/label';

export const ShipmentStatusCell = ({ status }: ShipmentStatusCellProps) => {
  return (
    <Cell>
      <Label paddingBottom=".5x" text="Status" />
      <Cell justifyItems="flex-start">
        <InventoryPurchaseStatusBadge
          status={status || shipmentStatuses.waiting}
        />
      </Cell>
    </Cell>
  );
};
