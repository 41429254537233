import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchConfigurationsParamsDef,
  FetchConfigurationsResponse,
} from './fetch-configurations.types';

export const fetchConfigurations = (params: FetchConfigurationsParamsDef) => {
  return axios
    .get<{ data: FetchConfigurationsResponse }>(
      getCoreApiUrl('configurations'),
      {
        params,
      },
    )
    .then(({ data }) => {
      return data.data;
    });
};
