import {
  Button,
  Cell,
  Conditional,
  Flex,
  Stack,
  Tooltip,
  Typography,
  useConfirmationModal,
} from 'gantri-components';
import { HoursUntilMaintenanceProps } from './hours-until-maintenance.types';
import { useSpinner } from '../../../../hooks';
import { useMachineQueriesCache } from '../../queries-cache';
import { NotApplicableText } from '../../../../components/common/not-applicable-text';
import { useResetTimeUntilMaintenance } from '../../../../api/machines/routes/reset-time-until-maintenance';
import { useUserHasAccessLevel } from '../../../../hooks/use-user-has-access-level';
import { userAccessDeniedMessage } from '../../../../constants/users';
import { machineTypes } from '../../../../constants/machines';

export const HoursUntilMaintenance = ({
  currentMachine,
}: HoursUntilMaintenanceProps) => {
  const { id, printHours, type: machineType } = currentMachine;
  const { onInterceptRequest } = useSpinner();
  const invalidateCache = useMachineQueriesCache();

  const { onResetTimeUntilMaintenance } = useResetTimeUntilMaintenance();

  const { hasLeadAccess } = useUserHasAccessLevel();

  const resetMachineMaintenanceHours = async () => {
    await onInterceptRequest(async () => {
      await onResetTimeUntilMaintenance(id, {
        onSuccess: async () => {
          await invalidateCache(currentMachine.id);
          hideResetMaintenanceHoursModal();
        },
      });
    });
  };

  const [showResetMaintenanceHoursModal, hideResetMaintenanceHoursModal] =
    useConfirmationModal({
      content: (
        <Stack alignContent="center" height={{ lg: '12rem', md: 'unset' }}>
          <Typography
            align="center"
            text="Are you sure you want to reset the counter?"
          />
        </Stack>
      ),
      headerText: 'Hours until maintenance',
      onConfirm: resetMachineMaintenanceHours,
      width: { lg: '40rem', md: 'auto' },
    });

  const isMachineTrackingMaintenance =
    getIsMachineTrackingMaintenance(machineType);

  const maxPrintHours = {
    [machineTypes.creality]: 75,
    [machineTypes.p1p]: 500,
  } as const;

  const disabled =
    typeof printHours === 'number' && printHours === maxPrintHours[machineType];

  return (
    <Cell gap="x">
      <Typography text="Hours until maintenance" textStyle="bold" />
      <Conditional
        condition={isMachineTrackingMaintenance}
        Fallback={<NotApplicableText />}
      >
        <Flex alignItems="center" gap="x">
          <Typography color="t1" text={printHours || 0} />
          <Tooltip description={!hasLeadAccess && userAccessDeniedMessage}>
            <Button
              disabled={disabled || !hasLeadAccess}
              size="small"
              text="Reset"
              variant="ghost"
              onClick={showResetMaintenanceHoursModal}
            />
          </Tooltip>
        </Flex>
      </Conditional>
    </Cell>
  );
};

export const getIsMachineTrackingMaintenance = (machineType) => {
  return [machineTypes.creality, machineTypes.p1p].some((type) => {
    return type === machineType;
  });
};
