import { Cell, Conditional, Grid, Line, Typography } from 'gantri-components';
import { useMemo } from 'react';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { SummarySectionsPropsDef } from './summary-section.types';
import { useFetchOrderSummary } from '../../../../api/transactions/routes';
import { SummaryValue } from './components/summary-value';

export const SummarySection = (props: SummarySectionsPropsDef) => {
  const { isReturn, order, refundShipping, selectedStocksDetails } = props;

  const stockAssociationIds = useMemo(() => {
    return selectedStocksDetails.map(({ stock }) => {
      return stock.stockInfoId;
    });
  }, [selectedStocksDetails]);

  const enableFetchOrderSummary =
    !!selectedStocksDetails?.length || refundShipping;

  const { data, isLoading } = useFetchOrderSummary({
    enabled: enableFetchOrderSummary,
    fetchArgs: {
      id: order.id,
      refundShipping,
      stockAssociationIds,
    },
  });

  const {
    credit = 0,
    discount = 0,
    gift = 0,
    shipping = 0,
    subtotal = 0,
    tax = 0,
    total = 0,
  } = enableFetchOrderSummary ? data?.amount || {} : {};

  const discountTotal = discount || gift;

  const forReturnOrRefundedText = isReturn ? 'for return' : 'refunded';

  return (
    <>
      <Typography text="Summary" variant="h4" />

      <Grid
        columnGap="x"
        columns="1fr max-content"
        justifyContent="space-between"
        rowGap="0"
      >
        <Cell width={2}>
          <Line verticalMargin="x" />
        </Cell>

        <Typography text="Subtotal" />
        <SummaryValue
          color="t2"
          processing={isLoading}
          text={formatAsCurrency(subtotal, {
            isCents: true,
          })}
        />

        <Typography text="Shipping" />
        <SummaryValue
          color="t2"
          processing={isLoading}
          text={formatAsCurrency(shipping, {
            isCents: true,
          })}
        />

        <Typography text="Tax" />
        <SummaryValue
          color="t2"
          processing={isLoading}
          text={formatAsCurrency(tax, { isCents: true })}
        />

        <Conditional condition={!!discountTotal}>
          <Typography text="Discount" />
          <SummaryValue
            color="t2"
            processing={isLoading}
            text={formatAsCurrency(-discountTotal, {
              isCents: true,
            })}
          />
        </Conditional>

        <Cell width={2}>
          <Line verticalMargin="x" />
        </Cell>

        <Conditional condition={!!credit}>
          <Typography text={`Credit ${forReturnOrRefundedText}`} />
          <SummaryValue
            processing={isLoading}
            text={formatAsCurrency(credit, { isCents: true })}
          />
        </Conditional>

        <Typography text={`Funds ${forReturnOrRefundedText}`} />
        <SummaryValue
          processing={isLoading}
          text={formatAsCurrency(total, { isCents: true })}
        />
      </Grid>
    </>
  );
};
