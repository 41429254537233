import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { BulkUpdateArgs, BulkUpdateResponse } from './bulk-update.types';

export const bulkUpdate = (request: BulkUpdateArgs) => {
  return axios
    .put<BulkUpdateResponse>(
      `${getCoreApiUrl('machines')}/bulk-update`,
      request,
    )
    .then(({ data }) => {
      return data;
    });
};
