import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreateNonProductJobResponse,
  CreateNonProductJobArgs,
} from './create-non-product-job.types';

export const createNonProductJob = async (body: CreateNonProductJobArgs) => {
  const { data } = await axios.post<CreateNonProductJobResponse>(
    `${coreApiUrl}/jobs/non-product`,
    body,
  );

  return data;
};
