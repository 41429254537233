import { Job } from '../../../../../../../../api/jobs/jobs.types';
import { MachineStatus } from '../../../../../../../../api/machines/routes';
import { machineStatuses } from '../../../../../../../../constants/machines';
import { getJobInfo } from '../../../../../../helpers/get-job-info';

/** Disables job action button (Start/Complete) based on the provided machine status. If no machine status is provided, returns false. */
export const getDisableJobActionButton = (props: {
  job: Job;
  machineStatus: MachineStatus;
}) => {
  const { job, machineStatus } = props;

  const { isInProgress, isPrintHarvestJob, isPrintPrintJob, isReady } =
    getJobInfo(job);

  if (machineStatus) {
    if (isPrintPrintJob) {
      const isMachineAvailable = [
        machineStatuses.ready,
        machineStatuses.manual,
      ].some((status) => {
        return status === machineStatus;
      });

      const canStartPrintPrintJob = isReady && isMachineAvailable;

      const canCompleteLegacyPrintPrintJob =
        isInProgress && machineStatus === machineStatuses.inProgress;

      return !(canStartPrintPrintJob || canCompleteLegacyPrintPrintJob);
    }

    if (isPrintHarvestJob) {
      return !(isReady && machineStatus === machineStatuses.inProgress);
    }
  }

  return false;
};
