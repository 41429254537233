import { isEqual } from 'lodash';

export const isStringEmpty = (string) => {
  return !string || string === '';
};

export const getQueryParams = (search) => {
  return new URLSearchParams(search);
};

/**
 * Returns an object of all queries found in `window.location.search`. Query values are decoded.
 * @deprecated
 * Consider using `useSyncPropertyWithQueryString` instead.
 */
export const getActiveQueries = <
  Queries = Record<string, string | number | boolean>,
>(options?: {
  /**
   * If any queries match the keys in this array, their plain text value will be returned.
   *
   * Defaults to `['version']`.
   */
  doNotTransform: (keyof Queries)[];
}): Queries => {
  const { doNotTransform = ['version'] } = options || {};
  const { href } = window.location;
  const allQueriesString = href.split('?')[1] || '';
  const stringQueries = allQueriesString.split('&').filter((query) => {
    return query;
  });
  const queryObj = stringQueries.reduce<Queries>((accumulator, query) => {
    const [key, stringValue] = query.split('=');

    const useString = doNotTransform.some((q) => {
      return q === key;
    });

    const value = useString ? stringValue : convertValueFromString(stringValue);

    return {
      ...accumulator,
      [key]: value,
    };
  }, {} as Queries);

  return queryObj;
};

export const convertValueFromString = <Type = string | number | boolean>(
  value: string,
): Type => {
  if (!value) {
    return undefined;
  }

  const isNumber = !Number.isNaN(Number(value));

  if (isNumber) {
    return Number(value) as Type;
  }

  const isBoolean = /^true|false$/i.test(value);

  if (isBoolean) {
    return /^true$/i.test(value) as Type;
  }

  return decodeURIComponent(value) as Type;
};

/** `{ foo : 'bar', hello: 'world' }` returns string in format `'?foo=bar&hello=world'` */
export const convertQueriesToString = <Queries extends Record<string, any>>(
  queries: Queries,
): `?${string}` | '' => {
  const keys = Object.keys(queries);
  const queriesArray = keys.map((key) => {
    const value = queries[key];
    let decodedValue = value;

    try {
      // protects against non-string falsy values
      if (typeof value === 'string') {
        // attempt to decode in case of encoded queries being passed back down
        decodedValue = decodeURIComponent(value);
      }
    } catch (e) {
      // catch errors from trying to decode an already decoded string
      decodedValue = value;
    }

    return {
      key,
      value: decodedValue,
    };
  });

  const validQueries = queriesArray.filter(({ value }) => {
    return isEqual(value, []) ? false : !!value || value === false;
  });

  const queryStrings = validQueries.map(({ key, value }) => {
    return `${key}=${encodeURIComponent(value)}`;
  });
  const queryString = queryStrings.join('&');

  return queryString ? `?${queryString}` : '';
};
