import { useFormikContext } from 'formik';
import { Button, Conditional } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { jobsPageAtoms } from '../../../../../../../../components/dropdowns/jobs-filter/job-filter.atoms';
import { jobsDetailsPanelIdQueryKey } from '../../../../../../../../components/jobs-details-panel-wrapper';
import routePaths from '../../../../../../../../config/route-paths';
import {
  jobStatuses,
  jobStepsIncludingNa,
} from '../../../../../../../../constants/options';
import { convertQueriesToString } from '../../../../../../../../helpers/checks';
import { useRouter } from '../../../../../../../../hooks';
import { useResetRecoilAtoms } from '../../../../../../../../hooks/use-reset-recoil-atoms';
import { addJobModalAtoms } from '../../../add-job-modal.atoms';
import { AddJobFormValues } from '../../../add-job-modal.types';
import { JobAddedFooterProps } from './job-added-footer.types';

export const JobAddedFooter = (props: JobAddedFooterProps) => {
  const { handleCloseModal } = props;

  const { navigate } = useRouter();

  const { values } = useFormikContext<AddJobFormValues>();
  const { assignedTo, step, type } = values;

  const [newJobId] = useRecoilValue(addJobModalAtoms.newJobIds);

  const resetJobsFiltersAtoms = useResetRecoilAtoms(jobsPageAtoms.filters);

  const setJobSteps = useSetRecoilState(jobsPageAtoms.filters.steps);
  const setJobTypes = useSetRecoilState(jobsPageAtoms.filters.types);
  const setAssignedTo = useSetRecoilState(jobsPageAtoms.filters.assignedTo);
  const setStatuses = useSetRecoilState(jobsPageAtoms.filters.statuses);
  const setSortBy = useSetRecoilState(jobsPageAtoms.sortBy);

  const handleSetJobsFilters = () => {
    resetJobsFiltersAtoms();

    if (step !== jobStepsIncludingNa.na) {
      setJobSteps([step]);
    }

    setJobTypes([type]);
    setAssignedTo(assignedTo);
    setStatuses([jobStatuses.ready]);
    // @ts-expect-error jobs sort doesn't follow the same sort value standards. This really needs to be updated so it's consistent with the rest of our paginated endpoints.
    setSortBy('Create Date DESC');
  };

  return (
    <>
      <Button
        size="large"
        text="Done"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Conditional
        condition={assignedTo.length > 1}
        Fallback={
          <Button
            size="large"
            text="View job details"
            onClick={async () => {
              await handleCloseModal();

              const queries = convertQueriesToString({
                [jobsDetailsPanelIdQueryKey]: newJobId,
              });

              navigate(`${routePaths.jobs}${queries}`);
            }}
          />
        }
      >
        <Button
          size="large"
          text="View jobs"
          onClick={async () => {
            handleSetJobsFilters();

            await handleCloseModal();

            navigate(routePaths.jobs);
          }}
        />
      </Conditional>
    </>
  );
};
