import { useMemo } from 'react';
import { Typography } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import routePaths from '../../../../config/route-paths';
import { PaginatedStatement } from '../../../../api/statements/routes';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';
import { StatementStatusBadge } from '../../components/statement-status-badge';
import { formatStatementCurrency } from '../../helpers/format-statement-currency';

export const useStatementsColumns = () => {
  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        cell: ({
          getValue,
        }: CellContext<PaginatedStatement, PaginatedStatement['id']>) => {
          const id = getValue();

          return (
            <StyledAnchor
              href={`${routePaths.statements}/${id}`}
              text={`#${id}`}
            />
          );
        },
        header: 'Statement #',
        maxSize: 100,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'category',
        header: 'Category',
      },
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        accessorKey: 'period',
        header: 'Period',
      },
      {
        accessorKey: 'designer',
        cell: ({
          getValue,
        }: CellContext<PaginatedStatement, PaginatedStatement['designer']>) => {
          const designer = getValue();

          return (
            <StyledAnchor
              text={designer.name}
              to={`${routePaths.designers}/${designer.userId}`}
            />
          );
        },
        header: 'Designer',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'amount',
        cell: ({
          getValue,
        }: CellContext<PaginatedStatement, PaginatedStatement['amount']>) => {
          const amount = getValue();

          return <Typography text={formatStatementCurrency(amount)} />;
        },
        header: 'Amount',
        maxSize: 60,
      },
      {
        accessorKey: 'status',
        cell: ({
          getValue,
        }: CellContext<PaginatedStatement, PaginatedStatement['status']>) => {
          const status = getValue();

          return <StatementStatusBadge status={status} />;
        },
        header: 'Status',
        maxSize: 60,
      },
    ];
  }, []);

  return columns;
};
