import styled from 'styled-components';
import { Cell, media } from 'gantri-components';

export const StyledJobDetailsCell = styled(Cell)`
  display: grid;
  grid-template-columns: 10.5rem 1fr;
  align-items: baseline;

  ${media.lessThan('md')`
    grid-template-columns: 1fr;
    row-gap: 5px;
  `}

  ${media.lessThan('sm')`
    grid-template-columns: 10rem 1fr;
  `}
`;

export const StyledPartImageWrapper = styled.div`
  margin-top: 40px;
`;

export const StyledStockWithPriority = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 5px;
  align-items: center;
`;
