import React, { FC, useRef } from 'react';
import { Form, Formik } from 'formik';
import {
  Button,
  Cell,
  Checkbox,
  CheckboxList,
  Dropdown,
  FormikInput,
  Grid,
  Modal,
  Typography,
} from 'gantri-components';
import { FormikProps } from 'formik/dist/types';
import {
  CreateGiftCodeFormType,
  NewGiftCodeProps,
} from './new-gift-code.types';
import { ALL_USER_TYPES } from '../../product/components/info/info.constants';
import { giftCodeTypesMap } from '../../../constants/options';
import { NewGiftCodeSchema } from './new-gift-code.schema';
import { getTypesFormValues, isAllTypeSelected } from './new-gift-code.adapter';
import { discountTypes } from '../../../api/transactions/transactions.constants';

export const NewGiftCode: FC<NewGiftCodeProps> = (props) => {
  const { onClose, onSave } = props;
  const formRef = useRef<FormikProps<any>>();

  const onToggleUserType = (value: boolean) => {
    formRef.current.setValues({
      ...formRef.current.values,
      types: getTypesFormValues(value),
    });
  };

  const onResetAmount = () => {
    formRef.current.setValues({
      ...formRef.current.values,
      amount: 0,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        accountLimit: 0,
        amount: 0,
        code: '',
        details: '',
        totalLimit: 0,
        type: null,
        types: getTypesFormValues(true),
      }}
      innerRef={formRef}
      validateOnChange
      validateOnMount
      validationSchema={NewGiftCodeSchema}
      onSubmit={onSave}
    >
      {({
        isValid,
        setFieldValue,
        values,
      }: FormikProps<CreateGiftCodeFormType>) => {
        const allTypesActive = isAllTypeSelected(values.types);
        const selectedUserTypes = Object.keys(values.types).filter((key) => {
          return values.types[key];
        });

        return (
          <Form>
            <Modal
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!isValid}
                    size="large"
                    text="Save"
                    type="submit"
                  />
                </>
              }
              header={
                <Typography
                  align="center"
                  text="New gift code"
                  textStyle="bold"
                  variant="h4"
                />
              }
              width={{ lg: '45rem', md: '100%' }}
              onClose={onClose}
            >
              <Grid columnGap="1rem" columns="repeat(2, 1fr)" rowGap="2rem">
                <Cell width={2}>
                  <FormikInput ariaLabel="code" labelText="Code" name="code" />
                </Cell>

                <Cell width={2}>
                  <FormikInput
                    ariaLabel="details"
                    labelText="Details"
                    name="details"
                  />
                </Cell>

                <Cell width={2}>
                  <Checkbox
                    checked={allTypesActive}
                    labelText="All user types"
                    onSelected={onToggleUserType}
                  />
                  <CheckboxList
                    gridProps={{
                      columns: 2,
                      marginTop: 'x',
                      width: { lg: '50%', md: '100%' },
                    }}
                    items={[...ALL_USER_TYPES].sort()}
                    values={selectedUserTypes}
                    onValueChange={(selectedUserTypes) => {
                      const updatedTypes = Object.keys(values.types).reduce<
                        typeof values.types
                      >((accumulator, userType) => {
                        const isChecked = selectedUserTypes.some((value) => {
                          return value === userType;
                        });

                        return { ...accumulator, [userType]: isChecked };
                      }, values.types);

                      setFieldValue('types', updatedTypes);
                    }}
                  />
                </Cell>

                <FormikInput
                  Field={
                    <Dropdown
                      idProperty="value"
                      items={giftCodeTypesMap}
                      labelProperty="name"
                      labelText="Gift type"
                      placeholder="Select Type"
                      onSelect={onResetAmount}
                    />
                  }
                  name="type"
                />

                <FormikInput
                  ariaLabel="amount"
                  disabled={values.type === discountTypes.gift}
                  fieldVariant="standard"
                  labelText={`Amount ${
                    values.type === discountTypes.discountPercent ? '%' : '$'
                  }`}
                  name="amount"
                  type="number"
                />

                <FormikInput
                  ariaLabel="accountLimit"
                  labelText="Account limit"
                  name="accountLimit"
                  type="number"
                />

                <FormikInput
                  ariaLabel="totalLimit"
                  labelText="Total limit"
                  name="totalLimit"
                  type="number"
                />
              </Grid>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
