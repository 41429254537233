import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { createNonProductJob } from './create-non-product-job';
import {
  CreateNonProductJobArgs,
  CreateNonProductJobResponse,
} from './create-non-product-job.types';

export const useCreateNonProductJob = <
  TransformedData = CreateNonProductJobResponse,
>(
  props?: GenericMutateQueryProps<
    CreateNonProductJobArgs,
    CreateNonProductJobResponse,
    TransformedData
  >,
) => {
  const { onMutate: onCreateNonProductJob, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to create non-product job.',
    mutationFn: createNonProductJob,
    ...props,
  });

  return { ...rest, onCreateNonProductJob };
};
