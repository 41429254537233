import { useMemo } from 'react';
import { StockStatus, stockStatuses } from '../../../../../constants/options';
import { shipmentHasNotShipped } from '../../helpers/shipment-has-not-shipped';
import { UseIsReasonStockDisabledPropsDef } from './use-is-reason-stock-disabled.types';

export const useIsReasonStockDisabled = (
  props: UseIsReasonStockDisabledPropsDef,
) => {
  const { getIsDisabled, shipments, stock } = props;

  const disabled = useMemo<boolean>(() => {
    const disabledStockStatuses = [
      stockStatuses.cancelled,
    ] satisfies StockStatus[];

    const isDisabledStockStatus = disabledStockStatuses.some((status) => {
      return status === stock.status;
    });

    if (isDisabledStockStatus) {
      return true;
    }

    if (getIsDisabled) {
      const shipmentForStock = shipments.find((shipment) => {
        return shipment.id === stock.shipmentId;
      });

      const hasShipped =
        shipmentForStock && !shipmentHasNotShipped(shipmentForStock);

      return getIsDisabled({ hasShipped, shipment: shipmentForStock, stock });
    }

    return false;
  }, [getIsDisabled, stock, shipments]);

  return disabled;
};
