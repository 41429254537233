import omit from 'lodash/omit';
import { productCategories } from '../../../api/products/products.constants';

export const productsFiltersStatusLabels = [
  'Active',
  'In preparation',
  'Off Market',
  'Ready',
] as const;

export const productsFilterCategoriesOptions = Object.values(
  omit(productCategories, 'giftCard'),
);
