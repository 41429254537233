import { Conditional, Icon, Typography } from 'gantri-components';
import { SummaryValuePropsDef } from './summary-value.types';

export const SummaryValue = (props: SummaryValuePropsDef) => {
  const { processing, ...typographyProps } = props;

  return (
    <Conditional
      condition={processing}
      Fallback={<Typography align="right" {...typographyProps} />}
    >
      <Icon color="link" name="animated:loader" />
    </Conditional>
  );
};
