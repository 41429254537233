import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { ChangeSpoolRequest } from './change-spool.types';

export const changeMachineSpool = (args: ChangeSpoolRequest) => {
  return axios
    .post(`${getCoreApiUrl('machines')}/change-spool`, args)
    .then((response) => {
      return response.data;
    });
};
