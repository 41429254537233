import { formatAsCurrency } from '../../../../helpers/formatter';

export const formatStatementCurrency = (value: number | string) => {
  const isNaN = Number.isNaN(value);

  if (isNaN) {
    throw Error(`"${value}" is not a number.`);
  }

  const num = Number(value);

  return num < 0
    ? `(${formatAsCurrency(Math.abs(num), { isCents: true })})`
    : formatAsCurrency(num, { isCents: true });
};
