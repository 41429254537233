import { Conditional, SearchField, Stack, Typography } from 'gantri-components';
import { useAsync } from 'react-use';
import { useRecoilState } from 'recoil';
import { machinesApi } from '../../../../../api';
import { idleMachinesAtoms } from '../../../../../global-atoms/idle-machines';
import { useNotification } from '../../../../../hooks/useNotification';
import { assignMachineModalText } from '../../assign-machine-modal.constants';
import { AssignMachineModalContentProps } from './assign-machine-modal-content.types';

export const AssignMachineModalContent = (
  props: AssignMachineModalContentProps,
) => {
  const {
    assignedPrinter,
    job,
    machineSelected,
    modalType,
    setAssignedPrinter,
    setMachineSelected,
  } = props;

  const machineType = job?.machineType;
  const material = job?.material;

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const [idleMachinesOfType, setIdleMachinesOfType] = useRecoilState(
    idleMachinesAtoms[machineType],
  );

  useAsync(async () => {
    if (modalType !== 'UNASSIGN' && machineType) {
      await onInterceptProcessingRequest(async () => {
        try {
          const { data } = await machinesApi.fetchIdleMachines({
            material,
            type: machineType,
          });

          const idleMachinesOfType = data.machines;

          setIdleMachinesOfType(idleMachinesOfType);
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to fetch idle machines.',
          });
        }
      });
    }
  }, []);

  return (
    <Stack alignContent="center">
      {machineType ? (
        <>
          <Typography
            align="center"
            color="t1"
            text={assignMachineModalText[modalType].description}
            variant="p2"
          />

          <Conditional
            condition={modalType !== 'UNASSIGN'}
            Fallback={<Typography align="center" text={job?.machine?.name} />}
          >
            <SearchField
              autoSelectFirst={false}
              disabled={processing}
              idProperty="id"
              items={idleMachinesOfType}
              labelProperty="name"
              placeholder="Search name..."
              value={assignedPrinter || machineSelected}
              onSelect={(item) => {
                setAssignedPrinter?.(item?.id);
                setMachineSelected(item?.id);
              }}
            />
          </Conditional>
        </>
      ) : (
        <Typography
          align="center"
          color="alert"
          text="You must first assign a machine type."
        />
      )}
    </Stack>
  );
};
