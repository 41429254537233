import { ReasonItemDef } from '../../components/order-reason/reason-stock';

export const replacementReasonItems: ReasonItemDef[] = [
  {
    requireSpecifics: false,
    value: 'Breakage',
  },
  {
    requireSpecifics: true,
    value: 'Quality issue',
  },
  {
    requireSpecifics: true,
    value: 'Other',
  },
].map((item) => {
  return { ...item, label: item.value };
});
