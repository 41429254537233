import { Box, Conditional, Typography } from 'gantri-components';
import { AllDesignersItem } from '../../../../../../api/designers/routes';

export const DesignerFilterRenderItem = (props: AllDesignersItem) => {
  const { active, name } = props || {};

  return (
    <Conditional condition={!!name}>
      <Box horizontalPadding="x" verticalPadding=".5x">
        <Typography display="inline" text={name} />{' '}
        <Conditional condition={!active}>
          <Typography color="alert" display="inline" text="(Inactive)" />
        </Conditional>
      </Box>
    </Conditional>
  );
};
