import {
  Conditional,
  GetRowProps,
  Icon,
  OnRowClick,
  Stack,
} from 'gantri-components';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  useFetchMachinesOverview,
  useInvalidateFetchMachinesOverviewCache,
} from '../../api/machines/routes';
import {
  FetchMachinesOverviewArgs,
  MachinesOverviewItem,
} from '../../api/machines/routes/fetch-machines-overview/fetch-machines-overview.types';
import { useTableFilters } from '../../components/common/table/hooks';
import { PageHeading } from '../../components/layout/page-heading';
import { MetaData } from '../../components/meta-data/metaData';
import { SimpleErrorBoundary } from '../../components/simple-error-boundary';
import routePaths from '../../config/route-paths';
import { convertQueriesToString } from '../../helpers/checks';
import { useRealTimeWebHook } from '../../hooks/use-real-time-web-hook';
import { realTimeChannels } from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.constants';
import { RealTimeMachineChangedData } from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.types';
import { dataAttrMachineId } from '../machines/machine.constants';
import { MachineRecord } from '../machines/machines.types';
import { useMachinesOverviewColumns } from './hooks/use-machines-overview-columns';
import {
  machinesOverviewPageAtoms,
  VisibleMachineStatus,
} from './machines-overview.atoms';
import { ConfigureMachinesOverviewButton } from './components/configure-machines-overview-button';
import { StyledGrid, StyledTable } from './machines-overview.styles';
import { MachinesOverviewDetailsPanel } from './components/machines-overview-details-panel';
import { machineDetailsPanelIdQueryKey } from './components/machines-overview-details-panel/machines-overview-details-panel.constants';
import { useInvalidateFetchMachineDetailsPanelCache } from '../../api/machines/routes/fetch-machine-detail-panel';
import { MachineStatus } from '../../constants/machines';

export const MachinesOverview = () => {
  const [filters, setFilters] = useState<
    FetchMachinesOverviewArgs & {
      visibleStatuses: VisibleMachineStatus[];
    }
  >();
  const [selectedMachineLocations, setSelectedMachineLocations] = useState<
    string[]
  >([]);

  const visibleStatuses = useRecoilValue(
    machinesOverviewPageAtoms.filters.visibleStatuses,
  );

  const navigate = useNavigate();

  // ? Separated into state so re-fetches do not show loading indicator
  const [showLoading, setShowLoading] = useState<boolean>(true);

  const { data, isLoading } = useFetchMachinesOverview({
    cacheTime: 0,
    enabled: !!filters,
    fetchArgs: { locations: filters?.locations },
    onSuccess: async ({ data }) => {
      const allMachines = Object.values(data).reduce(
        (accumulator, machines) => {
          return [...accumulator, ...machines];
        },
        [],
      );

      setRecords(allMachines);
      setSelectedMachineLocations(Object.keys(data));
      setShowLoading(false);
    },
    showLoading,
  });

  const { filtersProps, records, setRecords } = useTableFilters<
    MachinesOverviewItem,
    FetchMachinesOverviewArgs & { visibleStatuses: VisibleMachineStatus[] }
  >({
    fetchRequest: setFilters,
    modalFiltersDetails: [
      {
        atom: machinesOverviewPageAtoms.filters.locations,
        defaultValue: machinesOverviewPageAtoms.defaults.filters.locations,
        payloadKey: 'locations',
      },
      {
        atom: machinesOverviewPageAtoms.filters.visibleStatuses,
        defaultValue:
          machinesOverviewPageAtoms.defaults.filters.visibleStatuses,
        payloadKey: 'visibleStatuses',
      },
    ],
    pageName: 'machines-overview',
  });

  const { invalidateFetchMachinesOverviewCache } =
    useInvalidateFetchMachinesOverviewCache();

  const { invalidateFetchMachineDetailsPanelCache } =
    useInvalidateFetchMachineDetailsPanelCache();

  const onRealTimeUpdate = async () => {
    await invalidateFetchMachinesOverviewCache();
    await invalidateFetchMachineDetailsPanelCache();
  };

  useRealTimeWebHook<MachinesOverviewItem, RealTimeMachineChangedData>(
    {
      activeStatusFilters: visibleStatuses as MachineStatus[],
      channel: realTimeChannels.machines,
      dataAttrForRow: dataAttrMachineId,
      handleFetchRequest: onRealTimeUpdate,
      records,
      setRecords: onRealTimeUpdate,
    },
    [],
  );

  const getMachinesOverviewColumns = useMachinesOverviewColumns(
    filters?.visibleStatuses,
  );

  const onRowClick: OnRowClick<MachineRecord> = ({ original }) => {
    navigate(
      `${routePaths.machinesOverview}${convertQueriesToString({
        [machineDetailsPanelIdQueryKey]: original.id,
      })}`,
    );
  };

  const getRowProps: GetRowProps<MachineRecord> = (row) => {
    const { id } = row.original;

    return {
      [dataAttrMachineId]: id,
    };
  };

  const pageTitle = 'Machines Overview';

  return (
    <>
      <MetaData title={pageTitle} />
      <MachinesOverviewDetailsPanel>
        <Stack
          gap="x"
          height="100%"
          overflow="hidden"
          paddingBottom="4x"
          rows="max-content 1fr"
        >
          <PageHeading title={pageTitle} />

          <Stack
            gap="2x"
            height="100%"
            overflow="hidden"
            rows="max-content 1fr"
          >
            <ConfigureMachinesOverviewButton filtersProps={filtersProps} />

            <StyledGrid
              alignItems="start"
              columns={`repeat(${selectedMachineLocations.length}, 27.2rem)`}
              height="100%"
              padding="x"
            >
              {selectedMachineLocations?.map((location) => {
                const matchingMachines = data?.data?.[location] || [];

                return (
                  <Fragment key={location}>
                    <SimpleErrorBoundary>
                      <Conditional
                        condition={isLoading && !matchingMachines.length}
                        Fallback={
                          <StyledTable
                            columns={getMachinesOverviewColumns(location)}
                            data={matchingMachines}
                            getRowProps={getRowProps}
                            highlightHoveredRow
                            stickyFirstColumn={false}
                            onRowClick={onRowClick}
                          />
                        }
                      >
                        <Icon color="link" name="animated:loader" />
                      </Conditional>
                    </SimpleErrorBoundary>
                  </Fragment>
                );
              })}
            </StyledGrid>
          </Stack>
        </Stack>
      </MachinesOverviewDetailsPanel>
    </>
  );
};
