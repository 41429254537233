import React, { FC, useMemo } from 'react';
import {
  TextArea,
  Typography,
  FormikInput,
  Dropdown,
  Grid,
  Cell,
  Stack,
  Conditional,
  Box,
} from 'gantri-components';
import { useFormikContext } from 'formik';
import { StyledAvatar, StyledAvatarWrapper } from '../statement.styles';
import { paymentMethods, payoutStatuses } from '../../../constants/payouts';
import {
  StatementFormPropsDef,
  StatementFormSchemaDef,
} from './statement-form.types';
import { formatSummaryPeriod } from './helpers/format-summary-period';
import { StatementStatusBadge } from '../../statements/components/statement-status-badge';
import { formatStatementCurrency } from '../../statements/helpers/format-statement-currency';
import { Label } from '../../../components/label';
import { statementStatuses } from '../../../api/statements/routes';
import { formatDesignerLocation } from './helpers/format-designer-location';
import { getLogoUrl } from './helpers/get-logo-url';

export const StatementForm: FC<StatementFormPropsDef> = (props) => {
  const { isEditing, statement } = props;

  const { values } = useFormikContext<StatementFormSchemaDef>();

  const designerLocation = useMemo(() => {
    return formatDesignerLocation(statement);
  }, [statement]);

  const logoSrc = useMemo(() => {
    return getLogoUrl(statement);
  }, [statement]);

  const summaryPeriod = useMemo(() => {
    return formatSummaryPeriod(statement);
  }, [statement]);

  const formGridColumns = '12rem 1fr';

  const isPaid = values.status === statementStatuses.paid;

  return (
    <Stack gap="4x">
      <Grid alignItems="center" columns="8rem 1fr" gap="2x">
        <StyledAvatarWrapper>
          <StyledAvatar img={logoSrc} />
        </StyledAvatarWrapper>
        <Cell>
          <Typography text={statement.designer.name} variant="h4" />
          <Typography color="t2" text={designerLocation} />
        </Cell>
      </Grid>

      <Typography
        text={formatStatementCurrency(statement.total)}
        variant="h2"
      />

      <Grid alignItems="baseline" columns={formGridColumns} gap="1.2rem">
        <Label text="Period" />
        <Typography text={summaryPeriod} />

        <Label text="Category" />
        <Typography text={statement.category} />

        <Label text="Type" />
        <Typography text={statement.type} />

        <Label text="Status" />
        <Conditional
          condition={isEditing}
          Fallback={
            <Box>
              <StatementStatusBadge status={values.status} />
            </Box>
          }
        >
          <FormikInput
            Field={<Dropdown items={payoutStatuses} placeholder="Status" />}
            name="status"
            required
          />
        </Conditional>
      </Grid>

      <Grid alignItems="baseline" columns={formGridColumns} gap="1.2rem">
        <Cell width={2}>
          <Typography text="Payment" variant="h4" />
        </Cell>

        <Label text="Method" />
        <Conditional
          condition={isEditing}
          Fallback={<Typography text={statement.method || '-'} />}
        >
          <FormikInput
            Field={
              <Dropdown items={paymentMethods} placeholder="Select method" />
            }
            fieldVariant="standard"
            name="method"
            required={isPaid}
          />
        </Conditional>

        <Label text="Name" />
        <Conditional
          condition={isEditing}
          Fallback={<Typography text={statement.name || '-'} />}
        >
          <FormikInput name="name" placeholder="Name" />
        </Conditional>

        <Label text="Email" />

        <Conditional
          condition={isEditing}
          Fallback={<Typography text={statement.email || '-'} />}
        >
          <FormikInput name="email" placeholder="Email" type="email" />
        </Conditional>

        <Label text="Date" />
        <Typography color="t1" text={statement.paidDate || '-'} />
      </Grid>

      <Stack gap="x">
        <Typography text="Notes" variant="h4" />
        <Conditional
          condition={isEditing}
          Fallback={<Typography text={statement.notes || '-'} />}
        >
          <FormikInput
            ariaLabel="notes"
            debounce={500}
            Field={<TextArea minRows={5} placeholder="Input notes" />}
            name="notes"
          />
        </Conditional>
      </Stack>
    </Stack>
  );
};
