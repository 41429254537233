import { Cell, Grid, Typography } from 'gantri-components';
import { EditButtons } from '../edit-buttons';
import { InventoryPurchaseStatusBadge } from '../status-badge';
import { SectionHeadingProps } from './section-heading.types';

export const SectionHeading = (props: SectionHeadingProps) => {
  const {
    disabledTooltip,
    handleSave,
    isEditing,
    isEditingDisabled,
    setIsEditing,
    status,
    toggleEditMode,
    ...rest
  } = props;

  const Heading = () => {
    return <Typography textStyle="bold" variant="h4" {...rest} />;
  };

  const showStatusBadge = !!status;

  if (showStatusBadge) {
    return (
      <Grid alignItems="center" columns="max-content 1fr" gap="1.3rem">
        <Heading />
        <Cell justifyItems="flex-start">
          <InventoryPurchaseStatusBadge status={status} />
        </Cell>
      </Grid>
    );
  }

  const showEditButtons = !!toggleEditMode;

  if (showEditButtons) {
    return (
      <Grid alignItems="center" columns="1fr max-content">
        <Heading />
        <EditButtons
          disabledTooltip={disabledTooltip}
          handleSave={handleSave}
          isEditing={isEditing}
          isEditingDisabled={isEditingDisabled}
          setIsEditing={setIsEditing}
          toggleEditMode={toggleEditMode}
        />
      </Grid>
    );
  }

  return <Heading />;
};
