import { DateRangeFilter } from '../../common/table/components/table-filters/table-filters.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { PartsSortingField, Status, Stock } from './parts-filter.types';
import { AttentionAlert } from '../../../api/parts/routes/get-parts/get-parts.types';
import { Material } from '../../../constants/options/options.types';

export const partsFiltersDefaults: {
  filters: {
    attentionAlerts: AttentionAlert[];
    attentionOnly: boolean;
    completedDateRange: DateRangeFilter;
    lastActionDateRange: DateRangeFilter;
    late: boolean;
    locationId: number;
    materials?: Material[];
    orderId: number;
    productId: number;
    status: Status;
    stock: Stock;
  };
  sortBy: SortBy<PartsSortingField>;
} = {
  filters: {
    attentionAlerts: [],
    attentionOnly: false,
    completedDateRange: {
      from: null,
      to: null,
    },
    lastActionDateRange: {
      from: null,
      to: null,
    },
    late: false,
    locationId: null,
    materials: [],
    orderId: null,
    productId: null,
    status: null,
    stock: null,
  },
  sortBy: {
    sortingField: 'completedAt',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'PARTS_FILTERS';

export const pageName: AtomPageName = 'parts';

export const partsPageAtoms = {
  defaults: partsFiltersDefaults,
  filters: {
    attentionAlerts: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.attentionAlerts,
      key: `${atomKeyPrefix}-attention-alerts`,
    })(pageName),
    attentionOnly: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.attentionOnly,
      key: `${atomKeyPrefix}-attention-only`,
    })(pageName),
    completedDateRange: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.completedDateRange,
      key: `${atomKeyPrefix}-completed-date-range`,
    })(pageName),
    lastActionDateRange: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.lastActionDateRange,
      key: `${atomKeyPrefix}-last-action-date-range`,
    })(pageName),
    late: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.late,
      key: `${atomKeyPrefix}-late`,
    })(pageName),
    locationId: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.locationId,
      key: `${atomKeyPrefix}-location-id`,
    })(pageName),
    materials: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.materials,
      key: `${atomKeyPrefix}-materials`,
    })(pageName),
    orderId: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.orderId,
      key: `${atomKeyPrefix}-order-id`,
    })(pageName),
    productId: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.productId,
      key: `${atomKeyPrefix}-product-id`,
    })(pageName),
    status: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.status,
      key: `${atomKeyPrefix}-status`,
    })(pageName),
    stock: getGenericAtomFamily({
      defaultValue: partsFiltersDefaults.filters.stock,
      key: `${atomKeyPrefix}-stock`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<PartsSortingField>>({
    defaultValue: partsFiltersDefaults.sortBy,
  })(pageName),
};
