import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdateStatementArgsDef,
  UpdateStatementResponseDef,
} from './update-statement.types';

export const updateStatement = (args: UpdateStatementArgsDef) => {
  const { payout } = args;

  return axios
    .put<UpdateStatementResponseDef>(
      `${getCoreApiUrl('statements')}/${payout.id}`,
      payout,
    )
    .then(({ data }) => {
      return data;
    });
};
