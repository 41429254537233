import { Conditional, Icon } from 'gantri-components';
import { useFetchMachineLocations } from '../../../api/machines/routes/fetch-machine-locations';
import { AtomsCheckboxList } from '../atoms-checkbox-list';
import { MachineLocationsAtomsCheckboxListPropsDef } from './machine-locations-atoms-checkbox-list.types';

export const MachineLocationsAtomsCheckboxList = (
  props: MachineLocationsAtomsCheckboxListPropsDef,
) => {
  const { data: allMachineLocations = [], isLoading } =
    useFetchMachineLocations<string[]>({
      transform: (data) => {
        return (data?.locations || []).map(({ name }) => {
          return name;
        });
      },
    });

  return (
    <Conditional
      condition={isLoading}
      Fallback={
        <AtomsCheckboxList
          gridProps={{ columns: 2 }}
          idProperty="id"
          items={allMachineLocations}
          labelProperty="name"
          {...props}
        />
      }
    >
      <Icon color="link" name="animated:loader" size="2.4rem" />
    </Conditional>
  );
};
