import { useParams } from 'react-router-dom';
import {
  Button,
  Cell,
  Conditional,
  Flex,
  Grid,
  Line,
  Stack,
} from 'gantri-components';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { StatementItems } from './statement-items';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import { StatementForm } from './statement-form';
import {
  useFetchStatement,
  useGetStatementDownloadUrl,
} from '../../api/statements/routes';
import { useUpdateStatement } from '../../api/statements/routes/update-statement';
import { statementUpdateFormSchema } from './statement-form/statement-form.schema';
import { StatementFormSchemaDef } from './statement-form/statement-form.types';
import { useSmallToast } from '../../hooks/use-small-toast';
import { downloadToastText } from '../../hooks/use-download-with-small-toast';
import { useDownloadSingleFile } from '../../hooks/use-download/hooks/use-download-single-file';

export const Statement = () => {
  const { id: statementIdQuery } = useParams<{ id: string }>();

  const statementId = Number(statementIdQuery);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { isLoading: isUpdating, onUpdateStatement } = useUpdateStatement({
    onSuccess: () => {
      setIsEditing(false);
    },
  });

  const onUpdatePayout = async (values: StatementFormSchemaDef) => {
    await onUpdateStatement({
      payout: { ...statement, ...values },
    });
  };

  const { data } = useFetchStatement({
    enabled: !!statementId,
    fetchArgs: {
      id: statementId,
    },
    showLoading: true,
  });

  const { isLoading: isDownloading, onGetStatementDownloadUrl } =
    useGetStatementDownloadUrl({
      onSuccess: async ({ downloadUrl }) => {
        await downloadSingleFile({
          url: downloadUrl,
        });
      },
    });

  const {
    Toast: DownloadStatusToast,
    setToastStatus,
    setToastText,
  } = useSmallToast();

  const { downloadSingleFile } = useDownloadSingleFile({
    onComplete: () => {
      setToastText(downloadToastText.statement.completedText);
      setToastStatus('success');
    },
    onError: () => {
      setToastText(downloadToastText.statement.errorText);
      setToastStatus('error');
    },
    onStart: () => {
      setToastText(downloadToastText.statement.startedText);
    },
  });

  const statement = data?.statement;
  const pageTitle = `Statement #${statementId}`;

  return statement ? (
    <>
      <MetaData title={pageTitle} />

      <Formik
        enableReinitialize
        initialValues={statement}
        validateOnChange
        validateOnMount
        validationSchema={statementUpdateFormSchema}
        onSubmit={onUpdatePayout}
      >
        {({ isValid, resetForm }) => {
          return (
            <Form style={{ width: '100%' }}>
              <Stack paddingBottom="5x">
                <PageHeading subTitle={statement?.category} title={pageTitle}>
                  <Flex gap=".5x">
                    <DownloadStatusToast>
                      <Button
                        disabled={isEditing}
                        processing={isDownloading}
                        text="Download PDF"
                        variant="secondary"
                        onClick={async () => {
                          await onGetStatementDownloadUrl({ id: statementId });
                        }}
                      />
                    </DownloadStatusToast>
                    <Conditional
                      condition={isEditing}
                      Fallback={
                        <Button
                          key="Update"
                          text="Update"
                          onClick={() => {
                            setIsEditing(true);
                          }}
                        />
                      }
                    >
                      <Button
                        key="Save"
                        disabled={!isValid}
                        processing={isUpdating}
                        text="Save"
                        type="submit"
                      />
                      <Button
                        key="Cancel"
                        disabled={isUpdating}
                        text="Cancel"
                        variant="secondary"
                        onClick={() => {
                          resetForm();
                          setIsEditing(false);
                        }}
                      />
                    </Conditional>
                  </Flex>
                </PageHeading>

                <Grid
                  alignItems="start"
                  columns={{ lg: '1fr 40rem', md: 1 }}
                  gap="5x"
                >
                  <Cell column={1} row={{ lg: 1, md: 2 }}>
                    <StatementItems statement={statement} />
                    <Line />
                  </Cell>

                  <Cell column={{ lg: 2, md: 1 }} row={1}>
                    <StatementForm
                      isEditing={isEditing}
                      statement={statement}
                    />
                  </Cell>
                </Grid>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </>
  ) : null;
};
