import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';

export const fetchAllDesigners = () => {
  return axios
    .get(`${getCoreApiUrl('designers')}/designer-names`)
    .then(({ data }) => {
      return data;
    });
};
