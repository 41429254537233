import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchAllDesignersResponseDef,
  FetchAllDesignersArgsDef,
} from './fetch-all-designers.types';
import { fetchAllDesigners } from './fetch-all-designers';

const fetchAllDesignersQueriesObj = getGenericQueryObj({
  groupName: 'fetch-all-designers',
  query: fetchAllDesigners,
});

export const useFetchAllDesigners = <
  TransformedData = FetchAllDesignersResponseDef,
>(
  props?: GenericFetchProps<
    FetchAllDesignersArgsDef,
    FetchAllDesignersResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch all designers.',
    queryObj: fetchAllDesignersQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchAllDesignersCache = () => {
  const invalidateFetchAllDesignersCache = useGetInvalidateQueryCache(
    fetchAllDesignersQueriesObj,
  );

  return { invalidateFetchAllDesignersCache };
};
