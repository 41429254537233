import { Cell, Grid, Typography } from 'gantri-components';
import { Fragment } from 'react';
import { SimpleErrorBoundary } from '../../../../components/simple-error-boundary';
import { MachineStatusBadge } from '../machine-status-badge';
import { BulkUpdateMachinesModalDetailsPanelProps } from './bulk-update-machines-modal-details-panel.types';

export const BulkUpdateMachinesModalDetailsPanel = (
  props: BulkUpdateMachinesModalDetailsPanelProps,
) => {
  const { machines } = props;

  return (
    <SimpleErrorBoundary>
      <Grid
        alignItems="baseline"
        columns={{ lg: '15rem 1fr', sm: '1fr' }}
        gap="2x"
        height="100%"
      >
        <Typography text={`Machines (${machines.length})`} textStyle="bold" />

        <Grid
          columnGap="3x"
          columns="max-content 1fr"
          maxHeight={{ lg: '100%', sm: '15rem' }}
          overflow="auto"
          rowGap=".5x"
        >
          {machines.map(({ id, name, status }) => {
            return (
              <Fragment key={id}>
                <Typography text={name} />
                <Cell justifyContent="start">
                  <MachineStatusBadge status={status} />
                </Cell>
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
    </SimpleErrorBoundary>
  );
};
