import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  UpdateStatementArgsDef,
  UpdateStatementResponseDef,
} from './update-statement.types';
import { updateStatement } from './update-statement';
import { useInvalidateFetchStatementCache } from '../fetch-statement';
import { useInvalidateGetPaginatedStatementsCache } from '../fetch-paginated-statements';

export const useUpdateStatement = <
  TransformedData = UpdateStatementResponseDef,
>({
  onSuccess,
  ...props
}: GenericMutateQueryProps<
  UpdateStatementArgsDef,
  UpdateStatementResponseDef,
  TransformedData
>) => {
  const { invalidateFetchStatementCache } = useInvalidateFetchStatementCache();

  const { invalidateGetPaginatedStatementsCache } =
    useInvalidateGetPaginatedStatementsCache();

  const { onMutate: onUpdateStatement, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update statement.',
    mutationFn: updateStatement,
    onSuccess: async (...args) => {
      await invalidateGetPaginatedStatementsCache();
      await invalidateFetchStatementCache();

      await onSuccess?.(...args);
    },
    ...props,
  });

  return { ...rest, onUpdateStatement };
};
