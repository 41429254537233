import { useMemo, useState } from 'react';
import { Box, Line, Stack } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { startCase } from 'lodash';
import { SelectedStockDetailsDef } from '../../components/order-reason/reason-stock';
import { orderActionTypes } from '../../config/route-paths';
import { PageHeading } from '../../components/layout/page-heading';
import { useRouter } from '../../hooks';
import MetaData from '../../components/meta-data';
import { useFetchOrder } from '../../api/transactions/routes';
import { SummarySection } from './components/summary-section';
import { RefundOrReturnFooter } from './components/refund-or-return-footer';
import { RefundOrReturnItems } from './components/refund-or-return-items';
import { useIsShippingRefundedAutomatically } from './components/summary-section/hooks/use-handle-is-shipping-refunded-automatically';

export const OrderRefundOrReturn = () => {
  const [selectedStocksDetails, setSelectedStocksDetails] = useState<
    SelectedStockDetailsDef[]
  >([]);
  const [manuallyRefundShipping, setManuallyRefundShipping] = useState(false);

  const { location } = useRouter();

  const isReturn = useMemo<boolean>(() => {
    const returnPattern = new RegExp(`/${orderActionTypes.return}$`, 'i');

    return returnPattern.test(location.pathname);
  }, [location.pathname]);

  const { id: orderIdParam } = useParams<{ id: string }>();

  const orderId = Number(orderIdParam);

  const { data } = useFetchOrder({
    enabled: !!orderId,
    fetchArgs: {
      id: orderId,
    },
    showLoading: true,
  });

  const order = data?.order;

  const isShippingRefundedAutomatically = useIsShippingRefundedAutomatically({
    isReturn,
    order,
    selectedStocksDetails,
  });

  const refundShipping = order?.hasRefundShipping
    ? false
    : manuallyRefundShipping || isShippingRefundedAutomatically;

  const contentWidth = { lg: '70rem', sm: '100%' };

  const actionType = isReturn
    ? orderActionTypes.return
    : orderActionTypes.refund;

  const pageTitle = `Create ${startCase(actionType)}`;

  return (
    <>
      <MetaData title={pageTitle} />

      <PageHeading
        subTitle={`Select items below to create a ${actionType}`}
        title={pageTitle}
      />

      {order && (
        <Stack gap="5x" height="unset" verticalPadding="5x">
          <RefundOrReturnItems
            isReturn={isReturn}
            isShippingRefundedAutomatically={isShippingRefundedAutomatically}
            maxWidth={contentWidth}
            order={order}
            refundShipping={refundShipping}
            setManuallyRefundShipping={setManuallyRefundShipping}
            setSelectedStocksDetails={setSelectedStocksDetails}
          />

          <Line />

          <Box maxWidth={contentWidth}>
            <SummarySection
              isReturn={isReturn}
              order={order}
              refundShipping={refundShipping}
              selectedStocksDetails={selectedStocksDetails}
            />
          </Box>

          <RefundOrReturnFooter
            isReturn={isReturn}
            order={order}
            refundShipping={refundShipping}
            selectedStocksDetails={selectedStocksDetails}
          />
        </Stack>
      )}
    </>
  );
};
