import { useMemo } from 'react';
import { shipmentHasNotShipped } from '../../../../../../components/order-reason/reason-stock';
import { stockStatuses } from '../../../../../../constants/options';
import { Shipment } from '../../../../../../types/store';
import { UseIsShippingRefundedAutomaticallyPropsDef } from './use-handle-is-shipping-refunded-automatically.types';

export const useIsShippingRefundedAutomatically = (
  props: UseIsShippingRefundedAutomaticallyPropsDef,
) => {
  const { isReturn, order, selectedStocksDetails } = props;

  const { hasRefundShipping, shipments = [] } = order || {};

  const getIsEveryShipmentUnshipped = () => {
    return !!shipments.length && shipments.every(shipmentHasNotShipped);
  };

  const getIsEveryStockRefundedReturnedOrSelected = () => {
    const refundedOrReturnedStockStatuses = [
      stockStatuses.refunded,
      stockStatuses.returnInProgress,
    ] as const;

    const shipmentIsRefundedReturnedOrSelected = (shipment: Shipment) => {
      return shipment.stocks.every((stock) => {
        const isStockRefundedOrReturned = refundedOrReturnedStockStatuses.some(
          (status) => {
            return status === stock.status;
          },
        );

        if (isStockRefundedOrReturned) {
          return true;
        }

        const isSelectedStock = selectedStocksDetails.some((details) => {
          return details.stock.id === stock.id;
        });

        return isSelectedStock;
      });
    };

    return (
      !!shipments.length &&
      shipments.every(shipmentIsRefundedReturnedOrSelected)
    );
  };

  const isShippingRefundedAutomatically = useMemo(() => {
    return hasRefundShipping || isReturn
      ? false
      : getIsEveryShipmentUnshipped() &&
          getIsEveryStockRefundedReturnedOrSelected();
  }, [shipments, selectedStocksDetails, hasRefundShipping]);

  return isShippingRefundedAutomatically;
};
