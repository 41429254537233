import {
  Checkbox,
  Conditional,
  Flex,
  Stack,
  Typography,
  TypographyProps,
} from 'gantri-components';
import { useCallback } from 'react';
import {
  GetIsReasonStockDisabledDef,
  ReasonStock,
} from '../../../../components/order-reason/reason-stock';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { StockStatus, stockStatuses } from '../../../../constants/options';
import { returnAndRefundReasonItems } from '../../order-refund-or-return.constants';
import { RefundOrReturnItemsPropsDef } from './refund-or-return-items.types';

export const RefundOrReturnItems = (props: RefundOrReturnItemsPropsDef) => {
  const {
    isReturn,
    isShippingRefundedAutomatically,
    maxWidth,
    order,
    refundShipping,
    setManuallyRefundShipping,
    setSelectedStocksDetails,
  } = props;

  const getIsReasonStockDisabled: GetIsReasonStockDisabledDef = useCallback(
    ({ hasShipped, stock }) => {
      if (isReturn) {
        const returnEnabledStockStatuses = [
          stockStatuses.completed,
          stockStatuses.delivered,
          stockStatuses.inStock,
        ] satisfies StockStatus[];

        const isReturnableStatus = returnEnabledStockStatuses.some((status) => {
          return status === stock.status;
        });

        return !isReturnableStatus;
      }

      const isRefunded = stock.status === stockStatuses.refunded;

      return isRefunded || hasShipped;
    },
    [],
  );

  // ? 12/02/2024 - Temporarily commenting out return validation to allow workers
  // ? to offer refunds via the UI. Return restriction will be re-implemented in
  // ? the future when we offer ability to refund any amount.
  // isReturn &&
  const showManualShippingRefund = order?.amount?.shipping > 0;

  const shippingTextColor: TypographyProps['color'] = order.hasRefundShipping
    ? 't2'
    : 't1';

  return (
    <Stack gap="4x" height="unset" maxWidth={maxWidth}>
      {order.stocks.map((stock) => {
        return (
          <ReasonStock
            key={stock.id}
            getIsDisabled={getIsReasonStockDisabled}
            reasons={returnAndRefundReasonItems}
            setSelectedStocksDetails={setSelectedStocksDetails}
            shipments={order.shipments}
            stock={stock}
          />
        );
      })}

      <Conditional condition={showManualShippingRefund}>
        <Flex justifyContent="space-between">
          <Checkbox
            checked={refundShipping}
            disabled={
              order.hasRefundShipping || isShippingRefundedAutomatically
            }
            labelColor={shippingTextColor}
            labelText="Shipping refund"
            onSelected={setManuallyRefundShipping}
          />
          <Typography
            align="right"
            color={shippingTextColor}
            text={formatAsCurrency(order.amount.shipping, {
              isCents: true,
            })}
          />
        </Flex>
      </Conditional>
    </Stack>
  );
};
