import { Flex, Icon, Tooltip, Typography } from 'gantri-components';
import { FC } from 'react';

export const MachineDetailsMessage: FC<{ isJobPanel?: boolean }> = (props) => {
  const { isJobPanel } = props;

  return (
    <Flex alignItems="flex-start" gap="x" justifyContent="flex-start">
      <Typography
        maxWidth={isJobPanel ? 'min-content' : 'unset'}
        text="Machine Details"
        textStyle="bold"
      />
      <Tooltip
        description="Machine type and material selected for this print in the product template."
        position="bottom-start"
      >
        <Icon name="alert:i_circle_24" top=".3rem" />
      </Tooltip>
    </Flex>
  );
};
