import { useInvalidateGetMachineIssuesCache } from '../get-issues/get-issues.query';
import { useInvalidateFetchPaginatedMachineTimelineCache } from '../fetch-paginated-machine-timelines';
import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  ChangeMachineSpoolResponse,
  ChangeSpoolRequest,
} from './change-spool.types';
import { changeMachineSpool } from './change-spool';
import { useInvalidateFetchPaginatedMachinesCache } from '../fetch-paginated-machines';

export const useChangeMachineSpool = <
  TransformedData = ChangeMachineSpoolResponse,
>(
  props?: GenericMutateQueryProps<
    ChangeSpoolRequest,
    ChangeMachineSpoolResponse,
    TransformedData
  >,
) => {
  const invalidateCache = useInvalidateGetMachineIssuesCache();
  const invalidateTimelineCache =
    useInvalidateFetchPaginatedMachineTimelineCache();
  const { invalidateFetchPaginatedMachinesCache } =
    useInvalidateFetchPaginatedMachinesCache();

  const { onMutate: onChangeSpool, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to change the machine spool.',
    mutationFn: changeMachineSpool,
    ...props,
    onSuccess: async (data, variables) => {
      const [machineId] = variables.machineIds;

      await Promise.all([
        invalidateCache({ machineId }),
        invalidateTimelineCache({ machineId }),
        invalidateFetchPaginatedMachinesCache(),
      ]);

      await props?.onSuccess?.(data, variables);
    },
  });

  return { ...rest, onChangeSpool };
};
