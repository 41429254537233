import {
  Box,
  Checkbox,
  Dropdown,
  Flex,
  Stack,
  Typography,
} from 'gantri-components';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { PartStatusBadge } from '../../../../components/common/part-status-badge';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { ReassignStockItemPropsDef } from './reassign-stock-item.types';
import { useCreateIdleStockItems } from './hooks/use-create-idle-stock-items';
import { useHandleInteractions } from './use-handle-interactions';
import { StyledShortProductSummary } from './reassign-stock-item.styles';

export const ReassignStockItem = (props: ReassignStockItemPropsDef) => {
  const { idleStocks, selectedStocks, setSelectedStocks, stock } = props;

  const { checked, handleSetStock, handleUncheck, newStock } =
    useHandleInteractions({ selectedStocks, setSelectedStocks, stock });

  const idleStocksItems = useCreateIdleStockItems({
    idleStocks,
    newStock,
    selectedStocks,
    stock,
  });

  const noIdleStocks = !idleStocksItems.length;

  return (
    <Flex alignItems="flex-start" gap="x" justifyContent="space-between">
      <Flex gap="x">
        <Checkbox
          checked={checked}
          disabled={!checked}
          onSelected={handleUncheck}
        />

        <StyledShortProductSummary
          alignContent="start"
          disabled={!checked}
          fetchSku={
            // ! NOT IDEAL TO USE THIS PROP. SHOULD REFACTOR AWAY FROM IT WHEN ORDERS GETS OVERHAULED
            stock.product.sku
          }
          imageSize={{ lg: '8rem', sm: '4rem' }}
        >
          <Stack gap="x" paddingTop=".5x">
            <PartStatusBadge status={stock.status} />

            <Box>
              <Typography
                color="t2"
                display="inline"
                text="Re-assign stock from"
              />{' '}
              <StyledAnchor
                display="inline"
                target="_blank"
                text={`#${stock.id}`}
                to={`/stocks/${stock.id}`}
              />{' '}
              <Typography color="t2" display="inline" text="to" />
            </Box>

            <Dropdown
              disabled={noIdleStocks}
              idProperty="id"
              items={idleStocksItems}
              placeholder={noIdleStocks ? 'No idle stocks' : 'Select stock'}
              value={newStock?.id}
              onSelect={handleSetStock}
            />
          </Stack>
        </StyledShortProductSummary>
      </Flex>

      <Typography
        text={formatAsCurrency(stock.product.amount.subtotal, {
          isCents: true,
        })}
      />
    </Flex>
  );
};
