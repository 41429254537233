import { useState } from 'react';
import { Button, Table, Tooltip } from 'gantri-components';
import { PageWithTable } from '../../components/layout';
import { StatementsTransactionsFilters } from './components/statements-transactions-filters';
import {
  FetchPaginatedStatementTransactionsArgsDef,
  PaginatedStatementTransactionDef,
  TransactionsSortingField,
  useGetPaginatedStatementTransactions,
  fetchPaginatedStatementTransactions,
} from '../../api/statements/routes';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import {
  pageName,
  transactionsFiltersDefaults,
  transactionsPageAtoms,
} from './statements-transactions.atoms';
import { useGetDownloadMenuOption } from '../../hooks/use-get-download-menu-option';
import Protected from '../../components/common/protected/protected';
import { userRoles } from '../../constants/users';
import { useStatementsTransactionsColumns } from './hooks/use-statement-transactions-columns';
import { useStatementsTransactionsDownloadColumns } from './hooks/use-statement-transactions-download-columns';

export const StatementsTransactions = () => {
  const [filters, updateFilters] =
    useState<FetchPaginatedStatementTransactionsArgsDef>();

  const columns = useStatementsTransactionsColumns();

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedStatementTransactionDef,
    FetchPaginatedStatementTransactionsArgsDef,
    TransactionsSortingField
  >({
    fetchRequest: updateFilters,
    filtersContent: <StatementsTransactionsFilters />,
    modalFiltersDetails: [
      {
        atom: transactionsPageAtoms.filters.dateRange,
        defaultValue: transactionsFiltersDefaults.filters.dateRange,
        payloadKey: 'dateRange',
      },
      {
        atom: transactionsPageAtoms.filters.designerName,
        defaultValue: transactionsFiltersDefaults.filters.designerName,
        payloadKey: 'designerName',
      },
      {
        atom: transactionsPageAtoms.filters.categories,
        defaultValue: transactionsFiltersDefaults.filters.categories,
        payloadKey: 'categories',
      },
      {
        atom: transactionsPageAtoms.filters.types,
        defaultValue: transactionsFiltersDefaults.filters.types,
        payloadKey: 'types',
      },
    ],
    pageName,
    pageSize: 100,
    sortByFilterDetails: {
      atom: transactionsPageAtoms.sortBy,
      defaultValue: transactionsFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Date', sortingField: 'date' },
      { bidirectional: true, label: 'Quantity', sortingField: 'quantity' },
      { bidirectional: true, label: 'Total', sortingField: 'total' },
    ],
  });

  useGetPaginatedStatementTransactions({
    enabled: !!filters,
    fetchArgs: { ...filters, search: searchProps.value, ...sortProps.current },
    onSuccess: ({ data, pagination }) => {
      setRecords(data);
      setTotalCount(pagination.totalItems);
    },
    showLoading: true,
  });

  const downloadColumns = useStatementsTransactionsDownloadColumns();

  const { Toast: DownloadCsvToast, downloadOption } = useGetDownloadMenuOption({
    columns: downloadColumns,
    fetchRequestWithFilters: (overrides) => {
      return fetchPaginatedStatementTransactions({
        ...filters,
        ...overrides,
      }).then(({ data }) => {
        return data;
      });
    },
    fileNamePrefix: 'Transactions Admin',
    totalResults: pagingProps.total,
  });

  const pageTitle = 'Transactions';

  return (
    <PageWithTable pageTitle={pageTitle}>
      <PageWithTable.Header title={pageTitle}>
        <Protected allowedFor={[userRoles.admin, userRoles.lead]}>
          <DownloadCsvToast>
            <Tooltip
              description={
                downloadOption.enabled
                  ? ''
                  : downloadOption.disabledTooltipProps.description
              }
            >
              <Button
                disabled={!downloadOption.enabled}
                text={downloadOption.name}
                variant="secondary"
                onClick={downloadOption.onOptionClick}
              />
            </Tooltip>
          </DownloadCsvToast>
        </Protected>
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
