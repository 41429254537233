import { useEffect, useState } from 'react';
import { Table } from 'gantri-components';
import { PageWithTable } from '../../components/layout';
import { StatementsFilter } from './components/statements-filter';
import {
  FetchPaginatedStatementsArgs,
  PaginatedStatement,
  StatementsSortingField,
  useGetPaginatedStatements,
} from '../../api/statements/routes';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import {
  pageName,
  payoutsFiltersDefaults,
  payoutsPageAtoms,
} from './statements.atoms';
import { useStatementsColumns } from './hooks/use-statements-columns';

export const Statements = () => {
  const [filters, updateFilters] = useState<FetchPaginatedStatementsArgs>();

  const statementsColumns = useStatementsColumns();

  const { data, isLoading } = useGetPaginatedStatements({
    enabled: !!filters,
    fetchArgs: filters,
  });

  const {
    filtersProps,
    pagingProps,
    records,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedStatement,
    FetchPaginatedStatementsArgs,
    StatementsSortingField
  >({
    fetchRequest: updateFilters,
    filtersContent: <StatementsFilter />,
    modalFiltersDetails: [
      {
        atom: payoutsPageAtoms.filters.dateRange,
        defaultValue: payoutsFiltersDefaults.filters.dateRange,
        payloadKey: 'dateRange',
      },
      {
        atom: payoutsPageAtoms.filters.statuses,
        defaultValue: payoutsFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
      {
        atom: payoutsPageAtoms.filters.designerId,
        defaultValue: payoutsFiltersDefaults.filters.designerId,
        payloadKey: 'designerId',
      },
      {
        atom: payoutsPageAtoms.filters.categories,
        defaultValue: payoutsFiltersDefaults.filters.categories,
        payloadKey: 'categories',
      },
      {
        atom: payoutsPageAtoms.filters.types,
        defaultValue: payoutsFiltersDefaults.filters.types,
        payloadKey: 'types',
      },
    ],
    pageName,
    pageSize: 100,
    sortByFilterDetails: {
      atom: payoutsPageAtoms.sortBy,
      defaultValue: payoutsFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Amount', sortingField: 'amount' },
      { bidirectional: true, label: 'Status', sortingField: 'status' },
    ],
  });

  useEffect(() => {
    if (!isLoading) {
      setRecords(data?.data || []);
      setTotalCount(data?.pagination?.totalItems || 0);
    }
  }, [isLoading, data]);

  const pageTitle = 'Statements';

  return (
    <PageWithTable pageTitle={pageTitle}>
      <PageWithTable.Header title={pageTitle} />
      <PageWithTable.Content>
        <Table
          columns={statementsColumns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
