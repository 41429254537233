import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchMachinesOverviewArgs,
  FetchMachinesOverviewResponse,
} from './fetch-machines-overview.types';
import { fetchMachinesOverview } from './fetch-machines-overview';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';

export const fetchMachinesOverviewQueryObj = getGenericQueryObj({
  groupName: 'fetch-paginated-machines',
  query: fetchMachinesOverview,
});

export const useFetchMachinesOverview = <
  TransformedData = FetchMachinesOverviewResponse,
>(
  props?: GenericFetchProps<
    FetchMachinesOverviewArgs,
    FetchMachinesOverviewResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch machines overview.',
    queryObj: fetchMachinesOverviewQueryObj,
    ...props,
  });
};

export const useInvalidateFetchMachinesOverviewCache = () => {
  const invalidateFetchMachinesOverviewCache = useGetInvalidateQueryCache(
    fetchMachinesOverviewQueryObj,
  );

  return { invalidateFetchMachinesOverviewCache };
};
