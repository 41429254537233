import React, { FC, useEffect } from 'react';
import {
  Box,
  Button,
  Conditional,
  Dropdown,
  Flex,
  FormikInput,
  Grid,
  SearchField,
  TextField,
  Tooltip,
} from 'gantri-components';
import { GeneralSectionProps } from './general-section.types';
import { FormItem } from '../../../inventory/components/form-item';
import { Divider } from '../../../../components/divider';
import { machineStatusOptions } from './general-section.constant';
import { StatusItem } from './status-item';
import { OpenIssues } from '../../components/open-issues';
import { machineTypeOptions } from '../../../machines/machine.constants';
import { useEditStatusState } from './use-edit-status-state';
import { HoursUntilMaintenance } from '../../components/hours-until-maintenance';
import { materials } from '../../../../constants/parts';

export const GeneralSection: FC<GeneralSectionProps> = (props) => {
  const { currentMachine, isEditModeActive, isOverviewTabActive } = props;
  const {
    canActivateEditStatusBtn,
    editTooltipMessage,
    isEditStatusActive,
    setIsEditStatusActive,
    showEditTooltip,
  } = useEditStatusState(props);

  useEffect(() => {
    if (isEditModeActive) {
      setIsEditStatusActive(false);
    }
  }, [isEditModeActive]);

  const materialChoices = Object.values(materials).map((value) => {
    return {
      label: value,
      value,
    };
  });

  return (
    <Grid
      alignItems="flex-start"
      columnGap="3x"
      columns={{
        lg: 'repeat(7, minmax(0, max-content))',
        md: 'repeat(auto-fill,minmax(19rem, max-content))',
      }}
      paddingBottom="8x"
      paddingTop="4x"
      rowGap="3x"
    >
      <Flex alignItems="flex-start" gap="3x" height="100%">
        <FormItem
          isEditMode={
            (isEditModeActive || isEditStatusActive) && canActivateEditStatusBtn
          }
          label="Status"
          viewElement={
            <Flex gap="x">
              <StatusItem status={currentMachine.status} />

              <Conditional condition={!isEditModeActive && isOverviewTabActive}>
                <Tooltip description={showEditTooltip && editTooltipMessage}>
                  <Button
                    disabled={!canActivateEditStatusBtn}
                    size="small"
                    text="Edit"
                    variant="ghost"
                    onClick={setIsEditStatusActive}
                  />
                </Tooltip>
              </Conditional>
            </Flex>
          }
        >
          <Flex alignItems="center" gap="x">
            <FormikInput
              Field={
                <Dropdown
                  dropdownPopupWidth="22rem"
                  items={machineStatusOptions}
                  labelPosition="top"
                  maxHeight={400}
                  name="status"
                  placeholder={String(currentMachine.status)}
                  renderItem={(option) => {
                    return (
                      <StatusItem showDescription status={option?.label} />
                    );
                  }}
                  renderPlaceholder={(option) => {
                    return (
                      <StatusItem
                        status={option?.label}
                        style={{ paddingLeft: '.8rem' }}
                      />
                    );
                  }}
                />
              }
              name="status"
            />

            <Conditional condition={!isEditModeActive && isOverviewTabActive}>
              <Button
                key="machine-update-status"
                size="small"
                text="Update"
                type="submit"
                variant="ghost"
              />
            </Conditional>
          </Flex>
        </FormItem>

        <Divider borderLocation="left" height="100%" />
      </Flex>

      <Flex alignItems="flex-start" gap="3x" height="100%">
        <FormItem
          isEditMode={isEditModeActive}
          label="Machine Type"
          viewElement={currentMachine.type}
        >
          <FormikInput
            Field={
              <Dropdown items={machineTypeOptions} name="type" width="100%" />
            }
            name="type"
          />
        </FormItem>

        <Divider borderLocation="left" height="100%" />
      </Flex>

      <Flex alignItems="flex-start" gap="3x" height="100%">
        <FormItem
          isEditMode={isEditModeActive}
          label="Material"
          viewElement={currentMachine.material || 'N/A'}
        >
          <FormikInput
            Field={
              <SearchField
                items={materialChoices}
                labelPosition="top"
                maxHeight={400}
              />
            }
            name="material"
          />
        </FormItem>
        <Divider borderLocation="left" height="100%" />
      </Flex>

      <Flex alignItems="flex-start" gap="3x" height="100%">
        <FormItem
          isEditMode={isEditModeActive}
          label="Bay"
          viewElement={currentMachine.bay || '-'}
        >
          <Box width="12rem">
            <FormikInput
              Field={<TextField placeholder="Input bay" required />}
              name="bay"
            />
          </Box>
        </FormItem>

        <Divider borderLocation="left" height="100%" />
      </Flex>

      <Flex alignItems="flex-start" gap="3x" height="100%">
        <FormItem
          label="Open Issues"
          viewElement={<OpenIssues issues={currentMachine.openIssues} />}
        />

        <Divider borderLocation="left" height="100%" />
      </Flex>

      <HoursUntilMaintenance currentMachine={currentMachine} />
    </Grid>
  );
};
