import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedTimelinesArgs,
  FetchPaginatedTimelinesResponse,
} from './fetch-paginated-machine-timelines.types';

const query = ({ machineId, page }: FetchPaginatedTimelinesArgs) => {
  return axios
    .get<FetchPaginatedTimelinesResponse>(
      `${getCoreApiUrl(
        'machines',
      )}/${machineId}/timelines?page=${page}&count=10`,
    )
    .then((response) => {
      return response.data;
    });
};

const groupKey = (machineId: number) => {
  return `machine-${machineId}-timelines`;
};

const key = (args: FetchPaginatedTimelinesArgs) => {
  const queries = [
    'machine',
    `machineId=${args?.machineId}`,
    'timelines',
    `page=${args?.page}`,
  ];

  return [
    {
      groupKey: groupKey(args?.machineId),
      key: queries.join('|'),
    },
  ];
};

export const fetchPaginatedMachineTimelines = {
  groupKey,
  key,
  query,
};
