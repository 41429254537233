import {
  FetchConfigurationsParamsDef,
  FetchConfigurationsResponse,
} from './fetch-configurations.types';
import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { fetchConfigurations } from './fetch-configurations';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';

const fetchConfigurationsQuery = getGenericQueryObj({
  groupName: 'fetch-configurations',
  query: fetchConfigurations,
});

export const useFetchConfigurations = <
  TransformedData = FetchConfigurationsResponse,
>(
  props?: GenericFetchProps<
    FetchConfigurationsParamsDef,
    FetchConfigurationsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    cacheTime: Infinity,
    fallbackErrorMessage: 'Unable to fetch configurations.',
    queryObj: fetchConfigurationsQuery,
    staleTime: Infinity,
    ...props,
  });
};

export const useInvalidateFetchConfigurationsCache = () => {
  const invalidateFetchConfigurationsCache = useGetInvalidateQueryCache(
    fetchConfigurationsQuery,
  );

  return { invalidateFetchConfigurationsCache };
};
