import { useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Flex, Typography } from 'gantri-components';
import routePaths from '../../../../../config/route-paths';
import { StatementTransaction } from '../../../../../api/statements/routes';
import { StyledAnchor } from '../../../../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../../../../helpers/get-is-clickable-if-value';
import { formatStatementCurrency } from '../../../../statements/helpers/format-statement-currency';
import { Label } from '../../../../../components/label';
import { formatStatementQuantity } from '../../../../statements/helpers/format-statement-quantity';
import { formatStatementSku } from '../../../../statements/helpers/format-statement-sku';

export const useStatementItemsColumns = (props: { total: string }) => {
  const { total } = props;

  const columns: ColumnDef<StatementTransaction>[] = useMemo(() => {
    return [
      {
        accessorKey: 'date',
        cell: ({
          getValue,
        }: CellContext<StatementTransaction, StatementTransaction['date']>) => {
          return <Typography text={getValue()} />;
        },
        footer: 'TOTAL',
        header: 'Date',
        size: 80,
      },
      {
        accessorKey: 'order',
        cell: ({
          getValue,
        }: CellContext<
          StatementTransaction,
          StatementTransaction['order']
        >) => {
          const order = getValue();

          return (
            <Flex gap="x" wrap="nowrap">
              <Typography
                display="inline"
                text={order.type}
                whiteSpace="nowrap"
              />
              <StyledAnchor
                display="inline"
                text={`#${order.id}`}
                to={`${routePaths.orders}/${order.id}`}
              />
            </Flex>
          );
        },
        header: 'Order',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'sku',
        cell: ({
          getValue,
        }: CellContext<StatementTransaction, StatementTransaction['sku']>) => {
          const sku = getValue();

          return (
            <Typography text={formatStatementSku(sku)} whiteSpace="nowrap" />
          );
        },
        header: 'SKU',
      },
      {
        accessorKey: 'quantity',
        cell: ({
          getValue,
        }: CellContext<
          StatementTransaction,
          StatementTransaction['quantity']
        >) => {
          const quantity = getValue();

          return (
            <Typography
              align="right"
              text={formatStatementQuantity(quantity)}
            />
          );
        },
        header: ({ column }) => {
          const { label } = column.columnDef.meta;

          return <Label align="right" text={label} />;
        },
        meta: {
          label: 'Quantity',
        },
        size: 80,
      },
      {
        accessorKey: 'pricePerItem',
        cell: ({
          getValue,
        }: CellContext<
          StatementTransaction,
          StatementTransaction['pricePerItem']
        >) => {
          const pricePerItem = getValue();

          return (
            <Typography
              align="right"
              text={formatStatementCurrency(pricePerItem)}
            />
          );
        },
        header: ({ column }) => {
          const { label } = column.columnDef.meta;

          return <Label align="right" text={label} />;
        },
        meta: {
          label: 'Price per item',
        },
        size: 80,
      },
      {
        accessorKey: 'total',
        cell: ({
          getValue,
        }: CellContext<
          StatementTransaction,
          StatementTransaction['total']
        >) => {
          const total = getValue();

          return (
            <Typography
              align="right"
              color="t2"
              text={formatStatementCurrency(total)}
            />
          );
        },
        footer: () => {
          return (
            <Typography
              align="right"
              text={formatStatementCurrency(total)}
              textStyle="bold"
            />
          );
        },
        header: ({ column }) => {
          const { label } = column.columnDef.meta;

          return <Label align="right" text={label} />;
        },
        meta: {
          headerJustifyContent: 'flex-end',
          label: 'Total',
        },
        size: 80,
      },
    ];
  }, []);

  return columns;
};
