import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import {
  FetchMachineDetailsPanelArgsDef,
  FetchMachineDetailsPanelResponseDef,
} from './fetch-machine-detail-panel.types';
import { fetchMachineDetailsPanel } from './fetch-machine-detail-panel';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';

const fetchMachineDetailsPanelQueriesObj = getGenericQueryObj({
  groupName: 'fetch-machine-details-panel',
  query: fetchMachineDetailsPanel,
});

export const useFetchMachineDetailsPanel = <
  TransformedData = FetchMachineDetailsPanelResponseDef,
>(
  props?: GenericFetchProps<
    FetchMachineDetailsPanelArgsDef,
    FetchMachineDetailsPanelResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch machine details panel data.',
    queryObj: fetchMachineDetailsPanelQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchMachineDetailsPanelCache = () => {
  const invalidateFetchMachineDetailsPanelCache = useGetInvalidateQueryCache(
    fetchMachineDetailsPanelQueriesObj,
  );

  return { invalidateFetchMachineDetailsPanelCache };
};
