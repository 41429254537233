import * as yup from 'yup';
import { UpdateGrowthMultiplierFormData } from './update-growth-multiplier-modal.types';

const errorMsg = {
  max: 'Quantity must be less than 100',
  min: 'Quantity must be at least 1.',
  required: 'Required',
};

const lightSchema = yup
  .number()
  .min(1, errorMsg.min)
  .max(100, errorMsg.max)
  .required(errorMsg.required);

export const updateGrowthMultiplierSchema: yup.SchemaOf<UpdateGrowthMultiplierFormData> =
  yup.object().shape({
    accessory: yup.number(),
    clampLight: lightSchema,
    floorLight: lightSchema,
    flushMount: yup.number(),
    pendantLight: lightSchema,
    tableLight: lightSchema,
    wallLight: lightSchema,
    wallSconce: lightSchema,
  });
