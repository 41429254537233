import React, { FC } from 'react';
import { useField } from 'formik';
import {
  Conditional,
  FileUploader,
  Flex,
  Grid,
  HandleFileSelected,
  Icon,
  SelectedFileDetails,
  Typography,
  useThemeDefaults,
} from 'gantri-components';
import { FetchUsersEmailsItem } from '../../../../api/products/routes/fetch-users-emails/fetch-users-emails.types';
import { useFirebaseFileUploader } from '../../../../hooks/use-firebase-file-uploader';
import { downloadBlob } from '../../../../helpers/file';
import { defaultFileInfo } from '../shipping-section/shipping-section.constant';
import {
  TransactionType,
  transactionTypesMap,
} from '../../../../constants/options';

export const ResaleCertificate: FC = React.memo(() => {
  const themeDefaults = useThemeDefaults();

  const [{ value: userSelected }] =
    useField<FetchUsersEmailsItem>('userSelected');
  const [{ value: orderType }] = useField<TransactionType>('orderType');

  const [{ value: file }, , helper] = useField<
    SelectedFileDetails & { fileUrl?: string }
  >('resaleCertificate');

  const onFileSelected: HandleFileSelected = async (fileDetails) => {
    await helper.setValue(fileDetails);
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    onFileSelected,
  });

  return (
    <Flex direction="column">
      <Typography
        color="t1"
        paddingBottom=".5x"
        text="Resale Certificate"
        variant={themeDefaults.textField.labelVariant}
      />

      <Conditional
        condition={!!userSelected}
        Fallback={
          <Typography
            color="t2"
            marginTop="x"
            text="Please provide email first"
          />
        }
      >
        <FileUploader
          {...(fileUploaderProps as any)}
          buttonProps={{
            text: 'Add Certificate',
          }}
          CustomContent={() => {
            return (
              <Grid
                alignItems="center"
                columns="minmax(0, max-content) max-content"
                gap="x"
                paddingBottom="x"
                paddingTop=".6rem"
              >
                <Typography
                  color="link"
                  decoration="underline"
                  icon={<Icon color="link" name="arrows:arrow_download" />}
                  iconPosition="left"
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  text={file.fileName}
                  onClick={() => {
                    if (file.fileBlob) {
                      downloadBlob(file.fileBlob, file.fileName);

                      return;
                    }

                    window.open(file.fileUrl, '_blank');
                  }}
                />

                <Icon
                  color="alert"
                  cursor="pointer"
                  name="actions:trash_can"
                  onClick={() => {
                    helper.setValue(defaultFileInfo);
                  }}
                />
              </Grid>
            );
          }}
          expectedExtensions={['pdf']}
          fileName={file.fileName}
          variant={!!file.fileBlob || file.fileUrl ? 'custom' : 'link'}
        />
        <Conditional condition={orderType === transactionTypesMap.wholesale}>
          <Typography color="t2" marginTop="4px" text="Required" variant="p3" />
        </Conditional>
      </Conditional>
    </Flex>
  );
});
