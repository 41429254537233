import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Table } from 'gantri-components';
import { JobsFilter } from '../../components/dropdowns';
import { Job } from '../../api/jobs/jobs.types';
import { getJobsRowProps } from '../../helpers/get-jobs-row-props';
import {
  GetPaginatedJobsArgs,
  ProductName,
  UserName,
} from '../../api/jobs/routes/get-paginated-jobs/get-paginated-jobs.types';
import { PageWithTable } from '../../components/layout';
import { useNotification } from '../../hooks/useNotification';
import { useTableColumnsSync } from '../../hooks';
import { jobsApi } from '../../api';
import {
  dataAttrJobId,
  jobsModalFiltersDetails,
  jobsSortOptions,
} from './jobs.constants';
import {
  FiltersFetchRequestArgs,
  SortBy,
  useTableFilters,
} from '../../components/common/table/hooks';
import { JobsSortingField } from '../../components/dropdowns/jobs-filter/job-filter.types';
import {
  jobsPageAtoms,
  pageName,
} from '../../components/dropdowns/jobs-filter/job-filter.atoms';
import { JobsDetailsPanelWrapper } from '../../components/jobs-details-panel-wrapper';
import { useGetJobColumns } from './hooks/use-get-job-columns/use-get-job-columns';
import { JobsHeaderContent } from './components/jobs-header-content';
import { realTimeChannels } from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.constants';
import { useRealTimeWebHook } from '../../hooks/use-real-time-web-hook';
import { RealTimeJobChangedData } from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.types';

export const Jobs = () => {
  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const [productNames, setProductNames] = useState<ProductName[]>([]);
  const [userNames, setUserNames] = useState<UserName[]>([]);
  const [downloadFilters, setDownloadFilters] =
    useState<FiltersFetchRequestArgs<GetPaginatedJobsArgs, JobsSortingField>>();

  const columnsSyncProps = useTableColumnsSync('jobs');

  const fetchJobsPageData = async (
    args: FiltersFetchRequestArgs<GetPaginatedJobsArgs, JobsSortingField>,
    options: { showLoadingIndicator?: boolean } = {
      showLoadingIndicator: true,
    },
  ) => {
    if (options?.showLoadingIndicator) {
      showLoading();
    }

    setDownloadFilters(args);

    const handleGetJobsCount = async () => {
      const { data } = await jobsApi.getPaginatedJobsCount(args);

      setTotalCount(data.totalJobs || 0);
    };

    const handleGetJobsList = async () => {
      const { data } = await jobsApi.getPaginatedJobs(args);

      setCurrentPage(data.page || 1);
      setRecords(data.jobs || []);
      // TODO: we should eliminate this kind of practice, and replace it using its respective react-query implementation
      setProductNames(data.productNames || []);
      setUserNames(data.userNames || []);

      // Intentionally adding hide loading here to allow page interactions as fast as possible
      hideLoading();
    };

    try {
      await Promise.all([handleGetJobsCount(), handleGetJobsList()]);
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to fetch jobs.' });
    } finally {
      // Duplicating hide loading here just in case there is an error
      hideLoading();
    }
  };

  const {
    currentPage,
    filtersProps,
    handleFetchRequest,
    pagingProps,
    records,
    searchProps,
    setCurrentPage,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<Job, GetPaginatedJobsArgs, JobsSortingField>({
    excludeFromActiveFiltersCount: ['sortBy'],
    fetchRequest: fetchJobsPageData,
    filtersContent: (
      <JobsFilter productNames={productNames} userNames={userNames} />
    ),
    modalFiltersDetails: jobsModalFiltersDetails,
    pageName,
    searchFilterDetails: {
      atom: jobsPageAtoms.search,
    },
    sortOptions: jobsSortOptions,
  });

  const [sortingField, setSortingField] = useRecoilState(jobsPageAtoms.sortBy);

  const onSort = async ({ sortingField }: SortBy<JobsSortingField>) => {
    // overriding default onSort due to using non-standard sorting args

    setSortingField(sortingField);

    await handleFetchRequest({ page: 1 });
  };

  const onRefresh = async (options?: { showLoadingIndicator?: boolean }) => {
    await handleFetchRequest(
      {
        page: currentPage,
      },
      options,
    );
  };

  const {
    DownloadGcodeStatusToast,
    DownloadHandoutStatusToast,
    DownloadInstructionStatusToast,
    columns,
  } = useGetJobColumns({
    onRefresh: async () => {
      await handleFetchRequest({ page: 1 });
    },
  });

  const activeStatusFilters = useRecoilValue(jobsPageAtoms.filters.statuses);

  useRealTimeWebHook<Job, RealTimeJobChangedData>({
    activeStatusFilters,
    channel: realTimeChannels.jobs,
    dataAttrForRow: dataAttrJobId,
    handleFetchRequest: async () => {
      return onRefresh({ showLoadingIndicator: false });
    },
    records,
    setRecords,
  });

  return (
    <PageWithTable pageTitle="Jobs">
      <PageWithTable.Header title="Jobs">
        <JobsHeaderContent
          downloadFilters={downloadFilters}
          DownloadGcodeStatusToast={DownloadGcodeStatusToast}
          DownloadHandoutStatusToast={DownloadHandoutStatusToast}
          DownloadInstructionStatusToast={DownloadInstructionStatusToast}
          reloadCurrentPage={async () => {
            return handleFetchRequest({ page: currentPage });
          }}
          totalResults={pagingProps.total}
          userNames={userNames}
        />
      </PageWithTable.Header>

      <PageWithTable.Content>
        <JobsDetailsPanelWrapper
          records={records}
          userNames={userNames}
          onRefresh={onRefresh}
        >
          <Table
            columns={columns}
            data={records}
            filters={filtersProps}
            getRowProps={getJobsRowProps}
            highlightHoveredRow
            paging={pagingProps}
            search={searchProps}
            sorting={{
              ...sortProps,
              current: sortingField,
              onSort,
            }}
            stickyLastColumn
            {...columnsSyncProps}
          />
        </JobsDetailsPanelWrapper>
      </PageWithTable.Content>
    </PageWithTable>
  );
};
