import { useMemo, useState } from 'react';
import { ReassignRecord } from '../../../../../api/stocks/routes/reassign-stocks/reassign-stocks.types';
import { UseHandleInteractionsPropsDef } from './use-handle-interactions.types';

export const useHandleInteractions = (props: UseHandleInteractionsPropsDef) => {
  const { selectedStocks, setSelectedStocks, stock } = props;

  const newStockInit = useMemo(() => {
    // ? Added to resolve local env state management issue
    return selectedStocks.find(({ oldStockData }) => {
      return oldStockData.id === stock.id;
    })?.newStockData;
  }, []);

  const [checked, setChecked] = useState<boolean>(false);
  const [newStock, setNewStock] =
    useState<ReassignRecord['newStockData']>(newStockInit);

  const handleSetStock = (selectedStock: ReassignRecord['newStockData']) => {
    const checked = !!selectedStock?.id;

    setSelectedStocks((prevSelectedStocks) => {
      const getPrevSelectedStocksWithoutLastSelection = () => {
        return prevSelectedStocks.filter((item) => {
          return (
            item.newStockData.id !== newStock.id &&
            item.oldStockData.id !== stock.id
          );
        });
      };

      if (checked) {
        const newRecord: ReassignRecord = {
          newStockData: {
            id: selectedStock.id,
            status: selectedStock.status,
          },
          oldStockData: {
            id: stock.id,
            stockInfoId: stock.stockInfoId,
          },
        };

        if (newStock?.id) {
          return [...getPrevSelectedStocksWithoutLastSelection(), newRecord];
        }

        return [...prevSelectedStocks, newRecord];
      }

      return getPrevSelectedStocksWithoutLastSelection();
    });

    setChecked(checked);
    setNewStock(selectedStock);
  };

  const handleUncheck = () => {
    handleSetStock(null);
  };

  return { checked, handleSetStock, handleUncheck, newStock };
};
