import { Badge } from 'gantri-components';
import { useTheme } from 'styled-components';
import {
  StatusBadgePropsDef,
  StatusBadgeThemeDef,
  BadgeColorsDef,
} from './status-badge.types';
import { statusBadgeThemes } from './status-badge.constants';

export const StatusBadge = (props: StatusBadgePropsDef) => {
  const { theme, ...rest } = props;

  const { colors } = useTheme();

  const statusBadgeColors: Record<
    StatusBadgeThemeDef,
    Required<BadgeColorsDef>
  > = {
    [statusBadgeThemes.blueLight]: {
      borderColor: colors.surfaces.blue.t2,
      color: colors.surfaces.blue.t1,
      textColor: colors.typography.t1,
    },
    [statusBadgeThemes.greenDark]: {
      borderColor: colors.palette.green_400,
      color: colors.palette.green_400,
      textColor: colors.typography.alt,
    },
    [statusBadgeThemes.greenLight]: {
      borderColor: colors.surfaces.green.t2,
      color: colors.surfaces.green.t1,
      textColor: colors.typography.t1,
    },
    [statusBadgeThemes.monochromeLight]: {
      borderColor: colors.surfaces.monochrome.t3,
      color: colors.surfaces.monochrome.overlayLevel2,
      textColor: colors.typography.t1,
    },
    [statusBadgeThemes.redLight]: {
      borderColor: colors.surfaces.alert.t2,
      color: colors.surfaces.alert.t1,
      textColor: colors.typography.t1,
    },
    [statusBadgeThemes.yellowLight]: {
      borderColor: colors.palette.gold_200,
      color: colors.surfaces.warning.t1,
      textColor: colors.typography.t1,
    },
  };

  const badgeColors = statusBadgeColors[theme] || {};

  return <Badge {...badgeColors} {...rest} />;
};
