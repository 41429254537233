import { Cell, Checkbox, Stack, Toggle } from 'gantri-components';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { printerTypes } from '../../../constants/machines';
import {
  jobStatuses,
  JobType,
  machineRepairTypes,
  nonProductJobTypes,
  productJobTypes,
} from '../../../constants/options';
import { JobFilterProps } from './job-filter.types';
import { DatesFilter } from './components/dates-filter';
import { ColorFilter } from './components/color-filter';
import { ProductFilter } from './components/product-filter';
import { UserFilter } from './components/user-filter';
import { stepOptions } from '../../../api/jobs/routes/get-paginated-jobs/get-paginated-jobs.types';
import { MachineIssueDropdown } from '../../machine-issue-dropdown';
import { materials } from '../../../constants/parts';
import { jobsPageAtoms } from './job-filter.atoms';
import { OrderFilter } from './components/order-filter';
import { AssignedToFilter } from './components/assigned-to-filter';
import { userAtoms } from '../../../global-atoms/users';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { MachineLocationsAtomsCheckboxList } from '../../common/machine-locations-atoms-checkbox-list';

export const JobsFilter = (props: JobFilterProps) => {
  const { productNames, userNames } = props;

  const [nonProductOnly, setNonProductOnly] = useState(false);
  const { firstName, id, lastName, type } = useRecoilValue(userAtoms.user);

  const [attention, setAttention] = useRecoilState(
    jobsPageAtoms.filters.attentionOnly,
  );
  const [jobTypes, setJobTypes] = useRecoilState(jobsPageAtoms.filters.types);
  const resetJobTypes = useResetRecoilState(jobsPageAtoms.filters.types);

  const createdDateRange = useRecoilValue(
    jobsPageAtoms.filters.createdDateRange,
  );

  const completedDateRange = useRecoilValue(
    jobsPageAtoms.filters.completedDateRange,
  );

  const [late, setLate] = useRecoilState(jobsPageAtoms.filters.late);
  const isWorkerOrLead = type === 'Worker' || type === 'Lead';

  const productJobTypeValues = Object.values(productJobTypes);

  const nonProductJobTypeValues = useMemo(() => {
    return Object.values(nonProductJobTypes);
  }, [nonProductJobTypes]);

  const removeNonProductJobTypes = (values: JobType[]) => {
    return values.filter((value) => {
      return !nonProductJobTypeValues.some((jobType) => {
        return jobType === value;
      });
    });
  };

  const removeProductJobTypes = (values: JobType[]) => {
    return values.filter((value) => {
      return !productJobTypeValues.some((jobType) => {
        return jobType === value;
      });
    });
  };

  const users = useMemo(() => {
    if (isWorkerOrLead) {
      return [{ id, name: `${firstName} ${lastName}`, type }];
    }

    return userNames;
  }, [userNames, isWorkerOrLead]);

  useEffect(() => {
    const typesEqualNonProductJobTypes = isEqual(
      [...nonProductJobTypeValues].sort(),
      [...jobTypes].sort(),
    );

    setNonProductOnly(typesEqualNonProductJobTypes);
  }, [jobTypes]);

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.steps}
          default={jobsPageAtoms.defaults.filters.steps}
          text="Step"
        />
        <AtomsCheckboxList
          atom={jobsPageAtoms.filters.steps}
          gridProps={{ columns: 2 }}
          items={stepOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.types}
          default={jobsPageAtoms.defaults.filters.types}
          text="Product job types"
          transformDefaultValues={removeNonProductJobTypes}
          transformResetValues={removeProductJobTypes}
        />

        <AtomsCheckboxList
          atom={jobsPageAtoms.filters.types}
          gridProps={{ columns: 2 }}
          items={productJobTypeValues}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.types}
          default={jobsPageAtoms.defaults.filters.types}
          text="Non-product job types"
          transformDefaultValues={removeProductJobTypes}
          transformResetValues={removeNonProductJobTypes}
        />
        <Stack gap="3x">
          <Checkbox
            checked={nonProductOnly}
            labelText="Non-product Types Only"
            onSelected={(isChecked) => {
              if (isChecked) {
                setJobTypes(nonProductJobTypeValues);
              } else {
                resetJobTypes();
              }
            }}
          />

          <AtomsCheckboxList
            atom={jobsPageAtoms.filters.types}
            gridProps={{ columns: 2 }}
            items={nonProductJobTypeValues}
          />
        </Stack>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.statuses}
          default={jobsPageAtoms.defaults.filters.statuses}
          text="Status"
        />
        <AtomsCheckboxList
          atom={jobsPageAtoms.filters.statuses}
          gridProps={{ columns: 2 }}
          items={Object.values(jobStatuses)}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.late}
          default={jobsPageAtoms.defaults.filters.late}
          text="Late order only"
        />
        <Cell justifyContent="start">
          <Toggle value={late} onSelected={setLate} />
        </Cell>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.attentionOnly}
          default={jobsPageAtoms.defaults.filters.attentionOnly}
          text="Attention only"
        />
        <Cell justifyContent="start">
          <Toggle value={attention} onSelected={setAttention} />
        </Cell>
      </FilterRow>

      <UserFilter
        userIdsAtom={jobsPageAtoms.filters.users}
        userIdsDefault={jobsPageAtoms.defaults.filters.users}
        userNames={users}
        userStatusesAtom={jobsPageAtoms.filters.userStatuses}
        userStatusesDefault={jobsPageAtoms.defaults.filters.userStatuses}
        useSingleSelection={isWorkerOrLead}
      />

      <AssignedToFilter
        assignedToAtom={jobsPageAtoms.filters.assignedTo}
        default={jobsPageAtoms.defaults.filters.assignedTo}
        userNames={users}
        useSingleSelection={isWorkerOrLead}
      />

      <ProductFilter
        atom={jobsPageAtoms.filters.productId}
        default={jobsPageAtoms.defaults.filters.productId}
        productNames={productNames}
      />

      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.materials}
          default={jobsPageAtoms.defaults.filters.materials}
          text="Material"
        />
        <AtomsCheckboxList
          atom={jobsPageAtoms.filters.materials}
          items={Object.values(materials)}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.machineTypes}
          default={jobsPageAtoms.defaults.filters.machineTypes}
          text="Machine type"
        />
        <AtomsCheckboxList
          atom={jobsPageAtoms.filters.machineTypes}
          items={printerTypes}
        />
      </FilterRow>

      <OrderFilter
        atom={jobsPageAtoms.filters.orderId}
        default={jobsPageAtoms.defaults.filters.orderId}
      />

      <ColorFilter
        atom={jobsPageAtoms.filters.colors}
        default={jobsPageAtoms.defaults.filters.colors}
      />

      <DatesFilter endDate={completedDateRange} startDate={createdDateRange} />

      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.locations}
          default={jobsPageAtoms.defaults.filters.locations}
          text="Machine locations"
        />
        <MachineLocationsAtomsCheckboxList
          atom={jobsPageAtoms.filters.locations}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={jobsPageAtoms.filters.machineIssues}
          default={jobsPageAtoms.defaults.filters.machineIssues}
          text="Machine Repair"
        />
        <MachineIssueDropdown
          atom={jobsPageAtoms.filters.machineIssues}
          default={jobsPageAtoms.defaults.filters.machineIssues}
          items={machineRepairTypes.map(({ type }) => {
            return type;
          })}
        />
      </FilterRow>
    </FilterGrid>
  );
};
