import { Box } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledStockBox = styled(Box)<{ $isSelected: boolean }>`
  ${({ $isSelected, theme }) => {
    return css`
      cursor: pointer;
      border: ${$isSelected
        ? `2px solid ${theme.colors.typography.link}`
        : `1px solid ${theme.colors.dividers.t1}`};
      border-radius: 5px;
    `;
  }}
`;
