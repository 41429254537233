import { FetchMachineLocationsResponseDef } from './fetch-machine-locations.types';

export const fetchMachineLocations = async () => {
  return Promise.resolve({
    locations: [
      {
        id: 1,
        name: 'A-Aisle',
      },
      {
        id: 2,
        name: 'B-Even',
      },
      {
        id: 3,
        name: 'B-Odd',
      },
      {
        id: 4,
        name: 'C-Aisle - Polymaker',
      },
      {
        id: 5,
        name: 'D-Even',
      },
      {
        id: 6,
        name: 'D-Odd',
      },
      {
        id: 7,
        name: 'E-Even',
      },
      {
        id: 8,
        name: 'E-Odd',
      },
      {
        id: 9,
        name: 'F-Even',
      },
      {
        id: 10,
        name: 'F-Odd',
      },
      {
        id: 11,
        name: 'Inactive',
      },
      {
        id: 12,
        name: 'San Leandro',
      },
    ],
    success: true,
  } satisfies FetchMachineLocationsResponseDef);

  // ? The machine locations has been extracted out to a separate table in the DB.
  // ? The endpoint below has not been created yet, so we are temporarily returning
  // ? hard coded machine locations. The new endpoint should be created via a future
  // ? tech-debt ticket. - Decker 03/05/2025
  // return axios
  //   .get<FetchMachineLocationsResponseDef>(
  //     `${getCoreApiUrl('machines')}/locations`,
  //   )
  //   .then(({ data }) => {
  //     return data;
  //   });
};
