import { Grid, Table } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledTable = styled(Table)`
  ${({ theme }) => {
    return css`
      overflow: hidden;
      height: auto;
      max-height: 100%;
      border: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;

export const StyledGrid = styled(Grid)`
  ${({ theme }) => {
    return css`
      overflow-y: hidden;
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
    `;
  }}
`;
