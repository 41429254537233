import { FC } from 'react';
import { JobMenuProps } from './jobMenu.props';
import { useJobMenu } from '../../../hooks/use-job-menu';
import { useMoreMenu } from '../../../hooks/use-more-menu';

export const JobMenu: FC<JobMenuProps> = ({
  disableStartOption,
  job,
  onRefresh,
}) => {
  const { menuOptions } = useJobMenu({
    disableStartOption,
    job,
    onRefresh,
  });

  const { MoreMenu } = useMoreMenu({
    data: job,
    options: menuOptions,
  });

  return <MoreMenu />;
};
