import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import {
  JobEndDateCellProps,
  JobEndDatePropsDef,
} from './job-end-date-cell.types';

export const JobEndDateCell = ({ getValue, row }: JobEndDateCellProps) => {
  const job = row.original;
  const endDate = getValue();

  return <JobEndDate {...job} endDate={endDate} />;
};

export const JobEndDate = ({ endDate, id, statusInfo }: JobEndDatePropsDef) => {
  if (!endDate) {
    return null;
  }

  return (
    <DateWithUserDataCell
      data={statusInfo}
      date={endDate}
      jobId={id}
      type="End"
    />
  );
};
