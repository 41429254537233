import { useMemo } from 'react';
import { UseCreateIdleStockItemsPropsDef } from './use-create-idle-stock-items.types';

export const useCreateIdleStockItems = (
  props: UseCreateIdleStockItemsPropsDef,
) => {
  const { idleStocks, newStock, selectedStocks, stock } = props;

  const idleStocksItems = useMemo(() => {
    const availableIdleStocks = (idleStocks || []).filter((idleStock) => {
      const isSelectedElsewhere =
        idleStock.id !== newStock?.id &&
        selectedStocks.some(({ newStockData }) => {
          return newStockData.id === idleStock.id;
        });

      return !isSelectedElsewhere;
    });

    return availableIdleStocks.reduce(
      (accumulator, stock) => {
        return [
          ...accumulator,
          { ...stock, label: `#${stock.id} (${stock.status})` },
        ];
      },
      availableIdleStocks.length
        ? [
            {
              id: null,
              label: 'Select stock',
            },
          ]
        : [],
    );
  }, [selectedStocks, stock, idleStocks]);

  return idleStocksItems;
};
