import { Stack, Typography } from 'gantri-components';
import { PageHeading } from '../../components/layout/page-heading';

export const Page404 = () => {
  return (
    <Stack>
      <PageHeading title="Woah there friend." />
      <Typography text="Unfortunately, this page doesn't exist. If you're seeing this please let someone from the software team know." />
    </Stack>
  );
};
