import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { EditIssueArgs } from './edit-issue.types';

export const editIssue = (args: EditIssueArgs) => {
  const { id, ...data } = args;

  return axios
    .put(`${getCoreApiUrl('issues')}/${id}/edit`, data)
    .then((response) => {
      return response.data;
    });
};
