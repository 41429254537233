import { JobStep, JobType } from '../../../../constants/options';
import { Material } from '../../../../constants/parts';
import { ValuesOf } from '../../../../types';

export type ConfigurationNames = ValuesOf<typeof configurationNames>;

export const configurationNames = {
  /** Returns inventory details for each spool material type. */
  changeSpool: 'change_spool',
  /** Returns a map of job steps available for each job type. */
  jobStepsMap: 'job_steps_map',
} as const;

export interface FetchConfigurationsParamsDef {
  names: ConfigurationNames[];
}

export type ChangeSpoolValue = Record<
  Exclude<Material, 'N/A'>,
  {
    id: number;
    name: string;
    weight: number;
  }
>;

export type JobsStepsMapDef = Record<JobType, JobStep[]>;

export type FetchConfigurationsResponse = Partial<{
  [configurationNames.changeSpool]: ChangeSpoolValue;
  [configurationNames.jobStepsMap]: JobsStepsMapDef;
}>;
