import { CreateGiftCodeArgs } from '../../api/gift-codes/routes/create-giftcode/create-giftcode.types';
import { discountTypes } from '../../api/transactions/transactions.constants';
import { CreateGiftCodeFormType } from './new-gift-code';

export const convertFormValuesToAsyncCreateGiftCodeArgs = (
  formValues: CreateGiftCodeFormType,
): CreateGiftCodeArgs => {
  const { accountLimit, amount, code, details, totalLimit, type, types } =
    formValues;

  return {
    accountLimit: parseInt(accountLimit, 10),
    amount:
      parseInt(amount, 10) * (type === discountTypes.discountPercent ? 1 : 100),
    code,
    details,
    totalLimit: parseInt(totalLimit, 10),
    type,
    userTypes: Object.keys(types).filter((type) => {
      return types[type];
    }),
  };
};
