import {
  StatementCategory,
  TransactionsSortingField,
} from '../../api/statements/routes';
import { DateRangeFilter } from '../../components/common/table/components/table-filters/table-filters.types';
import { getSortByFilterAtomFamily } from '../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { SortBy } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { TransactionType } from '../../constants/options';
import { getPrevMonthsDateRange } from '../statements/statements.atoms';

export const transactionsFiltersDefaults: {
  filters: {
    categories: StatementCategory[];
    dateRange: DateRangeFilter;
    designerName: string;
    designersActiveOnly: boolean;
    types: TransactionType[];
  };
  sortBy: SortBy<TransactionsSortingField>;
} = {
  filters: {
    categories: [],
    dateRange: getPrevMonthsDateRange(),
    designerName: undefined,
    designersActiveOnly: true,
    types: [],
  },
  sortBy: {
    sortingField: 'date',
    sortingType: 'DESC',
  },
};

const atomKeyPrefix = 'STATEMENT_TRANSACTIONS_FILTERS';

export const pageName: AtomPageName = 'transactions';

export const transactionsPageAtoms = {
  defaults: transactionsFiltersDefaults,
  filters: {
    categories: getGenericAtomFamily({
      defaultValue: transactionsFiltersDefaults.filters.categories,
      key: `${atomKeyPrefix}-categories`,
    })(pageName),
    dateRange: getGenericAtomFamily({
      defaultValue: transactionsFiltersDefaults.filters.dateRange,
      key: `${atomKeyPrefix}-date-range`,
    })(pageName),
    designerName: getGenericAtomFamily({
      defaultValue: transactionsFiltersDefaults.filters.designerName,
      key: `${atomKeyPrefix}-designer-name`,
    })(pageName),
    designersActiveOnly: getGenericAtomFamily({
      defaultValue: transactionsFiltersDefaults.filters.designersActiveOnly,
      key: `${atomKeyPrefix}-designers-active-only`,
    })(pageName),
    types: getGenericAtomFamily({
      defaultValue: transactionsFiltersDefaults.filters.types,
      key: `${atomKeyPrefix}-types`,
    })(pageName),
  },
  sortBy: getSortByFilterAtomFamily<SortBy<TransactionsSortingField>>({
    defaultValue: transactionsFiltersDefaults.sortBy,
  })(pageName),
};
