import * as yup from 'yup';
import { allProductColorCodes } from 'gantri-components';
import {
  isOpaqueMaterial,
  Material,
  materials,
} from '../../../../../../../../constants/parts';
import { AddOrEditPartsFormData } from './add-or-edit-part-modal.types';
import { machineTypes } from '../../../../../../../../constants/machines';
import { partPaintedStatuses } from '../../../../../../../../api/products/products.constants';

const requiredText = 'Required';

export const schema: yup.SchemaOf<AddOrEditPartsFormData> = yup.object().shape({
  colorOverride: yup
    .array()
    .of(
      yup.object().shape({
        from: yup.object().shape({
          code: yup
            .mixed()
            .oneOf([...allProductColorCodes])
            .required(requiredText),
          name: yup.string().required(requiredText),
        }),
        to: yup.object().shape({
          code: yup
            .mixed()
            .oneOf([...allProductColorCodes])
            .required(requiredText),
          name: yup.string().required(requiredText),
        }),
      }),
    )
    .required(requiredText),
  hasBondo: yup
    .mixed()
    .oneOf([true, false])
    .when('material', {
      is: (material: Material) => {
        return isOpaqueMaterial(material);
      },
      otherwise: yup.mixed().oneOf([false]).required(requiredText),
      then: yup.mixed().oneOf([true, false]).required(requiredText),
    }),
  id: yup.mixed().required(requiredText),
  machineType: yup
    .mixed()
    .oneOf(Object.values(machineTypes))
    .required(requiredText),
  material: yup.mixed().oneOf(Object.values(materials)).required(requiredText),
  name: yup.string().required(requiredText),
  paintedStatus: yup
    .mixed()
    .oneOf([partPaintedStatuses.painted, partPaintedStatuses.unpainted])
    .when('material', {
      is: (material: Material) => {
        return isOpaqueMaterial(material);
      },
      otherwise: yup.mixed().oneOf([partPaintedStatuses.unpainted]).required(),
      then: yup
        .mixed()
        .oneOf([partPaintedStatuses.painted, partPaintedStatuses.unpainted])
        .required(requiredText),
    }),
  totalPrintBlocks: yup
    .number()
    .min(1, ({ min }) => {
      return `Number cannot be less than ${min}.`;
    })
    .max(3, ({ max }) => {
      return `Number cannot be greater than ${max}.`;
    })
    .required(requiredText),
});
