import { Button, Checkbox, Modal, Stack } from 'gantri-components';
import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { AtomsCheckboxList } from '../../../../../../components/common/atoms-checkbox-list';
import {
  FilterGrid,
  FilterRow,
} from '../../../../../../components/common/filter-grid';
import { FilterResetLabel } from '../../../../../../components/common/filter-reset-label';
import {
  machinesOverviewPageAtoms,
  visibleMachineStatusOptions,
  actionOnlyViewVisibleStatuses,
} from '../../../../machines-overview.atoms';
import { ConfigureMachinesOverviewModalPropsDef } from './configure-machines-overview-modal.types';
import { MachineLocationsAtomsCheckboxList } from '../../../../../../components/common/machine-locations-atoms-checkbox-list';

export const ConfigureMachinesOverviewModal = (
  props: ConfigureMachinesOverviewModalPropsDef,
) => {
  const { filtersProps, onClose } = props;

  const [visibleStatuses, setVisibleStatuses] = useRecoilState(
    machinesOverviewPageAtoms.filters.visibleStatuses,
  );

  const visibleStatusItems = useMemo(() => {
    return Object.values(visibleMachineStatusOptions);
  }, []);

  const onActionOnlyViewSelected = (checked: boolean): void => {
    if (checked) {
      setVisibleStatuses(actionOnlyViewVisibleStatuses);
    } else {
      setVisibleStatuses((visibleStatuses) => {
        return visibleStatuses.filter((status) => {
          const isDefaultStatus = actionOnlyViewVisibleStatuses.some(
            (visibleStatus) => {
              return visibleStatus === status;
            },
          );

          return !isDefaultStatus;
        });
      });
    }
  };

  const handleOnReset = async () => {
    await filtersProps.onReset();
    onClose();
  };

  const handleOnApply = async () => {
    await filtersProps.onApply();
    onClose();
  };

  const handleOnClose = () => {
    filtersProps.onClose();
    onClose();
  };

  const isActionOnlyViewSelected = useMemo(() => {
    return isEqual(
      // ? values are spread to avoid failing tests. Unsure why they're failing without that as everything is defined.
      [...actionOnlyViewVisibleStatuses].sort(),
      [...visibleStatuses].sort(),
    );
  }, [visibleStatuses]);

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Reset"
            variant="secondary"
            onClick={handleOnReset}
          />
          <Button size="large" text="Apply" onClick={handleOnApply} />
        </>
      }
      header="Configure View"
      onClose={handleOnClose}
    >
      <FilterGrid>
        <FilterRow>
          <FilterResetLabel
            atom={machinesOverviewPageAtoms.filters.locations}
            default={machinesOverviewPageAtoms.defaults.filters.locations}
            text="Machine locations"
          />
          <MachineLocationsAtomsCheckboxList
            atom={machinesOverviewPageAtoms.filters.locations}
          />
        </FilterRow>

        <FilterRow>
          <FilterResetLabel
            atom={machinesOverviewPageAtoms.filters.visibleStatuses}
            default={machinesOverviewPageAtoms.defaults.filters.visibleStatuses}
            text="Visible statuses"
          />
          <Stack>
            <Checkbox
              checked={isActionOnlyViewSelected}
              labelText="Action-only view"
              onSelected={onActionOnlyViewSelected}
            />

            <AtomsCheckboxList
              atom={machinesOverviewPageAtoms.filters.visibleStatuses}
              gridProps={{ columns: 2 }}
              items={visibleStatusItems}
            />
          </Stack>
        </FilterRow>
      </FilterGrid>
    </Modal>
  );
};
