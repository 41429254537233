import { CellContext, ColumnDef } from '@tanstack/react-table';
import { memo, useCallback } from 'react';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { VisibleMachineStatus } from '../../machines-overview.atoms';
import { Label } from '../../../../components/label';
import { MachineStatusBadgeWithCheckMsg } from '../../components/machine-status-badge-with-check-msg';
import { MachinesOverviewItem } from '../../../../api/machines/routes/fetch-machines-overview/fetch-machines-overview.types';

export const useMachinesOverviewColumns = (
  visibleStatusesFilter: VisibleMachineStatus[],
) => {
  const getMachinesOverviewColumns = useCallback(
    (header: string): ColumnDef<MachinesOverviewItem>[] => {
      return [
        {
          accessorKey: 'id',
          cell: memo(
            ({
              row,
            }: CellContext<
              MachinesOverviewItem,
              MachinesOverviewItem['id']
            >) => {
              return <StyledAnchor text={row.original.bay} />;
            },
          ),
          header: () => {
            return <Label text={header} whiteSpace="nowrap" />;
          },
          size: 50,
        },
        {
          accessorKey: 'status',
          cell: memo(
            ({
              getValue,
              row,
            }: CellContext<
              MachinesOverviewItem,
              MachinesOverviewItem['status']
            >) => {
              const status = getValue();

              return (
                <MachineStatusBadgeWithCheckMsg
                  hideIcon
                  lastStatusChangeDate={row.original.lastStatusChangeDate}
                  status={status}
                  visibleStatuses={visibleStatusesFilter}
                />
              );
            },
          ),
          header: ' ',
          size: 120,
        },
      ];
    },
    [visibleStatusesFilter],
  );

  return getMachinesOverviewColumns;
};
