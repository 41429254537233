import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchStatementArgsDef,
  FetchStatementResponseDef,
} from './fetch-statement.types';

export const fetchStatement = ({ id }: FetchStatementArgsDef) => {
  return axios
    .get<FetchStatementResponseDef>(`${getCoreApiUrl('statements')}/${id}`)
    .then(({ data }) => {
      return data;
    });
};
