import { Stack, Typography } from 'gantri-components';

export const JobNotAddedContent = () => {
  return (
    <Stack
      alignContent="center"
      gap="0"
      justifyContent="center"
      minHeight="17.2rem"
      paddingBottom="2x"
    >
      <Typography
        align="center"
        text="There was en error and the job(s) could not be added."
      />
      <Typography align="center" text="Please try again." />
    </Stack>
  );
};
