import { ReasonItemDef } from '../../components/order-reason/reason-stock';

export const returnAndRefundReasonItems: ReasonItemDef[] = [
  {
    requireSpecifics: false,
    value: "Size doesn't fit",
  },
  {
    requireSpecifics: false,
    value: "Style doesn't fit",
  },
  {
    requireSpecifics: false,
    value: 'Shipping takes too long',
  },
  {
    requireSpecifics: true,
    value: 'Quality issue',
  },
  {
    requireSpecifics: true,
    value: 'Other',
  },
].map((item) => {
  return { ...item, label: item.value };
});
