import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchOrderSummaryArgsDef,
  FetchOrderSummaryResponseDef,
} from './fetch-order-summary.types';
import { fetchOrderSummary } from './fetch-order-summary';

const fetchOrderSummaryQueriesObj = getGenericQueryObj({
  groupName: 'fetch-order-summary',
  query: fetchOrderSummary,
});

export const useFetchOrderSummary = <
  TransformedData = FetchOrderSummaryResponseDef,
>(
  props?: GenericFetchProps<
    FetchOrderSummaryArgsDef,
    FetchOrderSummaryResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch order summary.',
    queryObj: fetchOrderSummaryQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchOrderSummaryCache = () => {
  const invalidateFetchOrderSummaryCache = useGetInvalidateQueryCache(
    fetchOrderSummaryQueriesObj,
  );

  return { invalidateFetchOrderSummaryCache };
};
