import { Button, Icon, useModal } from 'gantri-components';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { machinesOverviewPageAtoms } from '../../machines-overview.atoms';
import { ConfigureMachinesOverviewModal } from './components/configure-machines-overview-modal';
import { ConfigureMachinesOverviewButtonPropsDef } from './configure-machines-overview-button.types';

export const ConfigureMachinesOverviewButton = (
  props: ConfigureMachinesOverviewButtonPropsDef,
) => {
  const { filtersProps } = props;

  const locations = useRecoilValue(machinesOverviewPageAtoms.filters.locations);
  const visibleStatuses = useRecoilValue(
    machinesOverviewPageAtoms.filters.visibleStatuses,
  );

  const [showConfigureModal, hideConfigureModal] = useModal(() => {
    return (
      <ConfigureMachinesOverviewModal
        filtersProps={filtersProps}
        onClose={hideConfigureModal}
      />
    );
  }, [
    locations,
    visibleStatuses,
    filtersProps?.onClose /* Ensures the atoms snapshot update is correctly passed through */,
  ]);

  const buttonText = useMemo(() => {
    const numActive = filtersProps.numActive
      ? `(${filtersProps.numActive})`
      : '';

    return `Configure ${numActive}`.trim();
  }, [filtersProps.numActive]);

  return (
    <Button
      alignSelf="start"
      icon={<Icon name="actions:gear" />}
      justifySelf="start"
      text={buttonText}
      variant="tertiary"
      onClick={showConfigureModal}
    />
  );
};
