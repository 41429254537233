import { Button } from 'gantri-components';
import { MouseEvent } from 'react';
import { jobStatuses } from '../../../../../../constants/options';
import { useJobComplete } from '../../../useJobComplete';
import { useJobStart } from '../../../useJobStart';
import { JobActionButtonPropsDef } from './job-action-cell.types';

export const JobActionButton = (props: JobActionButtonPropsDef) => {
  const { disabled, job, onRefresh } = props;

  const isReady = job.status === jobStatuses.ready;
  const isInProgress = job.status === jobStatuses.inProgress;

  const handleRefresh = async () => {
    await onRefresh();
  };

  const onStartJob = useJobStart({
    onStartEnd: handleRefresh,
  });

  const onCompleteJob = useJobComplete({
    onCompleteEnd: handleRefresh,
  });

  const handleOnClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (isInProgress) {
      await onCompleteJob(job.id);
    } else {
      await onStartJob(job.id);
    }
  };

  return isReady || isInProgress ? (
    <Button
      disabled={disabled}
      minWidth="10rem"
      text={isInProgress ? 'Complete' : 'Start'}
      variant="secondary"
      onClick={handleOnClick}
    />
  ) : null;
};
