import { Conditional, Flex, Icon } from 'gantri-components';
import { MachineStatusBadge } from '../../../machines/components/machine-status-badge';
import { visibleMachineStatusOptions } from '../../machines-overview.atoms';
import { machineStatuses } from '../../../../constants/machines';
import { Label } from '../../../../components/label';
import { useShowInProgressCheck } from './hooks/use-show-in-progress-check';
import { MachineStatusBadgeWithCheckMsgPropsDef } from './machine-status-badge-with-check-msg.types';

/**
 * Displays the Machine status badge. If the `lastStatusChangeDate` is within
 * `showCheckWithinMinutes`, displays a Check message next to the badge.
 */
export const MachineStatusBadgeWithCheckMsg = (
  props: MachineStatusBadgeWithCheckMsgPropsDef,
) => {
  const {
    hideIcon = true,
    lastStatusChangeDate,
    showCheckWithinMinutes = 30,
    status,
    visibleStatuses,
  } = props;

  const showInProgressCheck = useShowInProgressCheck({
    lastStatusChangeDate,
    showCheckWithinMinutes,
    status,
    visibleStatuses,
  });

  const isMachineInProgress = status === machineStatuses.inProgress;

  const isVisibleStatus =
    !visibleStatuses ||
    visibleStatuses?.some((visibleStatus) => {
      const isInProgressCheckVisibleStatus =
        visibleStatus === visibleMachineStatusOptions.inProgressCheck;

      if (isMachineInProgress && isInProgressCheckVisibleStatus) {
        return showInProgressCheck;
      }

      return visibleStatus === status;
    });

  return (
    <Conditional condition={isVisibleStatus}>
      <Flex alignItems="center" gap="x" wrap="nowrap">
        <MachineStatusBadge hideIcon={hideIcon} status={status} />
        <Conditional condition={showInProgressCheck}>
          <Label
            color="warning"
            icon={<Icon color="warning" name="time:clock" />}
            text="Check"
            whiteSpace="nowrap"
          />
        </Conditional>
      </Flex>
    </Conditional>
  );
};
