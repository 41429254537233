import { Conditional, Flex, Icon, Typography } from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import {
  CellCurrentJob,
  CellLastAction,
  CellLocation,
  CellPartCreatedAt,
  CellPartName,
  CellPartStatus,
} from './components';
import { AttentionGridCell } from '../../../../components/layout/attention-grid-cell';
import { StockPartsTableRecord } from './stock-parts.types';
import { CellJobAttempt } from '../../../part/components/part-jobs/components/cell-job-attempt';

export const useGetStockPartsColumns = ({
  hasPartsWithAttempts,
}: {
  hasPartsWithAttempts: boolean;
}) => {
  const columns: ColumnDef<StockPartsTableRecord>[] = [
    {
      cell: ({ row }: CellContext<StockPartsTableRecord, never>) => {
        return (
          <Conditional condition={row.getCanExpand()}>
            <Flex justifyContent="center">
              <Icon
                cursor="pointer"
                name={
                  row.getIsExpanded()
                    ? 'ui-control:minus_24'
                    : 'ui-control:plus_24'
                }
                size="2rem"
                onClick={row.getToggleExpandedHandler()}
              />
            </Flex>
          </Conditional>
        );
      },
      id: 'toggle',
      meta: {
        getOnClick: ({ row }: Cell<StockPartsTableRecord, never>) => {
          return row.getCanExpand()
            ? row.getToggleExpandedHandler()
            : undefined;
        },
        hidden: !hasPartsWithAttempts,
      },
      size: 30,
    },
    {
      accessorKey: 'name',
      cell: CellPartName,
      header: 'Name',
      minSize: 340,
    },
    {
      accessorKey: 'id',
      cell: ({
        getValue,
      }: CellContext<StockPartsTableRecord, StockPartsTableRecord['id']>) => {
        return <Typography text={`#${getValue()}`} />;
      },
      header: 'Part #',
      size: 60,
    },
    {
      accessorKey: 'status',
      cell: CellPartStatus,
      header: 'Status',
    },
    {
      accessorKey: 'attempt',
      cell: ({
        getValue,
      }: CellContext<
        StockPartsTableRecord,
        StockPartsTableRecord['attempt']
      >) => {
        return <CellJobAttempt attempt={getValue()} />;
      },
      header: 'Atmpt',
      size: 60,
    },
    {
      accessorKey: 'createdAt',
      cell: CellPartCreatedAt,
      header: 'Created',
      minSize: 110,
    },
    {
      accessorKey: 'currentJob',
      cell: CellCurrentJob,
      header: 'Current Job',
    },
    {
      accessorKey: 'lastActionAt',
      cell: CellLastAction,
      header: 'Last Action',
      size: 200,
    },
    {
      accessorKey: 'location',
      cell: CellLocation,
      header: 'Location',
      size: 200,
    },
    {
      accessorKey: 'attentions',
      cell: ({
        getValue,
      }: CellContext<
        StockPartsTableRecord,
        StockPartsTableRecord['attentions']
      >) => {
        return <AttentionGridCell attentions={getValue()} />;
      },
      header: 'Attention',
    },
  ];

  return columns;
};
