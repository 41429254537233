import React, { FC } from 'react';
import {
  Button,
  Conditional,
  Flex,
  Tooltip,
  useModal,
} from 'gantri-components';
import { useUserHasAccessLevel } from '../../../hooks/use-user-has-access-level';
import { userAccessDeniedMessage } from '../../../constants/users';
import { useMoreMenu } from '../../../hooks/use-more-menu';
import { OverviewMachine } from '../../../api/machines/routes';
import { getChangeSpoolDisabled } from '../../machines/helpers';
import { ChangeSpoolModal } from '../../machines/components/change-spool-modal';

interface FormHeaderProps {
  canEdit?: boolean;
  currentMachine: OverviewMachine;
  isEditModeActive?: boolean;
  isSyncing?: boolean;
  isValid?: boolean;
  onCancel: () => void;
  onEdit?: () => void;
  onRefreshData?: () => Promise<void>;
  onSave?: () => void;
}

export const FormHeader: FC<FormHeaderProps> = (props) => {
  const {
    canEdit,
    currentMachine,
    isEditModeActive,
    isSyncing,
    isValid,
    onCancel,
    onEdit,
    onRefreshData,
    onSave,
  } = props;

  const { hasLeadAccess } = useUserHasAccessLevel();
  const disableEdit = !canEdit || !hasLeadAccess;

  const [showChangeSpoolModal, hideChangeSpoolModal] = useModal(() => {
    return (
      <ChangeSpoolModal
        machinesToUpdate={[currentMachine]}
        onClose={hideChangeSpoolModal}
        onSuccess={onRefreshData}
      />
    );
  }, [currentMachine]);

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        disabled: getChangeSpoolDisabled(currentMachine),
        name: 'Change Spool',
        onOptionClick: () => {
          showChangeSpoolModal();
        },
      },
    ],
  });

  return (
    <Flex alignItems="center" gap="x">
      <Conditional
        condition={isEditModeActive}
        Fallback={
          <Tooltip
            description={!hasLeadAccess && userAccessDeniedMessage}
            position="left"
          >
            <Button
              disabled={disableEdit}
              text="Edit Machine"
              variant="secondary"
              onClick={onEdit}
            />
          </Tooltip>
        }
      >
        <Flex gap=".5x">
          <Button
            disabled={!isValid}
            processing={isSyncing}
            text="Save"
            type="submit"
            onClick={onSave}
          />
          <Button
            disabled={isSyncing}
            text="Cancel"
            variant="secondary"
            onClick={onCancel}
          />
        </Flex>
      </Conditional>

      <MoreMenu />
    </Flex>
  );
};
