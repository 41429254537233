import React, { useMemo } from 'react';
import { Cell, Dropdown, SearchField, Toggle } from 'gantri-components';
import { useRecoilState } from 'recoil';
import DatePicker from '../../common/date-picker';
import {
  statusFilterOptions,
  stockFilterOptions,
} from './parts-filter.constants';
import { Locations } from '../../common/part-location-information/part-location-information.types';
import { partsPageAtoms } from './parts-filter.atoms';
import { OnDateChanged } from '../../common/date-picker/date-picker.types';
import { partsAttentionAlertOptions } from '../../../api/parts/routes/get-parts/get-parts.constants';
import { OrdersSearchInput } from '../components/orders-search-input';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { PartsFilterProps } from './parts-filter.types';
import { useGetAllProducts } from '../../../api/products/routes';
import { materialsFilterOptions } from '../../../constants/parts';

const PartsFilter = (props: PartsFilterProps) => {
  const { locations } = props;

  const [attentionOnly, setAttentionOnly] = useRecoilState(
    partsPageAtoms.filters.attentionOnly,
  );
  const [completedDateRange, setCompletedDateRange] = useRecoilState(
    partsPageAtoms.filters.completedDateRange,
  );
  const [late, setLate] = useRecoilState(partsPageAtoms.filters.late);
  const [locationId, setLocationId] = useRecoilState(
    partsPageAtoms.filters.locationId,
  );

  const [productId, setProductId] = useRecoilState(
    partsPageAtoms.filters.productId,
  );
  const [status, setStatus] = useRecoilState(partsPageAtoms.filters.status);
  const [stock, setStock] = useRecoilState(partsPageAtoms.filters.stock);
  const [lastActionDateRange, setLastActionDateRange] = useRecoilState(
    partsPageAtoms.filters.lastActionDateRange,
  );

  const { data, isLoading: isLoadingAllProducts } = useGetAllProducts();
  const allProducts = data?.products || [];

  const handleCompletedDateChange: OnDateChanged = ({
    formattedFrom,
    formattedTo,
  }) => {
    setCompletedDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const handleLastActionDateChange: OnDateChanged = ({
    formattedFrom,
    formattedTo,
  }) => {
    setLastActionDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const handleLocationChange = (item: Locations) => {
    setLocationId(item?.id);
  };

  const locationOptions = useMemo(() => {
    return locations;
  }, [locations]);

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.productId}
          default={partsPageAtoms.defaults.filters.productId}
          text="Product"
        />
        <SearchField
          idProperty="id"
          items={[
            {
              fullProductName: 'Select product...',
              id: partsPageAtoms.defaults.filters.productId,
            },
            ...allProducts,
          ]}
          labelProperty="fullProductName"
          placeholder="Select product..."
          processing={isLoadingAllProducts}
          value={productId}
          onSelect={(product) => {
            return setProductId(product?.id);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.locationId}
          default={partsPageAtoms.defaults.filters.locationId}
          text="Location"
        />
        <SearchField
          idProperty="id"
          items={locationOptions}
          labelProperty="name"
          placeholder="Select Location..."
          value={locationId}
          onSelect={handleLocationChange}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.status}
          default={partsPageAtoms.defaults.filters.status}
          text="Status"
        />
        <Dropdown
          items={[...statusFilterOptions]}
          placeholder="Select status..."
          value={status}
          onSelect={(item) => {
            setStatus(item.value);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.stock}
          default={partsPageAtoms.defaults.filters.stock}
          text="Stock"
        />
        <Dropdown
          items={[...stockFilterOptions]}
          placeholder="Select stock..."
          value={stock}
          onSelect={(item) => {
            setStock(item.value);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.orderId}
          default={partsPageAtoms.defaults.filters.orderId}
          text="Order #"
        />
        <OrdersSearchInput atom={partsPageAtoms.filters.orderId} />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.completedDateRange}
          default={partsPageAtoms.defaults.filters.completedDateRange}
          text="Date completed"
        />
        <DatePicker
          initialValue={completedDateRange}
          range
          onDateChanged={handleCompletedDateChange}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.lastActionDateRange}
          default={partsPageAtoms.defaults.filters.lastActionDateRange}
          text="Last action date"
        />
        <DatePicker
          initialValue={lastActionDateRange}
          range
          onDateChanged={handleLastActionDateChange}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.materials}
          default={partsPageAtoms.defaults.filters.materials}
          text="Material"
        />
        <AtomsCheckboxList
          atom={partsPageAtoms.filters.materials}
          items={materialsFilterOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.late}
          default={partsPageAtoms.defaults.filters.late}
          text="Late order only"
        />
        <Cell justifyContent="start">
          <Toggle value={late} onSelected={setLate} />
        </Cell>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.attentionOnly}
          default={partsPageAtoms.defaults.filters.attentionOnly}
          text="Attention only"
        />
        <Cell justifyContent="start">
          <Toggle value={attentionOnly} onSelected={setAttentionOnly} />
        </Cell>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={partsPageAtoms.filters.attentionAlerts}
          default={partsPageAtoms.defaults.filters.attentionAlerts}
          text="Attention Alerts"
        />
        <AtomsCheckboxList
          atom={partsPageAtoms.filters.attentionAlerts}
          items={[...partsAttentionAlertOptions]}
        />
      </FilterRow>
    </FilterGrid>
  );
};

export default PartsFilter;
