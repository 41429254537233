import { useMemo } from 'react';
import { Conditional, Flex, Grid, Icon, Tooltip } from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import MachineNameDataCell from '../../../../../../components/tables/custom-data-cells/machine-name-data-cell';
import routePaths from '../../../../../../config/route-paths';
import { JobStatusBadge } from '../../../../../../components/common/custom-data-cells/status-data-cell';
import { RFIDCell } from '../../../../../../components/common/custom-data-cells/rfid-data-cell';
import { StyledAttentionsList } from '../../machine-jobs-table.styles';
import { StyledIconWithTextContainer } from '../../../../../../assets/styles/common';
import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { JobMenu } from '../../../../../../components/common/jobMenu/jobMenu';
import { Job } from '../../../../../../api/jobs/jobs.types';
import { UseGetMachineJobsColumnsProps } from './use-get-machine-jobs-columns.types';
import { getIsClickableIfValue } from '../../../../../../helpers/get-is-clickable-if-value';
import { ShortProductSummary } from '../../../../../../components/common/short-product-summary';
import { JobActionButton } from '../../../../../jobs/hooks/use-get-job-columns/components/job-action-cell';
import { getDisableJobActionButton } from '../../../../../jobs/hooks/use-get-job-columns/components/job-action-cell/helpers/get-disable-job-action-button';
import { jobSteps } from '../../../../../../constants/options';

export const useGetMachineJobsColumns = (
  props: UseGetMachineJobsColumnsProps,
) => {
  const { currentMachine, onRefresh } = props;

  return useMemo(() => {
    const columns: ColumnDef<Job>[] = [
      {
        accessorKey: 'product',
        cell: ({ getValue }: CellContext<Job, Job['product']>) => {
          const product = getValue();

          return <ShortProductSummary {...product} />;
        },
        header: 'Product',
        minSize: 280,
      },
      {
        accessorKey: 'description',
        cell: ({ row: { original } }: CellContext<Job, Job['description']>) => {
          return (
            original.description && (
              <StyledIconWithTextContainer>
                <MachineNameDataCell {...original} />

                {!!original.attentions && !!original.attentions.length && (
                  <Tooltip
                    Component={
                      <StyledAttentionsList>
                        {original.attentions.map((attention, index) => {
                          return <div key={index}>{attention.name}</div>;
                        })}
                      </StyledAttentionsList>
                    }
                    position="top"
                  >
                    <Icon color="alert" name="view:eye_open" />
                  </Tooltip>
                )}
              </StyledIconWithTextContainer>
            )
          );
        },
        header: 'Job Name',
      },
      {
        accessorKey: 'part',
        cell: ({ row: { original } }: CellContext<Job, Job['part']>) => {
          if (original.part) {
            const { id, name: partName } = original.part;

            return (
              <Flex alignItems="center" gap="x">
                <StyledAnchor
                  href={`${routePaths.parts}/${id}`}
                  text={partName}
                />
                <RFIDCell partId={id} stockId={original.stockId} />
              </Flex>
            );
          }

          return null;
        },
        header: 'Part Name',
        meta: {
          getIsClickable: ({ getValue }: Cell<Job, Job['part']>) => {
            return !!getValue()?.id;
          },
        },
        size: 250,
      },
      {
        accessorKey: 'duration',
        header: 'Duration (m)',
      },
      {
        accessorKey: 'created',
        cell: ({ row: { original } }: CellContext<Job, Job['createdAt']>) => {
          return (
            !!original.statusInfo &&
            !!original.statusInfo.created && (
              <DateWithUserDataCell
                data={original.statusInfo}
                date={original.createdAt}
                jobId={original.id}
                type="Created"
              />
            )
          );
        },
        header: 'Created',
        size: 80,
      },
      {
        accessorKey: 'start',
        cell: ({ row: { original } }) => {
          return (
            !!original.statusInfo &&
            !!original.statusInfo.started && (
              <DateWithUserDataCell
                data={original.statusInfo}
                date={original.startDate}
                jobId={original.id}
                type="Start"
              />
            )
          );
        },
        header: 'Start',
        size: 80,
      },
      {
        accessorKey: 'end',
        cell: ({ row: { original } }) => {
          return (
            !!original.statusInfo &&
            !!original.statusInfo.completed && (
              <DateWithUserDataCell
                data={original.statusInfo}
                date={original.endDate}
                jobId={original.id}
                type="End"
              />
            )
          );
        },
        header: 'End',
        size: 80,
      },
      {
        accessorKey: 'status',
        cell: ({ row: { original } }) => {
          return <JobStatusBadge {...original} />;
        },
        header: 'Status',
        size: 80,
      },
      {
        accessorKey: 'stockId',
        cell: ({ getValue, row }: CellContext<Job, Job['stockId']>) => {
          const { highPriority } = row.original;
          const stockId = getValue();

          return (
            <Conditional condition={!!stockId}>
              <Grid
                alignItems="center"
                columns="repeat(2, max-content)"
                gap=".5x"
              >
                <StyledAnchor
                  href={`${routePaths.stocks}/${stockId}`}
                  target="_blank"
                  text={`#${stockId}`}
                />

                {highPriority && (
                  <Tooltip
                    description="High-priority stock"
                    overlayContainerStyles={{
                      position: 'relative',
                      zIndex: 1,
                    }}
                    position="top"
                  >
                    <Icon name="arrows:arrow_triangle_up" />
                  </Tooltip>
                )}
              </Grid>
            </Conditional>
          );
        },
        header: 'Stock #',
        id: 'stockId',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 120,
      },
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        cell: (props: CellContext<Job, never>) => {
          const job = {
            ...props.row.original,
            step: jobSteps.print,
          };

          const disableJobActionButton = getDisableJobActionButton({
            job,
            machineStatus: currentMachine.status,
          });

          return (
            <Flex alignItems="center" gap="2x" justifyContent="flex-end">
              <JobActionButton
                disabled={disableJobActionButton}
                job={job}
                onRefresh={onRefresh}
              />
              <JobMenu
                disableStartOption={disableJobActionButton}
                job={job}
                onRefresh={onRefresh}
              />
            </Flex>
          );
        },
        id: 'actions',
        size: 30,
      },
    ];

    return columns;
  }, [currentMachine?.id, currentMachine?.status]);
};