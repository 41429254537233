import { useMemo } from 'react';
import {
  OrderStatus,
  orderStatusesMap,
  TransactionType,
  transactionTypesMap,
} from '../../../../constants/options';
import Protected from '../../../../components/common/protected/protected';
import { OrderActionProps } from './order-actions.types';
import { getOrderActionDisabledProps, shipmentRequirements } from './helper';
import { useMoreMenu } from '../../../../hooks/use-more-menu';
import { userRoles } from '../../../../constants/users';
import { getEditableStocksDetails } from '../change-items-modal/helpers/get-editable-stock-details';

export const OrderActionMenu = (props: OrderActionProps) => {
  const { optionActions, order } = props;

  const { shipments, status, type } = order || {};

  const data = {
    orderStatus: status as OrderStatus,
    orderType: type as TransactionType,
    shipments,
  };

  const nonTradeRefundOrderTypes = useMemo(() => {
    return Object.values(transactionTypesMap).filter((type) => {
      return type !== transactionTypesMap.tradeRefund;
    });
  }, []);

  const options = useMemo(() => {
    return [
      {
        allowedFor: [userRoles.admin],
        ...getOrderActionDisabledProps({
          data,
          enabledOrderTypes: [
            transactionTypesMap.designer,
            transactionTypesMap.marketing,
            transactionTypesMap.replacement,
            transactionTypesMap.rnd,
            transactionTypesMap.wholesale,
          ],
          enabledShipmentRequirements: [
            shipmentRequirements.hasInProductionShipment,
          ],
        }),
        name: 'Cancel items',
        onOptionClick: optionActions.cancelItems,
      },
      {
        allowedFor: [userRoles.admin],
        ...getOrderActionDisabledProps({
          data,
          enabledOrderStatuses: [
            orderStatusesMap.partiallyRefunded,
            orderStatusesMap.processed,
          ],
          enabledShipmentRequirements: [
            shipmentRequirements.hasInProductionShipment,
          ],
          validate: () => {
            const { hasEditableStocks } = getEditableStocksDetails(shipments);

            if (!hasEditableStocks) {
              return {
                disabled: true,
                disabledTooltipProps: {
                  description: 'Order does not contain editable stocks.',
                },
              };
            }
          },
        }),
        name: 'Change items',
        onOptionClick: optionActions.changeItems,
      },
      {
        allowedFor: [userRoles.admin],
        ...getOrderActionDisabledProps({
          data,
          enabledOrderTypes: [
            transactionTypesMap.order,
            transactionTypesMap.thirdParty,
            transactionTypesMap.trade,
            transactionTypesMap.wholesale,
          ],
          enabledShipmentRequirements: [
            shipmentRequirements.hasInProductionShipment,
          ],
        }),
        name: 'Create refund',
        onOptionClick: optionActions.createRefund,
      },
      {
        allowedFor: [userRoles.admin],
        ...getOrderActionDisabledProps({
          data,
          enabledOrderTypes: nonTradeRefundOrderTypes,
          enabledShipmentRequirements: [
            shipmentRequirements.hasShippedShipment,
          ],
        }),
        name: 'Create replacement',
        onOptionClick: optionActions.createReplacement,
      },
      {
        allowedFor: [userRoles.admin],
        ...getOrderActionDisabledProps({
          data,
          enabledOrderTypes: [
            transactionTypesMap.order,
            transactionTypesMap.thirdParty,
            transactionTypesMap.trade,
            transactionTypesMap.wholesale,
          ],
          enabledShipmentRequirements: [
            shipmentRequirements.hasDeliveredShipment,
          ],
        }),
        name: 'Create return',
        onOptionClick: optionActions.createReturn,
      },
      {
        allowedFor: [userRoles.admin],
        ...getOrderActionDisabledProps({
          data,
          enabledOrderTypes: [
            transactionTypesMap.designer,
            transactionTypesMap.marketing,
            transactionTypesMap.order,
            transactionTypesMap.rnd,
            transactionTypesMap.thirdParty,
            transactionTypesMap.trade,
            transactionTypesMap.tradeRefund,
          ],
          enabledShipmentRequirements: [
            shipmentRequirements.hasWaitingShipment,
          ],
        }),
        name: 'Re-assign stock',
        onOptionClick: optionActions.reassignStock,
      },
    ];
  }, [data]);

  const { MoreMenu } = useMoreMenu({
    data,
    options,
  });

  return (
    <Protected allowedFor={[userRoles.admin]}>
      <MoreMenu />
    </Protected>
  );
};
