import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { Checkbox, SearchField, Stack } from 'gantri-components';
import { FilterProps } from '../../../../components/dropdowns/index';
import DatePicker from '../../../../components/common/date-picker';
import { transactionsPageAtoms } from '../../statements-transactions.atoms';
import { OnDateChanged } from '../../../../components/common/date-picker/date-picker.types';
import { FilterResetLabel } from '../../../../components/common/filter-reset-label';
import { AtomsCheckboxList } from '../../../../components/common/atoms-checkbox-list';
import {
  FilterGrid,
  FilterRow,
} from '../../../../components/common/filter-grid';
import { statementCategory } from '../../../../api/statements/routes';
import { transactionTypesMap } from '../../../../constants/options';
import {
  FetchAllDesignersResponseDef,
  useFetchAllDesigners,
} from '../../../../api/designers/routes';
import { DesignerFilterRenderItem } from '../../../statements/components/statements-filter/components/designer-filter-render-item';

export const StatementsTransactionsFilters: FC<FilterProps> = () => {
  const [dateRange, setDateRange] = useRecoilState(
    transactionsPageAtoms.filters.dateRange,
  );
  const [designerName, setDesignerName] = useRecoilState(
    transactionsPageAtoms.filters.designerName,
  );
  const [designersActiveOnly, setDesignersActiveOnly] = useRecoilState(
    transactionsPageAtoms.filters.designersActiveOnly,
  );

  const onDateChange: OnDateChanged = ({ formattedFrom, formattedTo }) => {
    setDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const transactionsCategoryFilterItems = Object.values(statementCategory);
  const transactionsTypeFilterItems = [
    transactionTypesMap.order,
    transactionTypesMap.thirdParty,
    transactionTypesMap.trade,
    transactionTypesMap.wholesale,
  ];

  const { data, isLoading } =
    useFetchAllDesigners<FetchAllDesignersResponseDef>({
      transform: (response) => {
        const filteredDesigners = response.designers.filter(({ name }) => {
          return !!name;
        });

        if (designersActiveOnly) {
          const activeDesigners = filteredDesigners.filter(({ active }) => {
            return designersActiveOnly ? active : true;
          });

          return {
            ...response,
            designers: activeDesigners,
          } satisfies FetchAllDesignersResponseDef;
        }

        return {
          ...response,
          designers: filteredDesigners,
        } satisfies FetchAllDesignersResponseDef;
      },
      transformDependencies: [designersActiveOnly],
    });

  const designers = data?.designers || [];

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={transactionsPageAtoms.filters.dateRange}
          default={transactionsPageAtoms.defaults.filters.dateRange}
          text="Date range"
        />
        <DatePicker
          allowFutureDays
          initialValue={dateRange}
          range
          resetDates={transactionsPageAtoms.defaults.filters.dateRange}
          onDateChanged={onDateChange}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={transactionsPageAtoms.filters.designerName}
          default={transactionsPageAtoms.defaults.filters.designerName}
          text="Designer"
        />

        <Stack>
          <SearchField
            idProperty="id"
            items={designers}
            labelProperty="name"
            maxHeight="40rem"
            placeholder="Search designer"
            processing={isLoading}
            renderItem={DesignerFilterRenderItem}
            renderPlaceholder={DesignerFilterRenderItem}
            searchable
            value={designerName}
            onSelect={(item) => {
              setDesignerName(item?.name);
            }}
          />

          <Checkbox
            checked={designersActiveOnly}
            labelText="Active designers only"
            onSelected={setDesignersActiveOnly}
          />
        </Stack>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={transactionsPageAtoms.filters.categories}
          default={transactionsPageAtoms.defaults.filters.categories}
          text="Category"
        />
        <AtomsCheckboxList
          atom={transactionsPageAtoms.filters.categories}
          gridProps={{ columns: 2 }}
          items={transactionsCategoryFilterItems}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={transactionsPageAtoms.filters.types}
          default={transactionsPageAtoms.defaults.filters.types}
          text="Type"
        />
        <AtomsCheckboxList
          atom={transactionsPageAtoms.filters.types}
          gridProps={{ columns: 2 }}
          items={transactionsTypeFilterItems}
        />
      </FilterRow>
    </FilterGrid>
  );
};
