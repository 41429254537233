import { getFileUrl } from 'gantri-components';
import { StatementDef } from '../../../../../api/statements/routes';
import { cloudinaryUrl } from '../../../../../helpers/images';

export const getLogoUrl = (statement: StatementDef): string => {
  const logoUrl = getFileUrl({
    directory: 'designers',
    fileName: statement?.designer?.logoUrl,
    fileType: 'headshot',
    identifiers: {
      userId: statement?.designer?.userId,
    },
  });

  return cloudinaryUrl(logoUrl, {
    crop: 'limit',
    height: 80,
    width: 80,
  });
};
