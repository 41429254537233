import { UpdateGrowthMultiplierFormData } from '../../update-growth-multiplier-modal.types';
import { productCategories } from '../../../../../../../../api/products/products.constants';
import { useFetchGrowthMultiplierQuery } from '../../../../../../../../api/products/routes/fetch-growth-multiplier/fetch-growth-multiplier.query';

export const useUpdateGrowthMultiplierInitialValues = () => {
  const { growthMultipliers } = useFetchGrowthMultiplierQuery();

  const initialValues: UpdateGrowthMultiplierFormData = {
    accessory: 0,
    clampLight: growthMultipliers?.[productCategories.clampLight],
    floorLight: growthMultipliers?.[productCategories.floorLight],
    flushMount: growthMultipliers?.[productCategories.flushMount],
    pendantLight: growthMultipliers?.[productCategories.pendantLight],
    tableLight: growthMultipliers?.[productCategories.tableLight],
    wallLight: growthMultipliers?.[productCategories.wallLight],
    wallSconce: growthMultipliers?.[productCategories.wallSconce],
  };

  return initialValues;
};
