import styled, { css } from 'styled-components';
import { Grid, Typography } from 'gantri-components';

export const StyledBulletPointTypography = styled(Typography)`
  text-align: left;
  display: list-item;
  list-style-position: inside;
`;

export const StyledFooterButtonContainer = styled(Grid)<{
  isActiveWarning?: boolean;
}>`
  ${({ isActiveWarning, theme }) => {
    return css`
      border-top: ${isActiveWarning
        ? `1px solid ${theme.colors.dividers.t1}`
        : 'none'};

      padding-top: ${isActiveWarning ? '0.4rem' : '0'};
    `;
  }}
`;
