import {
  Button,
  Conditional,
  Flex,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { machinesApi } from '../../../../../../../../../../api';
import { MachineTasksFooterProps } from './machine-tasks-footer.types';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { startPrintPrintJobModalAtoms } from '../../../start-print-print-job-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { jobTypeOptions } from '../../../../../../../../../../constants/options';
import { spoolChangedTypes } from '../../../start-print-print-job-modal.constants';
import {
  useCompleteJob,
  useStartJob,
} from '../../../../../../../../../../api/jobs/routes';
import { StyledFooterButtonContainer } from '../../../../../start-job.modal.styles';
import { useChangeMachineSpool } from '../../../../../../../../../../api/machines/routes';

export const MachineTasksFooter = (props: MachineTasksFooterProps) => {
  const { handleCloseModal, job } = props;

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const assignedMachine = useRecoilValue(
    startPrintPrintJobModalAtoms.assignedMachine,
  );

  const buildPlateNeedsChanged = useRecoilValue(
    startPrintPrintJobModalAtoms.buildPlateNeedsChanged,
  );

  const buildPlateChanged = useRecoilValue(
    startPrintPrintJobModalAtoms.buildPlateChanged,
  );

  const [spoolChanged, setSpoolChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.spoolChanged,
  );

  const materialChanged = useRecoilValue(
    startPrintPrintJobModalAtoms.materialChanged,
  );

  const inventoryRequests = useRecoilValue(
    startPrintPrintJobModalAtoms.inventoryRequests,
  );

  const setStep = useSetRecoilState(startPrintPrintJobModalAtoms.step);

  const { onStartJob } = useStartJob();

  const { onCompleteJob } = useCompleteJob();

  const { onChangeSpool } = useChangeMachineSpool();

  const handleUpdateAndStartJob = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        if (buildPlateChanged) {
          await machinesApi.resetJobCountdown(assignedMachine);
        }

        if (spoolChanged !== spoolChangedTypes.noChange) {
          await onChangeSpool({
            addedType:
              spoolChanged === spoolChangedTypes.yesNew ? 'new' : 'used',
            machineIds: [assignedMachine],
            material: materialChanged,
          });
        }

        await onStartJob({
          jobId: job.id,
          machineId: assignedMachine,
        });

        if (job.hasPrintHarvest) {
          // ? See `hasPrintHarvest` JSDoc comment for functionality explanation
          await onCompleteJob({ jobId: job.id });
        }

        await handleCloseModal({ updateOnClose: true });
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to start job.',
        });
      }
    });
  };

  const buildPlateNotYetChanged = buildPlateNeedsChanged && !buildPlateChanged;
  const needToHaveSameMaterial =
    spoolChanged === spoolChangedTypes.yesNew ||
    spoolChanged === spoolChangedTypes.yesUsed;

  const hasSameMaterial =
    !needToHaveSameMaterial ||
    (needToHaveSameMaterial &&
      materialChanged === (job.part?.material || job.material));

  const spoolChangeInventoryNotSelected =
    spoolChanged === spoolChangedTypes.yesNew && !inventoryRequests.length;

  const materialNotYetChanged =
    spoolChanged === spoolChangedTypes.yesUsed && !materialChanged;

  const disableStart =
    !spoolChanged ||
    buildPlateNotYetChanged ||
    spoolChangeInventoryNotSelected ||
    materialNotYetChanged ||
    !hasSameMaterial;

  return (
    <Stack gap="unset">
      <Conditional condition={!hasSameMaterial}>
        <Flex gap="x" horizontalPadding="3x" verticalPadding="2x">
          <Icon color="warning" name="alert:warning_triangle" size="3rem" />

          <Typography text="The machine’s material differs from the material in the part template. Select another material or machine to continue." />
        </Flex>
      </Conditional>

      <StyledFooterButtonContainer
        columns={2}
        gap=".5x"
        isActiveWarning={!hasSameMaterial}
      >
        <Button
          size={fullScreenModalFooterButtonSize}
          text="Back"
          variant="secondary"
          onClick={() => {
            setSpoolChanged(null);
            setStep('ASSIGN_MACHINE');
          }}
        />
        <Button
          disabled={disableStart}
          processing={processing}
          size={fullScreenModalFooterButtonSize}
          text={`Confirm ${jobTypeOptions.print} ${
            job.hasPrintHarvest ? 'Completion' : 'Start'
          }`}
          onClick={handleUpdateAndStartJob}
        />
      </StyledFooterButtonContainer>
    </Stack>
  );
};
