import { Grid, Modal, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { SectionTitle } from '../../../../../../../../../../components/layout/section-title';
import { productAtoms } from '../../../../../../../../product.atoms';
import { useInvalidateFetchProductCache } from '../../../../../../../../../../api/products/routes';
import { productCategories } from '../../../../../../../../../../api/products/products.constants';
import { useUpdateCutSheet } from '../../../../../../../../../../api/products/routes/update-cut-sheet';
import { CutSheetPreview } from './components/cut-sheet-preview';
import { CutSheetLifestylePhotos } from './components/cut-sheet-lifestyle-photos';
import { CutSheetLineDrawings } from './components/cut-sheet-line-drawings';
import {
  ConfigureCutSheetFormSchemaDef,
  ConfigureCutSheetsModalPropsDef,
} from './configure-cut-sheets-modal.types';
import { configureCutSheetFormSchema } from './configure-cut-sheets-modal.schema';
import { ConfigureCutSheetsModalFooter } from './components/configure-cut-sheet-modal-footer';
import { configureCutSheetsModalAtoms } from './configure-cut-sheets-modal.atoms';

export const ConfigureCutSheetsModal = (
  props: ConfigureCutSheetsModalPropsDef,
) => {
  const { onClose } = props;

  const product = useRecoilValue(productAtoms.product);

  const processingUpload = useRecoilValue(
    configureCutSheetsModalAtoms.processingUpload,
  );

  const requires2LifestylePhotos = useMemo(() => {
    return [productCategories.wallSconce, productCategories.flushMount].some(
      (requiredCategory) => {
        return requiredCategory === product.category;
      },
    );
  }, [product.category]);

  const configureCutSheetInitialValues: ConfigureCutSheetFormSchemaDef =
    useMemo(() => {
      return {
        closeable: true,
        firstLifestyleViewPhoto:
          product.downloads?.cutSheet?.data?.firstLifestyleViewPhoto || null,
        frontViewPhoto:
          product.downloads?.cutSheet?.data?.frontViewPhoto || null,
        productCategory: product.category,
        requires2LifestylePhotos,
        secondLifestyleViewPhoto:
          product.downloads?.cutSheet?.data?.secondLifestyleViewPhoto || null,
        sideViewPhoto: product.downloads?.cutSheet?.data?.sideViewPhoto || null,
      };
    }, [product.downloads?.cutSheet?.data, requires2LifestylePhotos]);

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { isLoading: isSaving, onUpdateCutSheet } = useUpdateCutSheet({
    onSuccess: async () => {
      onClose();

      setTimeout(async () => {
        // Adds small timeout to ensure modal is closed before cache is busted
        await invalidateFetchProductCache();
      }, 100);
    },
  });

  const onSubmit = async (values: ConfigureCutSheetFormSchemaDef) => {
    await onUpdateCutSheet({ ...values, productId: product.id });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={configureCutSheetInitialValues}
      validateOnChange
      validateOnMount
      validationSchema={configureCutSheetFormSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <Modal
              closeable={processingUpload ? false : values.closeable}
              footer={
                <ConfigureCutSheetsModalFooter
                  isSaving={isSaving}
                  onClose={onClose}
                />
              }
              header="Configure Cut Sheet"
              height={{ lg: '60rem', md: '100%' }}
              maxWidth={{ lg: '63.6rem', md: '100%' }}
              onClose={onClose}
            >
              <Stack gap="5x">
                <CutSheetLineDrawings />

                <Stack gap="2x">
                  <SectionTitle text="Select lifestyle image(s)" variant="h5" />

                  <Grid columns={{ lg: '1fr max-content', sm: 1 }}>
                    <CutSheetLifestylePhotos />

                    <CutSheetPreview />
                  </Grid>
                </Stack>
              </Stack>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
