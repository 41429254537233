import {
  Box,
  Conditional,
  Icon,
  Radio,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMemo } from 'react';
import { startPrintPrintJobModalAtoms } from '../../../../../start-print-print-job-modal.atoms';
import { getInventoryRequestsForInventory } from './helpers/get-inventory-requests-for-inventory';
import { NewSpoolInventoryPropsDef } from './new-spool-inventory.types';
import { Inventory } from '../../../../../../../../../../../../api/inventories/routes/fetch-all/fetch-all-inventories.types';
import {
  isOpaqueMaterial,
  materials,
} from '../../../../../../../../../../../../constants/parts';

export const NewSpoolInventory = (props: NewSpoolInventoryPropsDef) => {
  const { isLoading, material, materialInventories } = props;
  const isOpaque = isOpaqueMaterial(material);
  const setInventoryRequests = useSetRecoilState(
    startPrintPrintJobModalAtoms.inventoryRequests,
  );
  const [materialChanged, setMaterialChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.materialChanged,
  );

  const spoolInventoryDetails = useRecoilValue(
    startPrintPrintJobModalAtoms.spoolInventoryDetails,
  );

  const inventoryItems = useMemo(() => {
    if (materialInventories) {
      // ? indexed in display order
      const inventoryIdsToShow = isOpaque
        ? [
            spoolInventoryDetails[materials.polymakerOpaque].id,
            spoolInventoryDetails[materials.fabbOpaque].id,
          ]
        : [
            spoolInventoryDetails[materials.polymakerTranslucent].id,
            spoolInventoryDetails[materials.fabbTranslucent].id,
          ];

      return inventoryIdsToShow.map((idToShow) => {
        const matchingInventory = materialInventories.find(({ id }) => {
          return id === idToShow;
        });

        const { description, material } = Object.values(
          spoolInventoryDetails,
        ).find(({ id }) => {
          return id === idToShow;
        });

        const updatedInventory: Inventory = {
          ...matchingInventory,
          description,
          material,
        };

        return updatedInventory;
      });
    }

    return [];
  }, [materialInventories]);

  const onSelected = (id: string) => {
    const inventory = inventoryItems.find((inventory) => {
      return String(inventory.id) === id;
    });

    const inventoryRequests = getInventoryRequestsForInventory(
      inventory,
      spoolInventoryDetails?.[inventory.material]?.need || 0,
    );

    setInventoryRequests(inventoryRequests);

    setMaterialChanged(inventory.material);
  };

  return (
    <Stack gap=".5x">
      <Typography text="Select material added:" variant="h6" />

      <Stack gap="x">
        <Conditional
          key="is-loading-inventories"
          condition={isLoading}
          Fallback={
            <>
              <Typography
                text={`A transaction of ${spoolInventoryDetails[material].need}kg of inventory will be recorded. Select material:`}
              />

              {inventoryItems.map((inventory) => {
                const { id, material, name } = inventory;

                // ? The inventory request only has one index
                const value = material === materialChanged ? id : null;

                return (
                  <Radio
                    key={id}
                    groupValue={id}
                    name={name}
                    value={value}
                    onSelected={onSelected}
                  >
                    <Box>
                      <Typography display="inline" text={material} />
                      &nbsp;
                      <Typography
                        color="t2"
                        display="inline"
                        text={`- #${id}: ${name}`}
                      />
                    </Box>
                  </Radio>
                );
              })}
            </>
          }
        >
          <Icon color="link" name="animated:loader" size="2.4rem" />
        </Conditional>
      </Stack>
    </Stack>
  );
};
