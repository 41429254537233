import {
  Checkbox,
  Conditional,
  Radio,
  RadioList,
  Stack,
  Typography,
} from 'gantri-components';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFetchAllInventories } from '../../../../../../../../../../api/inventories/routes/fetch-all';
import { JobDetails } from '../../../../../../../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { startPrintPrintJobModalAtoms } from '../../../start-print-print-job-modal.atoms';
import { spoolChangedTypes } from '../../../start-print-print-job-modal.constants';
import { NewSpoolInventory } from './components/new-spool-inventory';
import { getInventoryRequestsForInventory } from './components/new-spool-inventory/helpers/get-inventory-requests-for-inventory';
import { spoolChangeOptions } from './machine-tasks-content.constants';
import {
  getMaterialsByOpacity,
  isOpaqueMaterial,
  materials,
} from '../../../../../../../../../../constants/parts';
import { SpoolChanged } from '../../../start-print-print-job-modal.types';

export const MachineTasksContent = (props: { job: JobDetails }) => {
  const { job } = props;

  const { material } = job.part;
  const isOpaque = isOpaqueMaterial(material);
  const materialOptions = useMemo(() => {
    return getMaterialsByOpacity(material);
  }, [material]);
  const { data, isLoading } = useFetchAllInventories();

  const buildPlateNeedsChanged = useRecoilValue(
    startPrintPrintJobModalAtoms.buildPlateNeedsChanged,
  );
  const [buildPlateChanged, setBuildPlateChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.buildPlateChanged,
  );
  const [spoolChanged, setSpoolChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.spoolChanged,
  );
  const [materialChanged, setMaterialChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.materialChanged,
  );
  const setInventoryRequests = useSetRecoilState(
    startPrintPrintJobModalAtoms.inventoryRequests,
  );
  const spoolInventoryDetails = useRecoilValue(
    startPrintPrintJobModalAtoms.spoolInventoryDetails,
  );

  const assignedMachineInstance = useRecoilValue(
    startPrintPrintJobModalAtoms.assignedMachineInstance,
  );

  const { spoolInventoryOpaqueIds, spoolInventoryTraslucentIds } =
    useMemo(() => {
      if (!Object.keys(spoolInventoryDetails).length)
        return {
          spoolInventoryOpaqueIds: [],
          spoolInventoryTraslucentIds: [],
        };

      return {
        spoolInventoryOpaqueIds: [
          spoolInventoryDetails[materials.fabbOpaque].id,
          spoolInventoryDetails[materials.polymakerOpaque].id,
        ],
        spoolInventoryTraslucentIds: [
          spoolInventoryDetails[materials.fabbTranslucent].id,
          spoolInventoryDetails[materials.polymakerTranslucent].id,
        ],
      };
    }, [spoolInventoryDetails]);

  const isNewSpool = spoolChanged === spoolChangedTypes.yesNew;
  const isUsedSpool = spoolChanged === spoolChangedTypes.yesUsed;
  const materialInventories = data?.inventories || [];
  const need = spoolInventoryDetails?.[material]?.need || 0;

  useEffect(() => {
    if (isNewSpool && materialInventories) {
      if (isOpaque) {
        const opaqueSandableInventory = materialInventories.find(({ id }) => {
          return spoolInventoryOpaqueIds.includes(id);
        });
        const inventoryRequests = getInventoryRequestsForInventory(
          opaqueSandableInventory,
          need,
        );

        setInventoryRequests(inventoryRequests);
      } else {
        const translucentInventory = materialInventories.find(({ id }) => {
          return spoolInventoryTraslucentIds.includes(id);
        });
        const inventoryRequests = getInventoryRequestsForInventory(
          translucentInventory,
          need,
        );

        setInventoryRequests(inventoryRequests);
      }
    } else {
      setInventoryRequests([]);
    }
  }, [isNewSpool, materialInventories]);

  const options = useMemo(() => {
    return spoolChangeOptions.map((option) => {
      const disabled =
        option.groupValue === spoolChangedTypes.noChange &&
        job.part?.material !== assignedMachineInstance?.material;

      return {
        ...option,
        disabled,
      };
    });
  }, [spoolChanged]);

  return (
    <Stack gap="4x">
      <ModalContentHeading titleText="Machine tasks" />
      <Stack gap="5x">
        <Conditional condition={buildPlateNeedsChanged}>
          <Stack gap="x">
            <Typography text="Change build plate" variant="h5" />
            <Typography text="This machine has completed 20 prints. Please change build plate to ensure print adhesion." />
            <Checkbox
              aria-label="Build plate has been changed."
              checked={buildPlateChanged}
              labelText="Build plate has been changed."
              onSelected={setBuildPlateChanged}
            />
          </Stack>
        </Conditional>

        <Stack gap="x">
          <Typography text="Spool Change" variant="h5" />
          <Typography text="Did you perform a spool change to start print on this machine?" />

          <RadioList
            idProperty="groupValue"
            items={options}
            showAsTiles
            value={spoolChanged}
            onSelected={(value: SpoolChanged) => {
              setSpoolChanged(value);
            }}
          />
        </Stack>

        <Conditional key="is-new-spool" condition={isNewSpool}>
          <NewSpoolInventory
            isLoading={isLoading}
            material={material}
            materialInventories={materialInventories}
          />
        </Conditional>

        <Conditional condition={isUsedSpool}>
          <Stack gap="x">
            <Typography text="Select material added:" textStyle="bold" />
            {materialOptions.map((materialType) => {
              return (
                <Radio
                  key={materialType}
                  groupValue={materialType}
                  labelText={materialType}
                  name={materialType}
                  value={materialChanged}
                  onSelected={setMaterialChanged}
                />
              );
            })}
          </Stack>
        </Conditional>
      </Stack>
    </Stack>
  );
};
