import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  FetchMachinesOverviewArgs,
  FetchMachinesOverviewResponse,
} from './fetch-machines-overview.types';

export const fetchMachinesOverview = (args: FetchMachinesOverviewArgs) => {
  const { locations = [] } = args;
  const queries = convertQueriesToString({
    filter: JSON.stringify({ locations }),
  });

  return axios
    .get<FetchMachinesOverviewResponse>(
      `${getCoreApiUrl('machines')}/locations-overview${queries}`,
    )
    .then(({ data }) => {
      return data;
    });
};
