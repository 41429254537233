import styled from 'styled-components';
import { media } from 'gantri-components';

export const StyledShipmentTransactionContainer = styled.div`
  border: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
  border-top: none;
  background-color: ${(props) => {
    return props.theme.colors.surfaces.monochrome.t1;
  }};
  padding: 2.4rem;
  display: flex;
  justify-content: space-between;

  ${media.lessThan('sm')`
     padding: 1.6rem;
  `};
`;

export const StyledShipmentTransactionInfoContainer = styled.div`
  display: flex;
  min-width: 60%;
  gap: 1rem;
`;

export const StyledShipmentStockInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledRefundReasonContainer = styled.div`
  padding-top: 20px;
  max-width: 350px;
`;

export const StyledGiftMessageContainer = styled.div`
  display: grid;
  row-gap: 20px;
  padding-top: 20px;
  grid-template-columns: minmax(0, 1fr);

  p {
    white-space: pre-line;
    overflow-wrap: anywhere;
  }
`;
