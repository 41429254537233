import { DateRangeFilter } from '../../../../components/common/table/components/table-filters/table-filters.types';
import { jobSteps } from '../../../../constants/options';
import {
  MachineRepairIssueType,
  Material,
} from '../../../../constants/options/options.types';
import { Job } from '../../jobs.types';

// Statuses are in order of appearance in filters modal
export const userStatusOptions = [
  'Created',
  'Started',
  'Completed',
  'Failed',
] as const;

export type UserStatus = typeof userStatusOptions[number];

export interface GetPaginatedJobsArgs {
  assignedTo?: number[];
  attentionOnly?: boolean;
  colors?: unknown[];
  completedDateRange?: DateRangeFilter;
  count?: number;
  createdDateRange?: DateRangeFilter;
  isCsv?: boolean;
  late?: boolean;
  locations?: unknown[];
  machineIssues?: MachineRepairIssueType[];
  machineTypes?: unknown[];
  materials?: Material[];
  orderId?: number;
  page?: number;
  productId?: number;
  /** Added to the end of the request URL. Currently only used to differentiate requests in the network tab. */
  queries?: Record<string, string | number | boolean>;
  search?: string;
  sortBy?: string;
  statuses?: unknown[];
  steps?: string[];
  types?: unknown[];
  userIds?: number[];
  userStatuses?: UserStatus[];
}

export interface GetPaginatedJobsResponse {
  error?: string;
  jobs: Job[];
  maxPages: number;
  page: number;
  productNames: ProductName[];
  steps: Step[];
  success: boolean;
  totalJobs: number;
  userNames: UserName[];
}

export const stepOptions = Object.values(jobSteps);

export type Step = typeof stepOptions[number];

export interface ProductName {
  id: number;
  name: string;
}

export interface UserName {
  id: number;
  name: string;
  type?: string;
}
