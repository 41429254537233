import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import {
  FetchMachineLocationsArgsDef,
  FetchMachineLocationsResponseDef,
} from './fetch-machine-locations.types';
import { fetchMachineLocations } from './fetch-machine-locations';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';

const fetchMachineLocationsQueriesObj = getGenericQueryObj({
  groupName: 'fetch-machine-details-panel',
  query: fetchMachineLocations,
});

export const useFetchMachineLocations = <
  TransformedData = FetchMachineLocationsResponseDef,
>(
  props?: GenericFetchProps<
    FetchMachineLocationsArgsDef,
    FetchMachineLocationsResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch machine locations.',
    queryObj: fetchMachineLocationsQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchMachineLocationsCache = () => {
  const invalidateFetchMachineLocationsCache = useGetInvalidateQueryCache(
    fetchMachineLocationsQueriesObj,
  );

  return { invalidateFetchMachineLocationsCache };
};
