import styled, { css, CSSProperties } from 'styled-components';
import { Grid, media } from 'gantri-components';
import { BreadCrumbs } from './components/breadcrumbs';

export const StyledPageHeadingWrapper = styled.header<{
  paddingBottom?: CSSProperties['paddingBottom'];
}>`
  ${({ paddingBottom, theme }) => {
    return css`
      width: 100%;
      padding-bottom: ${paddingBottom};
      border-bottom: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;

export const StyledPageHeadingGrid = styled(Grid)`
  min-height: 8rem;

  ${media.lessThan('lg')`
    min-height: 7.8rem;
  `}
`;

export const StyledDesktopBreadcrumbs = styled(BreadCrumbs)`
  ${media.lessThan('md')`
    display: none;
  `}
`;
