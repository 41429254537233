import { SecondarySurfaceSwatchProps } from './secondary-surface-swatch.types';
import { isOpaqueMaterial } from '../../../../../../constants/parts';

export const SecondarySurfaceSwatch = (props: SecondarySurfaceSwatchProps) => {
  const { material, size = '2.6rem' } = props;
  const fill = isOpaqueMaterial(material) ? '#6FED6F' : '#4ebffc';

  return (
    <svg
      fill="none"
      height={size}
      role="img"
      viewBox="3 2 26 26"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill={fill} height="26" rx="1.25" width="26" x="3" y="2" />
      <circle cx="11.7792" cy="11.4138" fill="white" r="1.05875" />
      <circle cx="11.7792" cy="15.3462" fill="white" r="1.05875" />
      <circle cx="11.7792" cy="19.2786" fill="white" r="1.05875" />
      <circle cx="11.7792" cy="23.2116" fill="white" r="1.05875" />
      <circle cx="11.7792" cy="3.5491" fill="white" r="1.05875" />
      <circle cx="11.7792" cy="7.48148" fill="white" r="1.05875" />
      <circle cx="11.7792" cy="27.1433" fill="white" r="1.05875" />
      <circle cx="15.7121" cy="3.5491" fill="white" r="1.05875" />
      <circle cx="15.7121" cy="11.4138" fill="white" r="1.05875" />
      <circle cx="15.7121" cy="15.3462" fill="white" r="1.05875" />
      <circle cx="15.7121" cy="19.2786" fill="white" r="1.05875" />
      <circle cx="15.7121" cy="23.2116" fill="white" r="1.05875" />
      <circle cx="19.6451" cy="11.4138" fill="white" r="1.05875" />
      <circle cx="19.6451" cy="15.3462" fill="white" r="1.05875" />
      <circle cx="19.6451" cy="19.2786" fill="white" r="1.05875" />
      <circle cx="19.6451" cy="23.2116" fill="white" r="1.05875" />
      <circle cx="19.6451" cy="3.5491" fill="white" r="1.05875" />
      <circle cx="15.7121" cy="7.48148" fill="white" r="1.05875" />
      <circle cx="15.7121" cy="27.1433" fill="white" r="1.05875" />
      <circle cx="23.5757" cy="11.4138" fill="white" r="1.05875" />
      <circle cx="23.5757" cy="15.3462" fill="white" r="1.05875" />
      <circle cx="23.5757" cy="19.2786" fill="white" r="1.05875" />
      <circle cx="23.5757" cy="23.2116" fill="white" r="1.05875" />
      <circle cx="23.5757" cy="3.5491" fill="white" r="1.05875" />
      <circle cx="27.5087" cy="11.4138" fill="white" r="1.05875" />
      <circle cx="27.5087" cy="15.3462" fill="white" r="1.05875" />
      <circle cx="27.5087" cy="19.2786" fill="white" r="1.05875" />
      <circle cx="27.5087" cy="23.2116" fill="white" r="1.05875" />
      <circle cx="27.5087" cy="3.5491" fill="white" r="1.05875" />
      <circle cx="19.6451" cy="7.48148" fill="white" r="1.05875" />
      <circle cx="19.6451" cy="27.1433" fill="white" r="1.05875" />
      <circle cx="23.5757" cy="7.48148" fill="white" r="1.05875" />
      <circle cx="23.5757" cy="27.1433" fill="white" r="1.05875" />
      <circle cx="27.5087" cy="7.48148" fill="white" r="1.05875" />
      <circle cx="27.5087" cy="27.1433" fill="white" r="1.05875" />
      <circle cx="3.91324" cy="11.4138" fill="white" r="1.05875" />
      <circle cx="3.91324" cy="15.3462" fill="white" r="1.05875" />
      <circle cx="3.91324" cy="19.2786" fill="white" r="1.05875" />
      <circle cx="3.91324" cy="23.2116" fill="white" r="1.05875" />
      <circle cx="3.91324" cy="3.5491" fill="white" r="1.05875" />
      <circle cx="7.84622" cy="11.4138" fill="white" r="1.05875" />
      <circle cx="7.84622" cy="15.3462" fill="white" r="1.05875" />
      <circle cx="7.84622" cy="19.2786" fill="white" r="1.05875" />
      <circle cx="7.84622" cy="23.2116" fill="white" r="1.05875" />
      <circle cx="7.84622" cy="3.5491" fill="white" r="1.05875" />
      <circle cx="3.91324" cy="7.48148" fill="white" r="1.05875" />
      <circle cx="3.91324" cy="27.1433" fill="white" r="1.05875" />
      <circle cx="7.84622" cy="7.48148" fill="white" r="1.05875" />
      <circle cx="7.84622" cy="27.1433" fill="white" r="1.05875" />
    </svg>
  );
};
