import styled, { css } from 'styled-components';
import { ShortProductSummary } from '../../common/short-product-summary';

export const StyledShortProductSummary = styled(ShortProductSummary)`
  ${({ containerProps }) => {
    return css`
      cursor: ${containerProps?.onClick ? 'pointer' : 'not-allowed'};
    `;
  }}
`;
