import styled, { css } from 'styled-components';
import { ShortProductSummary } from '../../../../components/common/short-product-summary';

export const StyledShortProductSummary = styled(ShortProductSummary)<{
  disabled: boolean;
}>`
  ${({ disabled }) => {
    return css`
      cursor: ${disabled ? 'not-allowed' : 'auto'};
    `;
  }}
`;
