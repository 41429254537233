import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  GetStatementDownloadUrlArgsDef,
  GetStatementUrlResponseDef,
} from './get-statement-download-url.types';

export const getStatementDownloadUrl = ({
  id,
}: GetStatementDownloadUrlArgsDef) => {
  return axios
    .get<GetStatementUrlResponseDef>(`${getCoreApiUrl('statements')}/${id}/pdf`)
    .then(({ data }) => {
      return data;
    });
};
