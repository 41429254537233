import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { DeleteIssueResponse } from './delete-issue.type';

export const deleteIssue = (id: number) => {
  return axios
    .delete<DeleteIssueResponse>(`${getCoreApiUrl('issues')}/${id}/remove`)
    .then((response) => {
      return response.data;
    });
};
