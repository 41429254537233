import { MachineStatus, machineStatuses } from '../../constants/machines';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';

const machineInProgressCheckStatus =
  `${machineStatuses.inProgress} - Check` as const;

const {
  archived: _archived,
  inProgress,
  ...visibleMachineStatuses
} = machineStatuses;

export const visibleMachineStatusOptions = {
  inProgress,
  inProgressCheck: machineInProgressCheckStatus,
  ...visibleMachineStatuses,
};

export type VisibleMachineStatus =
  | MachineStatus
  | typeof machineInProgressCheckStatus;

export const actionOnlyViewVisibleStatuses = [
  visibleMachineStatusOptions.inProgressCheck,
  visibleMachineStatusOptions.offline,
  visibleMachineStatusOptions.stopped,
] satisfies VisibleMachineStatus[];

export const machinesOverviewFiltersDefaults: {
  filters: {
    locations: string[];
    visibleStatuses: VisibleMachineStatus[];
  };
} = {
  filters: {
    locations: ['C-Aisle - Polymaker', 'D-Even', 'D-Odd', 'E-Even', 'E-Odd'],
    visibleStatuses: actionOnlyViewVisibleStatuses,
  },
};

const atomKeyPrefix = 'MACHINES_OVERVIEW_FILTERS';

export const pageName: AtomPageName = 'machines-overview';

export const machinesOverviewPageAtoms = {
  defaults: machinesOverviewFiltersDefaults,
  filters: {
    locations: getGenericAtomFamily({
      defaultValue: machinesOverviewFiltersDefaults.filters.locations,
      key: `${atomKeyPrefix}-locations`,
    })(pageName),
    visibleStatuses: getGenericAtomFamily({
      defaultValue: machinesOverviewFiltersDefaults.filters.visibleStatuses,
      key: `${atomKeyPrefix}-visible-statuses`,
    })(pageName),
  },
};
