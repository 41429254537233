import {
  Box,
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Link } from 'react-router-dom';
import { jobStatuses, locationStatuses } from '../../../../constants/options';
import { StyledFieldIconWrapper } from '../custom-data-cells-styles';
import { formatDate } from '../../../../helpers/formatter';
import { prepareFailureReasons } from '../../../../helpers/fail-reason';
import { LONG_FORMAT } from '../../../../constants/dates';
import { Job } from '../../../../api/jobs/jobs.types';
import { RejectedStatus } from './components/rejected-status';
import { StatusBadge } from '../../../status-badge';
import { statusBadgeThemes } from '../../../status-badge/status-badge.constants';

interface StatusDataCellProps
  extends Partial<Pick<Job, 'failedReason' | 'id' | 'status'>> {
  hideTooltip?: boolean;
}

export const JobStatusBadge = (props: StatusDataCellProps) => {
  const { failedReason, hideTooltip, id, status } = props;

  switch (status) {
    case jobStatuses.waiting: {
      return <StatusBadge text={status} theme={statusBadgeThemes.blueLight} />;
    }

    case jobStatuses.completed: {
      return (
        <StatusBadge
          icon="ui-control:check_mark"
          text={status}
          theme={statusBadgeThemes.greenDark}
        />
      );
    }

    case jobStatuses.rejected: {
      return (
        <RejectedStatus hideTooltip={hideTooltip} id={id} status={status} />
      );
    }

    case jobStatuses.failed: {
      const failureReasons = failedReason
        ? prepareFailureReasons(failedReason)
        : [];

      const showTooltip =
        !!failureReasons.length ||
        !!failedReason?.failedAt ||
        !!failedReason?.userId;

      return (
        <Flex alignItems="flex-start">
          <StatusBadge
            icon="alert:warning_triangle"
            text={status}
            theme={statusBadgeThemes.redLight}
          />

          <Conditional condition={showTooltip}>
            <Tooltip
              Component={
                <Box horizontalPadding="1.6rem" verticalPadding="1rem">
                  {failureReasons.map(({ code, label }) => {
                    return <Typography key={code} color="alert" text={label} />;
                  })}
                  <Typography
                    display="inline"
                    text={formatDate(failedReason?.failedAt, LONG_FORMAT)}
                  />
                  <Conditional condition={!!failedReason.userId}>
                    <Typography display="inline" text=" by " />
                    <Link to={`/users/${failedReason.userId}`}>
                      <Typography
                        color="link"
                        decoration="underline"
                        display="inline"
                        text={failedReason.userName}
                      />
                    </Link>
                  </Conditional>
                </Box>
              }
              position="right"
            >
              <StyledFieldIconWrapper>
                <Icon name="alert:i_circle" top="3px" />
              </StyledFieldIconWrapper>
            </Tooltip>
          </Conditional>
        </Flex>
      );
    }

    case jobStatuses.cancelled:
    case locationStatuses.inactive:
      return (
        <StatusBadge text={status} theme={statusBadgeThemes.monochromeLight} />
      );

    case jobStatuses.inProgress:
      return (
        <StatusBadge
          icon="time:clock"
          text={status}
          theme={statusBadgeThemes.yellowLight}
        />
      );
    case jobStatuses.ready:
      return (
        <StatusBadge
          icon="actions:thumbs_up"
          text={status}
          theme={statusBadgeThemes.greenLight}
        />
      );

    case locationStatuses.active: {
      return <StatusBadge text={status} theme={statusBadgeThemes.greenLight} />;
    }

    default:
      return <StatusBadge text={status} theme={statusBadgeThemes.redLight} />;
  }
};
