import { Form, Formik } from 'formik';
import { Modal } from 'gantri-components';
import { UpdateGrowthMultiplierModalContent } from './components/update-growth-multiplier-modal-content';
import {
  UpdateGrowthMultiplierFormData,
  UpdateGrowthMultiplierModalProps,
} from './update-growth-multiplier-modal.types';
import { updateGrowthMultiplierSchema } from './update-growth-multiplier-modal.schema';
import { UpdateGrowthMultiplierModalFooter } from './components/update-growth-multiplier-modal-footer';
import { useSetGrowthMultiplier } from '../../../../../../api/products/routes/set-growth-multiplier';
import { productCategories } from '../../../../../../api/products/products.constants';
import { useInvalidateFetchGrowthMultiplierCache } from '../../../../../../api/products/routes/fetch-growth-multiplier/fetch-growth-multiplier.query';
import { useUpdateGrowthMultiplierInitialValues } from './hooks/use-growth-multiplier-initial-values';

export const UpdateGrowthMultiplierModal = (
  props: UpdateGrowthMultiplierModalProps,
) => {
  const { onClose } = props;

  const initialValues = useUpdateGrowthMultiplierInitialValues();

  const { invalidateFetchGrowthMultiplierCache } =
    useInvalidateFetchGrowthMultiplierCache();

  const { isLoading, onSetGrowthMultiplier } = useSetGrowthMultiplier({
    onSuccess: async () => {
      onClose();

      // timeout to prevent immediate re-fetch of growth multipliers from cache bust
      setTimeout(async () => {
        await invalidateFetchGrowthMultiplierCache();
      }, 500);
    },
  });

  const handleUpdateGrowthMultiplier = async (
    values: UpdateGrowthMultiplierFormData,
  ) => {
    await onSetGrowthMultiplier({
      [productCategories.accessory]: 0,
      [productCategories.flushMount]: values.flushMount,
      [productCategories.clampLight]: values.clampLight,
      [productCategories.floorLight]: values.floorLight,
      [productCategories.pendantLight]: values.pendantLight,
      [productCategories.tableLight]: values.tableLight,
      [productCategories.wallLight]: values.wallLight,
      [productCategories.wallSconce]: values.wallSconce,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      isInitialValid={false}
      validateOnChange
      validateOnMount
      validationSchema={updateGrowthMultiplierSchema}
      onSubmit={handleUpdateGrowthMultiplier}
    >
      <Form>
        <Modal
          footer={
            <UpdateGrowthMultiplierModalFooter
              processing={isLoading}
              onClose={onClose}
            />
          }
          header="Update Growth Multiplier"
          width={{ lg: '38.4rem', md: '100%' }}
          onClose={onClose}
        >
          <UpdateGrowthMultiplierModalContent />
        </Modal>
      </Form>
    </Formik>
  );
};
