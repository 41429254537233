import { Box, Conditional } from 'gantri-components';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useSyncPropertyWithQueryString } from '../../hooks/useSyncPropertyWithQueryString';
import { updateActiveJobRows } from '../../pages/jobs/helpers';
import { desktopDetailsPanelWidth } from '../common/details-panel/details-panel.styles';
import { JobsDetailsPanel } from './components/jobs-details-panel';
import { jobsDetailsPanelAtoms } from './components/jobs-details-panel/jobs-details-panel.atoms';
import { jobsDetailsPanelWrapperDefaultProps } from './jobs-details-panel-wrapper.presets';
import { JobsDetailsPanelWrapperProps } from './jobs-details-panel-wrapper.types';

export const jobsDetailsPanelIdQueryKey = 'detailsPanelJobId';

/** Wrap any Table with this component to utilize the JobsDetailsPanel. */
export const JobsDetailsPanelWrapper = <TData extends Record<'id', number>>(
  props: PropsWithChildren<JobsDetailsPanelWrapperProps<TData>>,
) => {
  const { children, idsMap, machineStatus, onRefresh, records, userNames } =
    props;

  const {
    currentValue: jobIdQueryValue,
    updateQueryString: updateJobIdQueryValue,
  } = useSyncPropertyWithQueryString<number>(
    jobsDetailsPanelIdQueryKey,
    undefined,
  );

  const [jobIdInDetailsPanel, setJobIdInDetailsPanel] = useRecoilState(
    jobsDetailsPanelAtoms.activeJobId,
  );
  const setJobIds = useSetRecoilState(jobsDetailsPanelAtoms.jobIds);

  const [viewInEditMode, setViewInEditMode] = useState<boolean>(false);

  const showDetailsPanel = !!jobIdInDetailsPanel;

  const jobIds = useMemo(() => {
    const jobIds = records?.map(idsMap) || [];

    return jobIds;
  }, [records, idsMap]);

  useEffect(() => {
    setJobIdInDetailsPanel(jobIdQueryValue);
  }, [jobIdQueryValue]);

  useEffect(() => {
    if (jobIdInDetailsPanel) {
      updateJobIdQueryValue(jobIdInDetailsPanel);
    }
  }, [jobIdInDetailsPanel]);

  useEffect(() => {
    setJobIds(jobIds);
  }, [jobIds]);

  useEffect(() => {
    const { cleanupUseEffect } = updateActiveJobRows({
      jobIdInDetailsPanel,
      setViewInEditMode,
      viewInEditMode,
    });

    return () => {
      cleanupUseEffect();
      updateJobIdQueryValue(undefined);
    };
  }, [showDetailsPanel, jobIdInDetailsPanel]);

  return (
    <>
      <Box
        height="100%"
        paddingRight={
          showDetailsPanel ? `${desktopDetailsPanelWidth}px` : undefined
        }
      >
        {children}
      </Box>
      <Conditional condition={showDetailsPanel}>
        <JobsDetailsPanel
          machineStatus={machineStatus}
          userNames={userNames}
          onRefresh={onRefresh}
        />
      </Conditional>
    </>
  );
};

JobsDetailsPanelWrapper.defaultProps = jobsDetailsPanelWrapperDefaultProps;
