import { RecoilState } from 'recoil';
import { MachineType, machineTypes } from '../../constants/machines';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { Material } from '../../constants/parts';

export interface IdleMachineDetails {
  bay: string;
  id: number;
  material?: Material;
  name: string;
  type: MachineType;
}

const atomKeyPrefix = 'IDLE_MACHINES';
const pageName: AtomPageName = 'global';

export const idleMachinesAtoms = {
  [machineTypes.creality]: getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-creality`,
  })(pageName),
  [machineTypes.p1p]: getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-p1p`,
  })(pageName),
  [machineTypes.tumbler]: getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-tumbler`,
  })(pageName),
  [machineTypes.ultimaker]: getGenericAtomFamily<IdleMachineDetails[]>({
    defaultValue: [],
    key: `${atomKeyPrefix}-ultimaker`,
  })(pageName),
} satisfies Record<any, RecoilState<IdleMachineDetails[]>>;
