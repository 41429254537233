import { useRecoilValue } from 'recoil';
import { addJobModalAtoms } from '../../add-job-modal.atoms';
import { AddJobFormFooter } from '../step-add-job/add-job-form-footer';
import { AddJobModalFooterProps } from './add-job-modal-footer.types';
import { JobAddedFooter } from '../step-job-added/job-added-footer';
import { JobNotAddedFooter } from '../step-job-not-added/job-not-added-footer';
import { addJobModalSteps } from '../../add-job-modal.types.constants';

export const AddJobModalFooter = (props: AddJobModalFooterProps) => {
  const { handleCloseModal } = props;

  const step = useRecoilValue(addJobModalAtoms.step);

  switch (step) {
    case addJobModalSteps.addJob:
      return <AddJobFormFooter handleCloseModal={handleCloseModal} />;

    case addJobModalSteps.jobAdded:
      return <JobAddedFooter handleCloseModal={handleCloseModal} />;

    case addJobModalSteps.jobNotAdded:
      return <JobNotAddedFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
