import { usePubNub } from 'pubnub-react';
import { DependencyList, useEffect } from 'react';
import { PAGE_UPDATED_MESSAGE } from '../../constants/messages';
import { realtimeClassName } from './use-real-time-web-hook.constants';
import { SESSION_IDENTIFIER } from '../../helpers/auth';
import { useNotification } from '../useNotification';
import {
  ChangedDataRecordDefault,
  DataRecordDefault,
  GetUpdatedRecordData,
  UseRealTimeWebHookProps,
  WebHookMessageType,
} from './use-real-time-web-hook.types';

/**
 * Example:
 *
 * ```ts
 * useRealTimeWebHook<DataRecord, ChangedDataRecord>(...)
 * ```
 */
export const useRealTimeWebHook = <
  DataRecord extends DataRecordDefault,
  ChangedDataRecord extends ChangedDataRecordDefault<DataRecord>,
>(
  props: UseRealTimeWebHookProps<DataRecord, ChangedDataRecord>,
  dependencies: DependencyList = [],
) => {
  const {
    activeStatusFilters = [],
    channel,
    dataAttrForRow,
    disableHandleFetchRequest,
    getUpdatedRecordData = ({ changedData, record }) => {
      return changedData.find(({ id }) => {
        return id === record.id;
      });
    },
    handleFetchRequest,
    records,
    setRecords,
  } = props;

  const pubNub = usePubNub();

  const { notify, notifyAxiosError } = useNotification();

  const handleWebHook = async (
    event: WebHookMessageType<ChangedDataRecord>,
  ) => {
    try {
      const { changedData, sessionIdentifier } = event.message;

      const isUpdateFromDifferentSession =
        !!sessionIdentifier && sessionIdentifier !== SESSION_IDENTIFIER;

      if (
        isUpdateFromDifferentSession &&
        changedData?.length &&
        records?.length
      ) {
        const shouldMakeRequest = getRecordUpdatedNoLongerShown({
          activeStatusFilters,
          changedData,
          getUpdatedRecordData,
          records,
        });

        if (shouldMakeRequest) {
          const disabled = disableHandleFetchRequest?.({
            changedData,
            records,
          });

          if (!disabled) {
            await handleFetchRequest();

            notify(PAGE_UPDATED_MESSAGE);
          }
        } else {
          let recordsUpdated = false;
          const updatedRecords = records.map((currentRecord) => {
            const updatedRecordData = getUpdatedRecordData({
              changedData,
              record: currentRecord,
            });

            if (updatedRecordData) {
              recordsUpdated = true;

              return { ...currentRecord, ...updatedRecordData };
            }

            return currentRecord;
          });

          if (recordsUpdated) {
            const updatedIds = changedData.map(({ id }) => {
              return id;
            });

            await setRecords(updatedRecords);

            runAnimation(updatedIds);

            notify(PAGE_UPDATED_MESSAGE);
          }
        }
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update page with real-time update data.',
      });
    }
  };

  const runAnimation = (ids: number[]) => {
    ids.forEach((id) => {
      const element = document.body.querySelector(
        `[${dataAttrForRow}='${id}']`,
      );

      if (element) {
        element.classList.add(realtimeClassName);
        setTimeout(() => {
          element.classList.remove(realtimeClassName);
        }, 5000);
      }
    });
  };

  useEffect(() => {
    // if pubNub is not working locally, check the keys in .env against https://app.netlify.com/sites/dev-core/configuration/env
    pubNub.addListener({ message: handleWebHook });
    pubNub.subscribe({ channels: [channel] });

    return () => {
      pubNub.removeAllListeners();
    };
  }, [pubNub, records, ...dependencies]);
};

const getRecordUpdatedNoLongerShown = <
  DataRecord extends DataRecordDefault,
  ChangedDataRecord extends ChangedDataRecordDefault<DataRecord>,
>(props: {
  activeStatusFilters: DataRecord['status'][];
  changedData: ChangedDataRecord[];
  getUpdatedRecordData: GetUpdatedRecordData<DataRecord, ChangedDataRecord>;
  records: DataRecord[];
}) => {
  const { activeStatusFilters, changedData, getUpdatedRecordData, records } =
    props;
  const shouldMakeRequest =
    !!activeStatusFilters?.length &&
    changedData.some(({ status }) => {
      const statusDoesNotMatchActiveFilters = !activeStatusFilters.some(
        (activeStatus) => {
          return activeStatus === status;
        },
      );

      if (statusDoesNotMatchActiveFilters) {
        const isInRecords = records.some((record) => {
          return !!getUpdatedRecordData({ changedData, record });
        });

        return isInRecords;
      }

      return false;
    });

  return shouldMakeRequest;
};
