import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { GetPrintJobsArgs, GetPrintJobsResponse } from './get-print-jobs.types';

export const getMachinePrintJobs = (args: GetPrintJobsArgs) => {
  const { machineId } = args;

  return axios
    .get<GetPrintJobsResponse>(
      `${getCoreApiUrl('machines')}/${machineId}/print-jobs`,
    )
    .then(({ data }) => {
      return data;
    });
};
