import { Form, Formik } from 'formik';
import { Modal } from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useCreateNonProductJob } from '../../../../../api/jobs/routes/create-non-product-job/create-non-product-job.query';
import { useResetRecoilAtomsOnMount } from '../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { addJobModalAtoms } from './add-job-modal.atoms';
import { initialValues } from './add-job-modal.formik';
import { schema } from './add-job-modal.schema';
import { AddJobFormValues, AddJobModalProps } from './add-job-modal.types';
import { addJobModalSteps } from './add-job-modal.types.constants';
import { AddJobModalContent } from './components/add-job-modal-content';
import { AddJobModalFooter } from './components/add-job-modal-footer';
import { AddJobModalHeader } from './components/add-job-modal-header';
import { getNonProductJobDescription } from './helpers/get-non-product-job-description';

export const AddJobModal = (props: AddJobModalProps) => {
  const { onClose, onUpdate, userNames } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(addJobModalAtoms);
  const [updateOnClose, setUpdateOnClose] = useRecoilState(
    addJobModalAtoms.updateOnClose,
  );
  const setStep = useSetRecoilState(addJobModalAtoms.step);
  const setUsers = useSetRecoilState(addJobModalAtoms.users);
  const setNewJobIds = useSetRecoilState(addJobModalAtoms.newJobIds);

  const { onCreateNonProductJob } = useCreateNonProductJob();

  const handleCloseModal = async () => {
    if (updateOnClose) {
      await onUpdate();
    }

    onClose();
  };

  const onSubmit = async (values: AddJobFormValues) => {
    await onCreateNonProductJob(
      {
        jobData: {
          assignedTo: values.assignedTo,
          description: getNonProductJobDescription(values),
          duration: values.duration,
          instruction: {
            text: values.instruction,
          },
          inventoryRequests: [],
          step: values.step,
          type: values.type,
        },
      },
      {
        onError: () => {
          setStep(addJobModalSteps.jobNotAdded);
        },
        onSuccess: ({ jobIds }) => {
          setNewJobIds(jobIds);
          setUpdateOnClose(true);
          setStep(addJobModalSteps.jobAdded);
        },
      },
    );
  };

  useEffect(() => {
    setUsers(userNames);
  }, [userNames]);

  return (
    <ResetAtomsWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validateOnMount
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <Modal
                footer={
                  <AddJobModalFooter handleCloseModal={handleCloseModal} />
                }
                header={<AddJobModalHeader />}
                width={{ lg: '50rem', md: '100%' }}
                onClose={handleCloseModal}
              >
                <AddJobModalContent />
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </ResetAtomsWrapper>
  );
};
