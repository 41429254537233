import { useMemo, useState } from 'react';
import flatten from 'lodash/flatten';
import {
  Button,
  Conditional,
  Flex,
  Line,
  Stack,
  Typography,
} from 'gantri-components';
import { useParams } from 'react-router-dom';
import routePaths from '../../config/route-paths';
import { shipmentStatuses } from '../../constants/options';
import { ReassignStockItem } from './components/reassign-stock-item';
import { stocksApi } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import { PageHeading } from '../../components/layout/page-heading';
import { useRouter } from '../../hooks';
import { useFetchOrder } from '../../api/transactions/routes';
import { ReassignRecord } from '../../api/stocks/routes/reassign-stocks/reassign-stocks.types';
import { useFetchIdleStocks } from '../../api/stocks/routes';
import { MetaData } from '../../components/meta-data/metaData';

export const OrderReassignStock = () => {
  const { navigate } = useRouter();
  const { id: orderIdParam } = useParams<{ id: string }>();

  const orderId = Number(orderIdParam);

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const [selectedStocks, setSelectedStocks] = useState<ReassignRecord[]>([]);

  const { data: orderData } = useFetchOrder({
    enabled: !!orderId,
    fetchArgs: {
      id: orderId,
    },
    showLoading: true,
  });

  const shipments = orderData?.order?.shipments || [];

  const allStocksFromWaitingShipments = useMemo(() => {
    return flatten(
      shipments
        .filter((shipment) => {
          return shipment.status === shipmentStatuses.waiting;
        })
        .map((shipment) => {
          return shipment.stocks;
        }),
    );
  }, [shipments]);

  const skus = useMemo(() => {
    return allStocksFromWaitingShipments.map((stock) => {
      return stock.product.sku;
    });
  }, [allStocksFromWaitingShipments]);

  const { data: idleStocksData } = useFetchIdleStocks({
    enabled: !!skus.length,
    fetchArgs: {
      skus,
    },
    showLoading: true,
  });

  const idleStocksBySku = idleStocksData?.idleStocks || [];

  const contentWidth = { lg: '70rem', sm: '100%' };

  const pageTitle = 'Re-assign stock';

  const onConfirm = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const response = await stocksApi.reassignStocks(
          orderId,
          selectedStocks,
        );

        notify(response.data.notice);
        navigate(`/orders/${orderId}`);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to re-assign stock(s).',
        });
      }
    });
  };

  return (
    <>
      <MetaData title={pageTitle} />
      <PageHeading
        subTitle="Re-assign stock for the items below."
        title={pageTitle}
      />
      <Stack gap="5x" height="unset" verticalPadding="5x">
        <Conditional
          condition={!!allStocksFromWaitingShipments.length}
          Fallback={
            <Typography
              text={`This order does not have any stocks associated with ${shipmentStatuses.waiting} shipments.`}
            />
          }
        >
          <Stack gap="4x" height="unset" maxWidth={contentWidth}>
            {allStocksFromWaitingShipments.map((stock) => {
              return (
                <ReassignStockItem
                  key={stock.id}
                  idleStocks={idleStocksBySku[stock.product.sku]}
                  selectedStocks={selectedStocks}
                  setSelectedStocks={setSelectedStocks}
                  stock={stock}
                />
              );
            })}
          </Stack>

          <Line />

          <Flex alignItems="center" gap="x">
            <Button
              text="Cancel"
              variant="secondary"
              onClick={() => {
                return navigate(`${routePaths.orders}/${orderId}`);
              }}
            />
            <Button
              disabled={!selectedStocks.length}
              processing={processing}
              text="Confirm"
              variant="primary"
              onClick={onConfirm}
            />
          </Flex>
        </Conditional>
      </Stack>
    </>
  );
};
