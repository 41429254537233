import styled, { css } from 'styled-components';
import { Typography } from 'gantri-components';

export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return css`
      span {
        color: ${theme.colors.typography.t2};
      }
    `;
  }}
`;
