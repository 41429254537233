import { Cell, Conditional, Stack, Table, Typography } from 'gantri-components';
import { useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import routePaths from '../../../../../../config/route-paths';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { JobEndDate } from '../../../../../jobs/hooks/use-get-job-columns/components/job-end-date-cell';
import { RepairHistoryPropsDef } from './repair-history.types';
import { DetailsPanelMachineRepairHistoryItem } from '../../../../../../api/machines/routes/fetch-machine-detail-panel';
import { MachineIssues } from '../../../../../machines/components/machine-issues';

export const RepairHistory = (props: RepairHistoryPropsDef) => {
  const { machineId, repairs } = props;

  const columns = useMemo(() => {
    const columns: ColumnDef<DetailsPanelMachineRepairHistoryItem>[] = [
      {
        accessorKey: 'description',
        cell: ({
          getValue,
        }: CellContext<
          DetailsPanelMachineRepairHistoryItem,
          DetailsPanelMachineRepairHistoryItem['description']
        >) => {
          return <Typography text={getValue()} />;
        },
        header: 'Job Name',
        size: 120,
      },
      {
        accessorKey: 'endDate',
        cell: ({
          getValue,
          row,
        }: CellContext<
          DetailsPanelMachineRepairHistoryItem,
          DetailsPanelMachineRepairHistoryItem['endDate']
        >) => {
          const job = row.original;
          const endDate = getValue();

          return <JobEndDate {...job} endDate={endDate} />;
        },
        header: 'End',
        size: 120,
      },
      {
        accessorKey: 'machineIssues',
        cell: ({
          getValue,
        }: CellContext<
          DetailsPanelMachineRepairHistoryItem,
          DetailsPanelMachineRepairHistoryItem['machineIssues']
        >) => {
          return <MachineIssues issues={getValue()} />;
        },
        header: 'Machine Issues',
        size: 170,
      },
    ];

    return columns;
  }, []);

  return (
    <Stack gap={!!repairs.length ? '3x' : 'x'}>
      <Typography text="Repair history" variant="h5" />
      <Conditional
        condition={!!repairs.length}
        Fallback={<Typography color="t2" text="None" />}
      >
        <Cell justifyItems="start">
          <Table columns={columns} data={repairs} />

          <StyledAnchor
            padding="x"
            text="View full history"
            to={`${routePaths.machines}/${machineId}?tab=repairs`}
          />
        </Cell>
      </Conditional>
    </Stack>
  );
};
