import { useMemo } from 'react';
import { Button, Flex } from 'gantri-components';
import routePaths from '../../../../config/route-paths';
import { useNotification } from '../../../../hooks/useNotification';
import { useRouter } from '../../../../hooks';
import {
  useShipmentActionsModal,
  shipmentActionsModalSteps,
} from '../../../order/components/shipment-actions-modal';
import {
  useCancelOrRefundItems,
  useInvalidateFetchOrderCache,
} from '../../../../api/transactions/routes';
import {
  CancelOrRefundItemsArgs,
  RefundItem,
} from '../../../../api/transactions/routes/cancel-or-refund-items/cancel-or-refund-items.types';
import { RefundOrReturnFooterPropsDef } from './refund-or-return-footer.types';
import { useInvalidateFetchPaginatedOrdersCache } from '../../../../api/orders/routes';

export const RefundOrReturnFooter = (props: RefundOrReturnFooterPropsDef) => {
  const { isReturn, order, refundShipping, selectedStocksDetails } = props;

  const { navigate } = useRouter();

  const { notifyAxiosError } = useNotification();

  const isValid = useMemo<boolean>(() => {
    if (selectedStocksDetails.length) {
      return selectedStocksDetails.every(({ isValid }) => {
        return isValid;
      });
    }

    return refundShipping;
  }, [selectedStocksDetails, refundShipping]);

  const refundItems = useMemo<RefundItem[]>(() => {
    return selectedStocksDetails.map(({ reason, stock }) => {
      return {
        id: stock.stockInfoId,
        refundReason: reason,
      } satisfies RefundItem;
    });
  }, [selectedStocksDetails]);

  const onCancel = () => {
    navigate(`${routePaths.orders}/${order.id}`);
  };

  const { openShipmentActionsModal } = useShipmentActionsModal({
    order,
  });

  const { invalidateFetchOrderCache } = useInvalidateFetchOrderCache();
  const { invalidateFetchPaginatedOrdersCache } =
    useInvalidateFetchPaginatedOrdersCache();

  const { isLoading: processing, onCancelOrRefundItems } =
    useCancelOrRefundItems({
      onError: (error) => {
        if (isReturn) {
          notifyAxiosError({
            error,
            fallbackMessage:
              'A shipping error occurred, please enter the shipping details manually.',
          });

          openShipmentActionsModal({
            handleReturnAndShipManually: onConfirm,
            step: shipmentActionsModalSteps.shipManually,
          });
        }
      },
      onSuccess: async ({ newOrderId }) => {
        await invalidateFetchOrderCache({ id: order.id });
        await invalidateFetchPaginatedOrdersCache();

        const orderId = newOrderId || order.id;

        navigate(`${routePaths.orders}/${orderId}`);
      },
    });

  const onConfirm = async (props?: {
    shippingCost: number;
    trackingNumber: string;
  }) => {
    const { shippingCost, trackingNumber } = props || {};

    const manualShipping: Partial<CancelOrRefundItemsArgs> = trackingNumber
      ? {
          manualDataFromShippo: {
            rateCost: shippingCost,
            trackingNumber,
          },
          type: 'Manual refund',
        }
      : {};

    await onCancelOrRefundItems({
      orderId: order.id,
      refundItems,
      refundShipping,
      status: 'Refund',
      ...manualShipping,
    });
  };

  return (
    <Flex alignItems="center" gap="x">
      <Button text="Cancel" variant="secondary" onClick={onCancel} />

      <Button
        disabled={!isValid}
        processing={processing}
        text="Confirm"
        variant="primary"
        onClick={() => {
          return onConfirm();
        }}
      />
    </Flex>
  );
};
