import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { getStatementDownloadUrl } from './get-statement-download-url';
import {
  GetStatementDownloadUrlArgsDef,
  GetStatementUrlResponseDef,
} from './get-statement-download-url.types';

export const useGetStatementDownloadUrl = <
  TransformedData = GetStatementUrlResponseDef,
>(
  props?: GenericMutateQueryProps<
    GetStatementDownloadUrlArgsDef,
    GetStatementUrlResponseDef,
    TransformedData
  >,
) => {
  // ? Intentionally using Mutate as we do not want to cache the response
  const { onMutate: onGetStatementDownloadUrl, ...rest } =
    useGenericMutateQuery({
      fallbackErrorMessage: 'Unable to get statement download url.',
      mutationFn: getStatementDownloadUrl,
      ...props,
    });

  return { ...rest, onGetStatementDownloadUrl };
};
