import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  FetchOrderSummaryArgsDef,
  FetchOrderSummaryResponseDef,
} from './fetch-order-summary.types';

export const fetchOrderSummary = (props: FetchOrderSummaryArgsDef) => {
  const { id, ...queries } = props;

  return axios
    .get<FetchOrderSummaryResponseDef>(
      `${getCoreApiUrl(
        'transactions',
      )}/${id}/order-summary${convertQueriesToString(queries)}`,
    )
    .then(({ data }) => {
      return data;
    });
};
