import * as yup from 'yup';
import { isOpaqueMaterial } from '../../constants/parts';

export const schema = yup.object().shape({
  batchPartName: yup.string().required('Part name is required.').nullable(),
  batchPartNotes: yup.string().nullable(),
  batchQty: yup.number().required('Please enter a valid number.').nullable(),
  colorCode: yup
    .string()
    .when('material', {
      is: (value) => {
        return isOpaqueMaterial(value);
      },
      then: yup.string().required('Color is required.').nullable(),
    })
    .nullable(),
  material: yup.string().nullable(),
  partName: yup.string().required('Part is required.').nullable(),
  productId: yup.number().required('Product is required.').nullable(),
  template: yup.string().required().nullable(),
});
