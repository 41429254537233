import { format } from 'date-fns';
import { StatementDef } from '../../../../../api/statements/routes';
import { MEDIUM_FORMAT } from '../../../../../constants/dates';

export const formatSummaryPeriod = (payout: StatementDef): string => {
  const formattedStartDate = format(new Date(payout.startDate), MEDIUM_FORMAT);
  const formattedEndDate = format(new Date(payout.endDate), MEDIUM_FORMAT);

  return `${formattedStartDate} - ${formattedEndDate}`;
};
