import { FC } from 'react';
import { Cell, Table } from 'gantri-components';
import { StockJobsProps } from './stock-jobs.types';
import { getJobsRowProps } from '../../../../helpers/get-jobs-row-props';
import { useGetStockPageJobTableColumns } from './hooks/use-get-stock-page-job-table-columns';
import { useTableColumnsSync } from '../../../../hooks';
import { JobsDetailsPanelWrapper } from '../../../../components/jobs-details-panel-wrapper';
import { SectionTitle } from '../../../../components/layout/section-title';

export const StockJobs: FC<StockJobsProps> = (props) => {
  const { refreshStock, stock } = props;
  const { jobs } = stock;

  const columnsSyncProps = useTableColumnsSync('stock-jobs');

  const { DownloadHandoutStatusToast, columns } =
    useGetStockPageJobTableColumns({
      onRefresh: refreshStock,
    });

  return (
    <Cell>
      <SectionTitle text="Stock Jobs" />

      <DownloadHandoutStatusToast position="top-end" />

      <JobsDetailsPanelWrapper records={jobs} onRefresh={refreshStock}>
        <Table
          columns={columns}
          data={jobs}
          getRowProps={getJobsRowProps}
          highlightHoveredRow
          stickyLastColumn
          {...columnsSyncProps}
        />
      </JobsDetailsPanelWrapper>
    </Cell>
  );
};
