import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  GetIdleStocksArgs,
  GetIdleStocksResponse,
} from './get-idle-stocks.types';

export const getIdleStocks = (queries: GetIdleStocksArgs) => {
  return axios
    .get<GetIdleStocksResponse>(
      `${coreApiUrl}/stocks/idle${convertQueriesToString(queries)}`,
    )
    .then(({ data }) => {
      return data;
    });
};
