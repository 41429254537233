import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Modal, Stack, TextArea, Toggle } from 'gantri-components';
import { RestockModalPropsDef } from './restock.types';
import { Items } from '../../../../order.type';
import { ShortProductSummary } from '../../../../../../components/common/short-product-summary';
import { transactionsApi } from '../../../../../../api';
import { useNotification } from '../../../../../../hooks/useNotification';
import { useInvalidateFetchOrderCache } from '../../../../../../api/transactions/routes';

export const RestockModal: FC<RestockModalPropsDef> = (props) => {
  const { onClose, orderId, stocks = [] } = props;

  const [selected, setSelected] = useState<Record<number, Items>>({});
  const [disabled, setDisabled] = useState<boolean>(true);

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const { invalidateFetchOrderCache } = useInvalidateFetchOrderCache();

  const onConfirm = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const items = Object.values(selected);
        const { data } = await transactionsApi.restockItems(orderId, items);

        notify(data.notice);

        await invalidateFetchOrderCache();

        onClose();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to restock items.',
        });
      }
    });
  };

  const onActiveChange = useCallback(
    (stockId) => {
      return (value) => {
        setSelected({
          ...selected,
          [stockId]: {
            ...selected[stockId],
            id: stockId,
            status: value ? 'Restocked' : 'Discarded',
          },
        });
      };
    },
    [selected],
  );

  const onReasonChange = useCallback(
    (stockId) => {
      return (value) => {
        setSelected({
          ...selected,
          [stockId]: { ...selected[stockId], id: stockId, reason: value },
        });
      };
    },
    [selected],
  );

  useEffect(() => {
    setSelected(
      stocks.reduce((accumulator, current) => {
        return {
          ...accumulator,
          [current.stockInfoId]: {
            id: current.stockInfoId,
            status: 'Discarded',
          },
        };
      }, {}),
    );
  }, [stocks]);

  useEffect(() => {
    setDisabled(
      !Object.values(selected).every(
        (item: { reason: string | boolean; status: string }) => {
          return (
            item.status === 'Restocked' ||
            (item.status === 'Discarded' && !!item.reason)
          );
        },
      ),
    );
  }, [selected]);

  return (
    <Modal
      footer={
        <Button
          disabled={disabled}
          processing={processing}
          size="large"
          text="Confirm"
          onClick={onConfirm}
        />
      }
      header="Restock items"
      maxWidth={{ lg: '44rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack>
        {stocks.map((stock) => {
          return selected[stock.stockInfoId] ? (
            <Stack key={stock.stockInfoId} gap="x">
              <ShortProductSummary
                fetchSku={stock.product.sku}
                imageSize="6rem"
              />
              <Stack gap="x" paddingLeft="6.8rem">
                <Toggle
                  labelText="Restock"
                  value={selected[stock.stockInfoId].status === 'Restocked'}
                  onSelected={onActiveChange(stock.stockInfoId)}
                />

                {selected[stock.stockInfoId].status === 'Discarded' && (
                  <TextArea
                    placeholder="Enter reason"
                    onTextChange={onReasonChange(stock.stockInfoId)}
                  />
                )}
              </Stack>
            </Stack>
          ) : null;
        })}
      </Stack>
    </Modal>
  );
};
