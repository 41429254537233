import { jobStepsIncludingNa } from '../../../../../../../constants/options';
import { GetNonProductJobDescriptionPropsDef } from './get-non-product-job-description.types';

export const getNonProductJobDescription = (
  props: GetNonProductJobDescriptionPropsDef,
) => {
  const { step, type } = props;

  return step === jobStepsIncludingNa.na ? type : [step, type].join(' ');
};
