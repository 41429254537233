import {
  Cell,
  Conditional,
  Line,
  Stack,
  Table,
  Typography,
} from 'gantri-components';
import { useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import routePaths from '../../../../../../config/route-paths';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { OpenIssuesPropsDef } from './open-issues.types';
import { DetailsPanelMachineOpenIssueItem } from '../../../../../../api/machines/routes/fetch-machine-detail-panel';
import {
  IssueCreatedAt,
  IssueDifficulty,
} from '../../../../../machine/sections/issues-section/hooks/use-get-machine-issues-columns';

export const OpenIssues = (props: OpenIssuesPropsDef) => {
  const { issues, machineId } = props;

  const columns = useMemo(() => {
    const columns: ColumnDef<DetailsPanelMachineOpenIssueItem>[] = [
      {
        accessorKey: 'type',
        cell: ({
          getValue,
        }: CellContext<
          DetailsPanelMachineOpenIssueItem,
          DetailsPanelMachineOpenIssueItem['type']
        >) => {
          return <Typography text={getValue()} />;
        },
        header: 'Type',
        size: 120,
      },
      {
        accessorKey: 'difficulty',
        cell: ({
          getValue,
        }: CellContext<
          DetailsPanelMachineOpenIssueItem,
          DetailsPanelMachineOpenIssueItem['difficulty']
        >) => {
          return <IssueDifficulty difficulty={getValue()} />;
        },
        header: 'Difficulty',
        size: 120,
      },
      {
        accessorKey: 'createdAt',
        cell: ({
          getValue,
          row,
        }: CellContext<
          DetailsPanelMachineOpenIssueItem,
          DetailsPanelMachineOpenIssueItem['createdAt']
        >) => {
          const createdAt = getValue();
          const { notes, user } = row.original;

          return (
            <IssueCreatedAt createdAt={createdAt} notes={notes} user={user} />
          );
        },
        header: 'Created',
        size: 170,
      },
    ];

    return columns;
  }, []);

  return (
    <Conditional condition={!!issues.length}>
      <Stack gap="3x">
        <Typography text="Open issues" variant="h5" />
        <Cell justifyItems="start">
          <Table columns={columns} data={issues} />

          <StyledAnchor
            padding="x"
            text="View full history"
            to={`${routePaths.machines}/${machineId}?tab=issues`}
          />
        </Cell>
      </Stack>
      <Line />
    </Conditional>
  );
};
