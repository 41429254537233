import {
  Cell,
  Flex,
  Grid,
  Icon,
  Line,
  Stack,
  Typography,
} from 'gantri-components';
import { capitalize } from 'lodash';
import { useInvalidateFetchMachinesOverviewCache } from '../../../../../../api/machines/routes';
import routePaths from '../../../../../../config/route-paths';
import { useInvalidateFetchMachineDetailsPanelCache } from '../../../../../../api/machines/routes/fetch-machine-detail-panel';
import { Label } from '../../../../../../components/label';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { JobStatusBadge } from '../../../../../../components/common/custom-data-cells/status-data-cell';
import { ShortProductSummary } from '../../../../../../components/common/short-product-summary';
import { jobSteps, jobTypeOptions } from '../../../../../../constants/options';
import { jobTypeToIconTypeMap } from '../../../../../../constants/jobs';
import { JobActionButton } from '../../../../../jobs/hooks/use-get-job-columns/components/job-action-cell';
import { CurrentPrintHarvestJobPropsDef } from './current-print-harvest-job.types';

export const CurrentPrintHarvestJob = (
  props: CurrentPrintHarvestJobPropsDef,
) => {
  const { job, machineId } = props;

  const { invalidateFetchMachinesOverviewCache } =
    useInvalidateFetchMachinesOverviewCache();

  const { invalidateFetchMachineDetailsPanelCache } =
    useInvalidateFetchMachineDetailsPanelCache();

  return (
    <>
      <Stack gap={job ? '3x' : 'x'}>
        <Typography
          text={capitalize(`Current ${jobTypeOptions.harvest} job`)}
          variant="h5"
        />
        {job ? (
          <>
            <Grid columns="9rem 1fr" gap="2x">
              <Label text="Step & type" />
              <Typography
                icon={
                  <Icon
                    color="t2"
                    name={jobTypeToIconTypeMap[job.type]}
                    top="2px"
                  />
                }
                text={`${jobSteps.print} ${job.type}`}
              />

              <Label text="Job status" />
              <Cell justifyItems="start">
                <JobStatusBadge status={job.status} />
              </Cell>

              <Label text="Part" />
              <Flex alignItems="baseline" gap="x">
                <StyledAnchor
                  text={`#${job.part.id}`}
                  to={`${routePaths.parts}/${job.part.id}`}
                />
                <Typography text={job.part.name} />
              </Flex>

              <Label text="Product" />
              <ShortProductSummary {...job.product} />
            </Grid>
            <JobActionButton
              job={job}
              onRefresh={async () => {
                await invalidateFetchMachinesOverviewCache();
                await invalidateFetchMachineDetailsPanelCache({
                  id: machineId,
                });
              }}
            />
          </>
        ) : (
          <Typography color="t2" text="None" />
        )}
      </Stack>
      <Line />
    </>
  );
};
