import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchStatementArgsDef,
  FetchStatementResponseDef,
} from './fetch-statement.types';
import { fetchStatement } from './fetch-statement';

const fetchStatementQueriesObj = getGenericQueryObj({
  groupName: 'fetch-statement',
  query: fetchStatement,
});

export const useFetchStatement = <TransformedData = FetchStatementResponseDef>(
  props?: GenericFetchProps<
    FetchStatementArgsDef,
    FetchStatementResponseDef,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch statement.',
    queryObj: fetchStatementQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchStatementCache = () => {
  const invalidateFetchStatementCache = useGetInvalidateQueryCache(
    fetchStatementQueriesObj,
  );

  return { invalidateFetchStatementCache };
};
