import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedStatementTransactionsArgsDef,
  FetchPaginatedStatementTransactionsResponseDef,
} from './fetch-paginated-statement-transactions.types';

export const fetchPaginatedStatementTransactions = (
  args: FetchPaginatedStatementTransactionsArgsDef,
): Promise<FetchPaginatedStatementTransactionsResponseDef> => {
  const {
    count,
    dateRange,
    page = null,
    search,
    sortingField = null,
    sortingType = null,
    ...filters
  } = args;

  return axios
    .get<FetchPaginatedStatementTransactionsResponseDef>(
      `${getCoreApiUrl('statement-transactions')}/paginate`,
      {
        params: {
          filter: {
            endDate: dateRange.to,
            startDate: dateRange.from,
            ...filters,
          },
          limit: count,
          page,
          search,
          sort: {
            field: sortingField,
            type: sortingType,
          },
        },
      },
    )
    .then(({ data }) => {
      return data;
    });
};
