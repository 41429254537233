import { atom } from 'recoil';
import { UserName } from '../../../../../api/jobs/routes/get-paginated-jobs/get-paginated-jobs.types';
import { AddJobModalStep } from './add-job-modal.types';
import { addJobModalSteps } from './add-job-modal.types.constants';

const atomKeyPrefix = 'ADD_JOB_MODAL';

export const addJobModalAtoms = {
  newJobIds: atom<number[]>({
    default: [],
    key: `${atomKeyPrefix}-new-job-ids`,
  }),
  step: atom<AddJobModalStep>({
    default: addJobModalSteps.addJob,
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
  users: atom<UserName[]>({
    default: [],
    key: `${atomKeyPrefix}-users`,
  }),
};
