import { useEffect, useState } from 'react';
import { ReasonItemDef } from '../../reason-stock.types';
import { UseHandleInteractionsPropsDef } from './use-handle-interactions.types';

export const useHandleInteractions = (props: UseHandleInteractionsPropsDef) => {
  const { setSelectedStocksDetails, stock } = props;

  const [checked, setChecked] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<ReasonItemDef>();
  const [specificReason, setSpecificReason] = useState<string>('');

  const handleCheck = (checked: boolean) => {
    if (!checked) {
      handleSelectedReason();
    }

    setChecked(checked);

    setSelectedStocksDetails((prevSelectedStocks) => {
      if (checked) {
        return [...prevSelectedStocks, { isValid: false, reason: '', stock }];
      }

      return prevSelectedStocks.filter(({ stock: selectedStock }) => {
        return selectedStock.id !== stock.id;
      });
    });
  };

  const handleSelectedReason = (selectedReasonItem?: ReasonItemDef) => {
    setSelectedReason(selectedReasonItem);
    setSpecificReason('');
  };

  useEffect(() => {
    const { requireSpecifics, value: primaryReason } = selectedReason || {};

    const isValid =
      checked &&
      (requireSpecifics
        ? !!primaryReason && !!specificReason
        : !!primaryReason);

    const reason = requireSpecifics
      ? `${primaryReason}: ${specificReason}`
      : primaryReason;

    setSelectedStocksDetails((prevSelectedStocks) => {
      return prevSelectedStocks.map((item) => {
        const { stock: selectedStock } = item;
        const isMatchingStock = selectedStock.id === stock.id;

        if (isMatchingStock) {
          return { isValid, reason, stock };
        }

        return item;
      });
    });
  }, [selectedReason, specificReason]);

  return {
    checked,
    handleCheck,
    handleSelectedReason,
    selectedReason,
    setSpecificReason,
    specificReason,
  };
};
