import { FormikInput, SearchField } from 'gantri-components';
import { useFetchMachineLocations } from '../../../api/machines/routes/fetch-machine-locations';
import { MachineLocationInputPropsDef } from './machine-location-input.types';

export const MachineLocationInput = (props: MachineLocationInputPropsDef) => {
  const { isEnabled = true, name = 'location', ...formikInputProps } = props;

  const { data, isLoading } = useFetchMachineLocations({
    enabled: isEnabled,
  });

  const allMachineLocations = data?.locations || [];

  return (
    <FormikInput
      Field={
        <SearchField
          idProperty="name"
          items={allMachineLocations}
          labelProperty="name"
          placeholder="Search location"
          processing={isLoading}
        />
      }
      fieldVariant="standard"
      name={name}
      {...formikInputProps}
    />
  );
};
