import { CheckboxList } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { AtomsCheckboxListProps } from './atoms-checkbox-list.types';

export const AtomsCheckboxList = <Value extends string | Record<any, any>>(
  props: AtomsCheckboxListProps<Value>,
) => {
  const { atom, onValueChange, ...rest } = props;

  const [atomValue, setAtomValue] = useRecoilState(atom);

  return (
    <CheckboxList
      {...rest}
      values={atomValue}
      onValueChange={async (newValue, lastInteractedItem) => {
        setAtomValue(newValue);

        await onValueChange?.(newValue, lastInteractedItem);
      }}
    />
  );
};
