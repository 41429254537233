import {
  PaginatedStatement,
  statementStatuses,
} from '../../../../api/statements/routes';
import { StatusBadge } from '../../../../components/status-badge';
import { statusBadgeThemes } from '../../../../components/status-badge/status-badge.constants';
import { StatementStatusBadgePropsDef } from './statement-status-badge.types';

export const StatementStatusBadge = (props: StatementStatusBadgePropsDef) => {
  const { status, ...rest } = props;

  const getBadgeThemeForStatus = (status: PaginatedStatement['status']) => {
    switch (status) {
      case statementStatuses.paid:
        return statusBadgeThemes.blueLight;

      case statementStatuses.processed:
        return statusBadgeThemes.yellowLight;

      default:
        return statusBadgeThemes.monochromeLight;
    }
  };

  const badgeTheme = getBadgeThemeForStatus(status);

  return (
    <StatusBadge
      {...rest}
      icon={
        status === statementStatuses.paid ? 'ui-control:check_mark' : undefined
      }
      text={status}
      theme={badgeTheme}
    />
  );
};
