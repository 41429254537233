import { useMemo } from 'react';
import { Flex, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import routePaths from '../../../../config/route-paths';
import { PaginatedStatementTransactionDef } from '../../../../api/statements/routes';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';
import { formatStatementCurrency } from '../../../statements/helpers/format-statement-currency';
import { formatStatementQuantity } from '../../../statements/helpers/format-statement-quantity';
import { Label } from '../../../../components/label';
import { formatStatementSku } from '../../../statements/helpers/format-statement-sku';

export const useStatementsTransactionsColumns = () => {
  const columns = useMemo(() => {
    const columns: ColumnDef<PaginatedStatementTransactionDef>[] = [
      {
        accessorKey: 'date',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['date']
        >) => {
          const date = getValue();

          return <Typography text={date} />;
        },
        header: 'Date',
        size: 80,
      },
      {
        accessorKey: 'designerName',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['designerName']
        >) => {
          const designer = getValue();

          return <Typography text={designer} />;
        },
        header: 'Designer',
      },
      {
        accessorKey: 'category',
        header: 'Category',
        size: 80,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 80,
      },
      {
        accessorKey: 'order',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['order']
        >) => {
          const order = getValue();

          return (
            <Flex gap="1ch">
              <Typography text={order.type} />
              <StyledAnchor
                href={`${routePaths.orders}/${order.id}`}
                text={`#${order.id}`}
              />
            </Flex>
          );
        },
        header: 'Order',
        maxSize: 100,
        meta: {
          getIsClickable: getIsClickableIfValue,
          isSearchable: true,
          label: 'Order ID',
        },
      },
      {
        accessorKey: 'statementId',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['statementId']
        >) => {
          const statementId = getValue();

          return (
            <StyledAnchor
              text={`#${statementId}`}
              to={`${routePaths.statements}/${statementId}`}
            />
          );
        },
        header: 'Statement',
        meta: {
          getIsClickable: getIsClickableIfValue,
          isSearchable: true,
          label: 'Statement ID',
        },
        size: 80,
      },
      {
        accessorKey: 'sku',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['sku']
        >) => {
          const sku = getValue();

          return (
            <Typography text={formatStatementSku(sku)} whiteSpace="nowrap" />
          );
        },
        header: 'SKU',
      },
      {
        accessorKey: 'quantity',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['quantity']
        >) => {
          const quantity = getValue();

          return (
            <Typography
              align="right"
              text={formatStatementQuantity(quantity)}
            />
          );
        },
        header: ({ column }) => {
          const { label } = column.columnDef.meta;

          return <Label align="right" text={label} />;
        },
        meta: {
          label: 'Quantity',
        },
        size: 40,
      },
      {
        accessorKey: 'pricePerItem',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['pricePerItem']
        >) => {
          const pricePerItem = getValue();

          return (
            <Typography
              align="right"
              text={formatStatementCurrency(pricePerItem)}
            />
          );
        },
        header: ({ column }) => {
          const { label } = column.columnDef.meta;

          return <Label align="right" text={label} />;
        },
        meta: {
          label: 'Price per item',
        },
        size: 60,
      },
      {
        accessorKey: 'total',
        cell: ({
          getValue,
        }: CellContext<
          PaginatedStatementTransactionDef,
          PaginatedStatementTransactionDef['total']
        >) => {
          const total = getValue();

          return (
            <Typography
              align="right"
              color="t2"
              text={formatStatementCurrency(total)}
            />
          );
        },
        header: ({ column }) => {
          const { label } = column.columnDef.meta;

          return <Label align="right" text={label} />;
        },
        meta: {
          headerJustifyContent: 'flex-end',
          label: 'Total',
        },
        size: 60,
      },
    ];

    return columns;
  }, []);

  return columns;
};
