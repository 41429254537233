import { Cell, Grid } from 'gantri-components';
import { AddOrEditPartModalContentProps } from './add-or-edit-part-modal-content.types';
import { PartName } from './components/part-name';
import { PartMaterial } from './components/part-material';
import { PartMachineType } from './components/part-machine-type';
import { PartPaintedStatus } from './components/part-painted-status';
import { PartNumPrints } from './components/part-num-prints';
import { PartBondo } from './components/part-bondo';
import { PartColorOverrides } from './components/part-color-overrides';
import { SimpleErrorBoundary } from '../../../../../../../../../../components/simple-error-boundary';

export const AddOrEditPartModalContent = (
  props: AddOrEditPartModalContentProps,
) => {
  const { modalType } = props;

  return (
    <Grid columnGap="2x" columns={{ lg: 2, sm: 1 }} rowGap="3x">
      <Cell width={{ lg: 2, sm: 1 }}>
        <SimpleErrorBoundary>
          <PartName />
        </SimpleErrorBoundary>
      </Cell>

      <Cell width={{ lg: 2, sm: 1 }}>
        <SimpleErrorBoundary>
          <PartMaterial />
        </SimpleErrorBoundary>
      </Cell>

      <Cell>
        <SimpleErrorBoundary>
          <PartMachineType />
        </SimpleErrorBoundary>
      </Cell>

      <Cell>
        <SimpleErrorBoundary>
          <PartNumPrints modalType={modalType} />
        </SimpleErrorBoundary>
      </Cell>

      <Cell>
        <SimpleErrorBoundary>
          <PartBondo />
        </SimpleErrorBoundary>
      </Cell>

      <Cell>
        <SimpleErrorBoundary>
          <PartPaintedStatus />
        </SimpleErrorBoundary>
      </Cell>

      <Cell width={{ lg: 2, sm: 1 }}>
        <SimpleErrorBoundary>
          <PartColorOverrides />
        </SimpleErrorBoundary>
      </Cell>
    </Grid>
  );
};
